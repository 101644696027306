import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_new, change_screens } from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import HoverReplace from "../wrappers/HoverReplace";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 8,
    minHeight: 60,
    border: cstyles.border("subtle"),
    minWidth: 300,
    marginBottom: 10,
  },
  path_name: {
    fontSize: 20,
    fontWeight: 400,
  },
  stage_name: {
    fontSize: 14,
    fontWeight: 300,
  },
  hover_msg: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  continue_with: {
    fontSize: 14,
    fontWeight: 400,
    color: cstyles.text("faded"),
  },
  continue_journal_name: {
    fontSize: 20,
    fontWeight: 400,
    textDecoration: "underline",
  },
};

class Journey extends Component {
  static propTypes = {
    path_name: PropTypes.string.isRequired,
    stage_name: PropTypes.string.isRequired,
    active_journal_id: PropTypes.string.isRequired,
    active_journal_name: PropTypes.string.isRequired,
    set_entry_new: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    on_initial_stage: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_click() {
    const { active_journal_id } = this.props;

    this.props.set_entry_new(active_journal_id);
    this.props.change_screens("entry");
  }

  render() {
    const { path_name, stage_name, active_journal_name } = this.props;

    return (
      <HoverReplace
        style={styles.container}
        action={() => this.on_click()}
        hover_children={
          <div style={styles.hover_msg}>
            <div style={styles.continue_with}>
              {this.props.on_initial_stage ? "start with" : "continue with"}
            </div>
            <div style={styles.continue_journal_name}>{active_journal_name}</div>
          </div>
        }
      >
        <div style={styles.path_name}>{path_name}</div>
        <div style={styles.stage_name}>{stage_name}</div>
      </HoverReplace>
    );
  }
}

function state_to_props(state, ownProps) {
  const path = state.db[ownProps.path_id];
  const current_stage_id = state.journeys[ownProps.path_id].stage;
  const stage = path.journey.stages[current_stage_id];

  const active_journal_id = stage.active_journal;
  const active_journal_name = state.db[active_journal_id].name;

  const on_initial_stage = path.journey.initial_stage === current_stage_id;

  return {
    path_name: path.name,
    stage_name: stage.name,
    active_journal_id,
    active_journal_name,
    on_initial_stage,
  };
}
export default connect(state_to_props, { set_entry_new, change_screens })(Journey);
