import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles, ss } from "../common";

import Hover from "../wrappers/Hover";

const styles = {
  container: {
    height: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    margin: 10,
    padding: 30,
    backgroundColor: cstyles.raised(2),
    fontSize: 32,
  },
  button_selected: {
    ...cstyles.brighter_hover,
  },
};

class YesNo extends Component {
  static propTypes = {
    update_entry: PropTypes.func.isRequired,
    entry: PropTypes.object,
    question_id: PropTypes.string.isRequired,
    answer: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  // Allows "cycling" through answers
  on_key_press(e) {
    if (e.key !== " ") {
      return;
    }

    const { answer } = this.props;
    let next_answer = "";
    if (answer === "no") {
      next_answer = "yes";
    } else if (answer === "yes") {
      next_answer = "";
    } else {
      next_answer = "no";
    }

    this.props.update_entry(
      this.props.entry ? this.props.entry.entry_id : null,
      this.props.question_id,
      "yes_no",
      next_answer
    );
  }

  update_answer(answer) {
    this.props.update_entry(
      this.props.entry ? this.props.entry.entry_id : null,
      this.props.question_id,
      "yes_no",
      answer
    );
  }

  render() {
    const { answer } = this.props;

    const yes_style = ss(answer === "yes", styles.button, styles.button_selected);
    const no_style = ss(answer === "no", styles.button, styles.button_selected);

    return (
      <div
        style={styles.container}
        onClick={() => {
          this.update_answer(""); // effectively no answer
        }}
      >
        <div style={styles.button_container}>
          <Hover style={no_style} action={() => this.update_answer("no")} stop_prop>
            No
          </Hover>
          <Hover style={yes_style} action={() => this.update_answer("yes")} stop_prop>
            Yes
          </Hover>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  let entry = null;
  if (state.entry.entry_id) {
    entry = state.db[state.entry.entry_id];
  }

  // Get answer
  const question_id = state.editor.question_id;
  const answer =
    entry && question_id in entry.answers ? entry.answers[question_id] : "";

  return {
    question_id: state.editor.question_id,
    entry,
    answer,
  };
}
export default connect(state_to_props, { update_entry })(YesNo);
