import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Input from "./Input";

import Hover from "../wrappers/Hover";

import {
  sign_in,
  forgot_password,
  forgot_password_submit,
  clear_error,
} from "./auth_actions";
import Error from "./Error";
import Confirm from "../auth/Confirm";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    width: 255,
  },
  header: {
    fontSize: 18,
    marginBottom: 30,
    color: cstyles.text("rest"),
  },
  forgot: {
    fontSize: 10,
    padding: 6,
    whiteSpace: "nowrap",
    width: "min-content",
  },
  button: {
    padding: 6,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: cstyles.text("rest"),
    border: cstyles.border("subtle"),
    height: 28,
  },
  spinner: {
    border: "4px solid " + cstyles.raised(0),
    borderTop: "4px solid #bae7ff",
    borderRadius: "50%",
    width: 10,
    height: 10,
    animation: "spin 1s linear infinite",
  },
  info_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  go_back: {
    fontSize: 10,
    fontWeight: 600,
    padding: 4,
    color: cstyles.text("inactive"),
  },
};

class SignIn extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      mode: "signin", // signin || forgot_email || forgot_submit
    };
    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  on_key_press(e) {
    if (e.key === "Enter") {
      switch (this.state.mode) {
        case "signin":
          this.on_sign_in();
          break;
        case "forgot_email":
          this.on_forgot_password();
          break;
        case "forgot_submit":
          this.on_forgot_password_submit();
          break;
        default:
          break;
      }
    }
  }

  on_input(name, value) {
    let new_state = {};
    new_state[name] = value;
    this.setState(new_state);
  }

  on_sign_in() {
    if (this.state.password.length === 0) {
      return;
    }

    this.setState({
      loading: true,
    });

    sign_in(this.state.email, this.state.password).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  // switch to forgot password mode
  on_start_forgot_password() {
    this.setState({
      mode: "forgot_email",
    });

    // if there's already an email typed, submit that for reset
    if (this.state.email.length > 5) {
      this.on_forgot_password();
    }
  }

  on_forgot_password() {
    this.setState({
      loading: true,
    });

    forgot_password(this.state.email).then(success => {
      this.setState({
        loading: false,
      });

      if (success) {
        this.setState({
          mode: "forgot_submit",
        });
      }
    });
  }

  on_forgot_password_submit() {
    this.setState({
      loading: true,
    });

    forgot_password_submit(
      this.state.email,
      this.state.code,
      this.state.new_password
    ).then(success => {
      this.setState({
        loading: false,
      });

      if (success) {
        this.setState(
          {
            password: this.state.new_password,
          },
          () => {
            this.on_sign_in();
          }
        );
      }
    });
  }

  go_back() {
    this.setState({
      mode: "signin",
    });
    clear_error();
  }

  render_header() {
    if (this.state.mode === "signin") {
      return "Welcome back";
    } else if (this.state.mode === "forgot_email") {
      return "Submit email to reset password";
    } else if (this.state.mode === "forgot_submit") {
      return "Submit code from email and a new password";
    }
  }

  render_mid() {
    if (this.state.mode === "signin") {
      return (
        <Input
          name={"password"}
          on_change={value => this.on_input("password", value)}
        />
      );
    } else if (this.state.mode === "forgot_email") {
      return null;
    } else if (this.state.mode === "forgot_submit") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input name={"code"} on_change={value => this.on_input("code", value)} />
          <Input
            name={"new password"}
            on_change={value => this.on_input("new_password", value)}
          />
        </div>
      );
    } else {
    }
  }

  render_button() {
    if (this.state.mode === "signin") {
      return (
        <Hover
          style={styles.button}
          action={() => {
            this.on_sign_in();
          }}
        >
          {this.state.loading ? <div style={styles.spinner} /> : "sign in"}
        </Hover>
      );
    } else if (this.state.mode === "forgot_email") {
      return (
        <Hover
          style={styles.button}
          action={() => {
            this.on_forgot_password();
          }}
        >
          {this.state.loading ? <div style={styles.spinner} /> : "submit email"}
        </Hover>
      );
    } else if (this.state.mode === "forgot_submit") {
      return (
        <Hover
          style={styles.button}
          action={() => {
            this.on_forgot_password_submit();
          }}
        >
          {this.state.loading ? <div style={styles.spinner} /> : "reset password"}
        </Hover>
      );
    }
  }

  render() {
    if (this.props.auth.error === "UserNotConfirmedException") {
      return <Confirm email={this.state.email} password={this.state.password} />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>{this.render_header()}</div>
        <Input
          name={"email"}
          on_change={value => this.on_input("email", value)}
          start={this.state.email}
        />
        {this.render_mid()}
        <Error />

        {this.render_button()}
        <div style={styles.info_row}>
          {this.state.mode === "signin" ? (
            <Hover
              style={styles.forgot}
              action={() => {
                this.on_start_forgot_password();
              }}
            >
              Forgot your password?
            </Hover>
          ) : (
            <Hover
              style={styles.go_back}
              action={() => {
                this.go_back();
              }}
            >
              Go back
            </Hover>
          )}
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    auth: state.auth,
  };
}
export default connect(state_to_props, {})(SignIn);
