import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import { get_ref_by_entryday, get_answer } from "../getters";

import SubsetChip from "./SubsetChip";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    userSelect: "none",
  },
  no_options_message: {
    color: cstyles.text("active"),
    fontSize: 24,
    width: "100%",
    height: "100%",
    ...cstyles.col,
    justifyContent: "center",
    alignItems: "center",
  },
};

class Subset extends Component {
  static propTypes = {
    ref_answer: PropTypes.array,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ref_answer } = this.props;

    if (!ref_answer || (Array.isArray(ref_answer) && ref_answer.length === 0)) {
      return (
        <div style={styles.no_options_message}>
          <div>Answer by choosing a subset from another answer</div>
          <div>There is nothing to choose from</div>
        </div>
      );
    }

    return (
      <div style={styles.container}>
        {ref_answer.map(id => {
          return <SubsetChip key={id} id={id} />;
        })}
      </div>
    );
  }
}

function state_to_props(state) {
  const question = state.db[state.editor.question_id];

  const my_journal_id = state.db[question.from_prompt_id].from_journal_id;
  const ref_journal_id =
    state.db[state.db[question.ref_question_id].from_prompt_id].from_journal_id;

  // get ref_answer
  let ref_answer;
  // the ref'd question is in the same journal, so it's safe to do a get_answer
  if (my_journal_id === ref_journal_id) {
    ref_answer = get_answer(state, question.ref_question_id, state.entry.entry_id);
  }
  // otherwise, this does a get by daily (and will throw if it's a responsive)
  else {
    ref_answer = get_ref_by_entryday(
      state,
      question.ref_question_id,
      state.entry.entry_id
    ).ref_answer;
  }

  return {
    question,
    ref_answer: ref_answer ? ref_answer : [],
  };
}
export default connect(state_to_props, {})(Subset);
