import * as AT from "./action_types";
import { app_init } from "./reducer";

function app_open() {
  return {
    type: AT.APP_OPEN,
  };
}

function change_screens(screen) {
  return {
    type: AT.CHANGE_SCREEN,
    screen: screen,
  };
}

function load_pathdef_success(paths) {
  return {
    type: AT.LOAD_PATHDEF_SUCCESS,
    paths: paths,
  };
}

function show_data_editor() {
  return {
    type: AT.SHOW_DATA_EDITOR,
  };
}

function close_data_editor() {
  return {
    type: AT.CLOSE_DATA_EDITOR,
  };
}

function toggle_expand_editor() {
  return {
    type: AT.TOGGLE_EXPAND_EDITOR,
  };
}

function set_data_editor(journal_id, question_id) {
  // values can be optional by passing null - null means keep the current value.
  // used to set question_id without respecifying journal_id (must be from within same journal, and journal_id already set)
  return {
    type: AT.SET_DATA_EDITOR,
    journal_id: journal_id,
    question_id: question_id,
  };
}

// Sets data editor to the first available question in a journal
function set_data_editor_first(journal_id) {
  if (!journal_id) {
    throw new Error("set_data_editor_first requires a journal_id");
  }

  return {
    type: AT.SET_DATA_EDITOR_FIRST,
    journal_id,
  };
}

// "shifts" the editor to the next/previous question
function shift_editor_question(direction) {
  if (!["next", "previous"].includes(direction)) {
    throw new Error("invalid direction for shift_editor_question()");
  }

  return {
    type: AT.SHIFT_EDITOR_QUESTION,
    direction: direction,
  };
}

function set_editor_mode(mode) {
  return {
    type: AT.SET_EDITOR_MODE,
    mode: mode,
  };
}

function text_entry_focus(focus) {
  if (typeof focus !== "boolean") {
    throw new Error("text_entry_focus() requires a bool");
  }

  return {
    type: AT.TEXT_ENTRY_FOCUS,
    focus: focus,
  };
}

function update_question_ops(question_id, option, action) {
  // Passed a question_id - not qops_id.
  return {
    type: AT.UPDATE_QUESTION_OPS,
    question_id: question_id,
    option: option,
    action: action,
  };
}

function update_db(objects) {
  // Allows updating arbitrary objects in the object db.
  // Expects an array of objects of the form:
  // [ { id: id, obj: object } ]

  let obj_arr;
  if (!Array.isArray(objects)) {
    obj_arr = [objects];
  } else {
    obj_arr = objects;
  }

  return {
    type: AT.DB_UPDATE,
    objects: obj_arr,
  };
}

function update_entry(entry_id, question_id, question_type, data) {
  return {
    type: AT.ENTRY_UPDATE,
    entry_id: entry_id,
    question_id: question_id,
    question_type: question_type,
    data: data,
  };
}

function remove_entry(entry_id) {
  return {
    type: AT.ENTRY_REMOVE,
    entry_id,
  };
}

function new_affect_category() {
  return {
    type: AT.NEW_AFFECT_CATEGORY,
  };
}

function update_affect_category(category_id, field, value) {
  return {
    type: AT.UPDATE_AFFECT_CATEGORY,
    category_id: category_id,
    field: field,
    value: value,
  };
}

function remove_affect_category(category_id) {
  return {
    type: AT.REMOVE_AFFECT_CATEGORY,
    category_id: category_id,
  };
}

function new_feeling() {
  return {
    type: AT.NEW_FEELING,
  };
}

function update_feeling(feeling_id, field, value) {
  return {
    type: AT.UPDATE_FEELING,
    feeling_id: feeling_id,
    field: field,
    value: value,
  };
}

function remove_feeling(feeling_id) {
  return {
    type: AT.REMOVE_FEELING,
    feeling_id: feeling_id,
  };
}

function dev_action(action, value = null) {
  return {
    type: AT.DEV_ACTION,
    action,
    value,
  };
}

function show_settings() {
  return {
    type: AT.SETTINGS_SHOW,
  };
}

function close_settings() {
  return {
    type: AT.SETTINGS_CLOSE,
  };
}

function change_setting(what, value) {
  return {
    type: AT.SETTINGS_CHANGE,
    what,
    value,
  };
}

function set_journals_screen_type(value) {
  return {
    type: AT.SET_JOURNALS_SCREEN_TYPE,
    value,
  };
}

// sets the entry screen to a specific entry (ie, not new / today's)
function set_entry_specific(entry_id, journal_id) {
  return {
    type: AT.SET_ENTRY_SPECIFIC,
    entry_id,
    journal_id,
  };
}

// A shortcut for "you click on a journal intending to make a new entry"
// Behavior varies whether that's a responsive or daily journal
//    Responsive -> Make a new entry
//    Daily -> Go to today's entry, or make a new one if it doesn't exist
//    Onboarding -> Go to last entry, or make a new one if it doesn't exist
function set_entry_new(journal_id) {
  return {
    type: AT.SET_ENTRY_NEW,
    journal_id,
  };
}

function SOS() {
  return {
    type: AT.SOS,
  };
}

// toggles the pop out display of quick access buttons
function toggle_quick_buttons() {
  return {
    type: AT.TOGGLE_QUICK_BUTTONS,
  };
}

const valid_auth_keys = ["signed_in", "error", "error_message", "email"];
function auth_update(update) {
  if (typeof update !== "object") {
    throw new Error(`function auth_update() must receive an object -- got ${update}`);
  }

  Object.keys(update).forEach(key => {
    if (!valid_auth_keys.includes(key)) {
      throw new Error(`invalid key '${key}' given to auth_update()`);
    }
  });

  return {
    type: AT.AUTH_UPDATE,
    update,
  };
}

// remove successfully synced items from the sync.queue
function sync_success(ids) {
  return {
    type: AT.SYNC_SUCCESS,
    ids,
  };
}

// starts syncing latest items from api
function sync_latest() {
  return {
    type: AT.SYNC_LATEST,
  };
}

// get latest succeeded, ready to add items
function sync_latest_success(items) {
  return {
    type: AT.SYNC_LATEST_SUCCESS,
    items,
  };
}

function sync_account() {
  return {
    type: AT.SYNC_ACCOUNT,
  };
}

function sync_account_success(data) {
  return {
    type: AT.SYNC_ACCOUNT_SUCCESS,
    data,
  };
}

// sets a path / journal for a creator to active/inactive
// can be either a path_id or a journal_id
function set_journal_active(active, id) {
  return {
    type: AT.SET_JOURNAL_ACTIVE,
    active,
    id,
  };
}

// sets the mode for the search screen
function set_search_mode(mode) {
  return {
    type: AT.SEARCH_SET_MODE,
    mode,
  };
}

// sets the ID for the creator page
function set_creator_id(id) {
  return {
    type: AT.SET_CREATOR_ID,
    id,
  };
}

// starts loading process for creator by id
function load_creator_by_id(id) {
  return {
    type: AT.LOAD_CREATOR_BY_ID,
    id,
  };
}

function load_creator_success(data) {
  return {
    type: AT.LOAD_CREATOR_SUCCESS,
    data,
  };
}

// sets an error message in state.error
function set_error(key, message, full_error) {
  return {
    type: AT.SET_ERROR,
    message,
    full_error,
  };
}

// makes an account change
function account_change(change, data) {
  return {
    type: AT.ACCOUNT_CHANGE,
    change,
    data,
  };
}

function account_change_success(change, data = null) {
  return {
    type: AT.ACCOUNT_CHANGE_SUCCESS,
    change,
    data,
  };
}

function toggle_entry_share_modal() {
  return {
    type: AT.TOGGLE_ENTRY_SHARE_MODAL,
  };
}

// sets a loading flag under state.loading to value
function set_loading_flag(key, value) {
  if (!(key in app_init.loading)) {
    throw new Error(`invalid key given to set_loading_flag`);
  }

  return {
    type: AT.SET_LOADING_FLAG,
    key,
    value,
  };
}

function share_entry(entry_id) {
  return {
    type: AT.SHARE_ENTRY,
    entry_id,
  };
}

function revoke_entry_sharing(entry_id) {
  return {
    type: AT.REVOKE_ENTRY_SHARING,
    entry_id,
  };
}

function entry_sharing_updated(entry_id, is_shared, token = null) {
  return {
    type: AT.ENTRY_SHARING_UPDATED,
    entry_id,
    is_shared,
    token,
  };
}

function toggle_side_context() {
  return {
    type: AT.TOGGLE_SIDE_CONTEXT,
  };
}

function toggle_expand_side_context(show = null) {
  // param show is optional. If not provided, it will toggle the current value
  return {
    type: AT.TOGGLE_EXPAND_SIDE_CONTEXT,
    show,
  };
}

function search_creators(search_type) {
  return {
    type: AT.SEARCH_CREATORS,
    search_type,
  };
}

function search_creators_success(search_type, data) {
  return {
    type: AT.SEARCH_CREATORS_SUCCESS,
    search_type,
    data,
  };
}

// shows the "stage complete" message on the entry screen during stage transitions
function show_stage_complete(show) {
  return {
    type: AT.SHOW_STAGE_COMPLETE,
    show,
  };
}

// restarts/resets a journey
function restart_journey(path_id, remove_entries = false) {
  return {
    type: AT.RESTART_JOURNEY,
    path_id,
    remove_entries,
  };
}

// create a new path in the path designer
function designer_new_path() {
  return {
    type: AT.DESIGNER_NEW_PATH,
  };
}

function remove_draft_path(path_id) {
  return {
    type: AT.REMOVE_DRAFT_PATH,
    path_id,
  };
}

function reorder_draft_paths(moved_path_id, from_index, to_index) {
  return {
    type: AT.REORDER_DRAFT_PATHS,
    moved_path_id,
    from_index,
    to_index,
  };
}

function set_selected_path(path_id) {
  return {
    type: AT.SET_SELECTED_PATH,
    path_id,
  };
}

function designer_new_journal(path_id) {
  return {
    type: AT.DESIGNER_NEW_JOURNAL,
    path_id,
  };
}

function remove_draft_journal(path_id, journal_id) {
  return {
    type: AT.REMOVE_DRAFT_JOURNAL,
    path_id,
    journal_id,
  };
}

function reorder_draft_journals(path_id, from_index, to_index) {
  return {
    type: AT.REORDER_DRAFT_JOURNALS,
    path_id,
    from_index,
    to_index,
  };
}

function set_selected_journal(journal_id) {
  return {
    type: AT.SET_SELECTED_JOURNAL,
    journal_id,
  };
}

function designer_new_prompt(path_id, journal_id) {
  return {
    type: AT.DESIGNER_NEW_PROMPT,
    path_id,
    journal_id,
  };
}

function remove_draft_prompt(path_id, journal_id, prompt_id) {
  return {
    type: AT.REMOVE_DRAFT_PROMPT,
    path_id,
    journal_id,
    prompt_id,
  };
}

function reorder_draft_internal_journal(path_id, journal_id, result) {
  return {
    type: AT.REORDER_DRAFT_INTERNAL_JOURNAL,
    path_id,
    journal_id,
    result,
  };
}

function designer_new_question(path_id, journal_id, prompt_id) {
  return {
    type: AT.DESIGNER_NEW_QUESTION,
    path_id,
    journal_id,
    prompt_id,
  };
}

function remove_draft_question(path_id, journal_id, prompt_id, question_id) {
  return {
    type: AT.REMOVE_DRAFT_QUESTION,
    path_id,
    journal_id,
    prompt_id,
    question_id,
  };
}

function set_selected_question(question_id) {
  return {
    type: AT.SET_SELECTED_QUESTION,
    question_id,
  };
}

function update_draft_path_info(path_id, info) {
  // info is an object with info on what to update in the path
  const expected_info_keys = ["name", "description"];
  Object.keys(info).forEach(key => {
    if (!expected_info_keys.includes(key)) {
      throw new Error(
        `unexpected key passed to info in update_draft_path_info: ${key}`
      );
    }
  });

  return {
    type: AT.UPDATE_DRAFT_PATH_INFO,
    path_id,
    info,
  };
}

function update_draft_journal_info(path_id, journal_id, info) {
  // info is an object with info on what to update in the path
  const expected_info_keys = ["name", "description", "type", "context"];
  Object.keys(info).forEach(key => {
    if (!expected_info_keys.includes(key)) {
      throw new Error(
        `unexpected key passed to info in update_draft_journal_info: ${key}`
      );
    }
  });

  return {
    type: AT.UPDATE_DRAFT_JOURNAL_INFO,
    path_id,
    journal_id,
    info,
  };
}

function update_draft_prompt_info(path_id, journal_id, prompt_id, info) {
  // info is an object with info on what to update in the path
  const expected_info_keys = ["name"];
  Object.keys(info).forEach(key => {
    if (!expected_info_keys.includes(key)) {
      throw new Error(
        `unexpected key passed to info in update_draft_journal_info: ${key}`
      );
    }
  });

  return {
    type: AT.UPDATE_DRAFT_PROMPT_INFO,
    path_id,
    journal_id,
    prompt_id,
    info,
  };
}

function update_draft_question_info(path_id, journal_id, prompt_id, question_id, info) {
  // info is an object with info on what to update in the path
  const expected_info_keys = [
    "question",
    "type",
    "style",
    "options_source",
    "static_options",
    "available_times",
    "ref_question_id",
    "scale",
    "context",
  ];
  Object.keys(info).forEach(key => {
    if (!expected_info_keys.includes(key)) {
      throw new Error(
        `unexpected key passed to info in update_draft_question_info: ${key}`
      );
    }
  });

  return {
    type: AT.UPDATE_DRAFT_QUESTION_INFO,
    path_id,
    journal_id,
    prompt_id,
    question_id,
    info,
  };
}

function designer_collapse_columns() {
  return {
    type: AT.DESIGNER_COLLAPSE_COLUMNS,
  };
}

function designer_breadcrumb(level) {
  return {
    type: AT.DESIGNER_BREADCRUMB,
    level,
  };
}

function designer_toggle_context() {
  return {
    type: AT.DESIGNER_TOGGLE_CONTEXT,
  };
}

function designer_set_path_manager_id(path_id) {
  // path_id can either be a draft path_id, or null

  return {
    type: AT.DESIGNER_SET_PATH_MANAGER_ID,
    path_id,
  };
}

// name is a temp param until something else can be setup
function designer_publish_path(path_id, name = null) {
  return {
    type: AT.DESIGNER_PUBLISH_PATH,
    path_id,
    name,
  };
}

function designer_publish_path_success() {
  return {
    type: AT.DESIGNER_PUBLISH_PATH_SUCCESS,
  };
}

function designer_publish_path_error() {
  return {
    type: AT.DESIGNER_PUBLISH_PATH_ERROR,
  };
}

export {
  app_open,
  change_screens,
  load_pathdef_success,
  show_data_editor,
  close_data_editor,
  toggle_expand_editor,
  set_data_editor,
  set_data_editor_first,
  shift_editor_question,
  set_editor_mode,
  text_entry_focus,
  update_question_ops,
  update_db,
  update_entry,
  remove_entry,
  new_affect_category,
  update_affect_category,
  remove_affect_category,
  new_feeling,
  update_feeling,
  remove_feeling,
  dev_action,
  show_settings,
  close_settings,
  change_setting,
  set_journals_screen_type,
  set_entry_specific,
  set_entry_new,
  SOS,
  toggle_quick_buttons,
  auth_update,
  sync_success,
  sync_latest,
  sync_latest_success,
  sync_account,
  sync_account_success,
  set_journal_active,
  set_search_mode,
  set_creator_id,
  load_creator_by_id,
  load_creator_success,
  set_error,
  account_change,
  account_change_success,
  toggle_entry_share_modal,
  set_loading_flag,
  share_entry,
  revoke_entry_sharing,
  entry_sharing_updated,
  toggle_side_context,
  toggle_expand_side_context,
  search_creators,
  search_creators_success,
  show_stage_complete,
  restart_journey,
  designer_new_path,
  remove_draft_path,
  reorder_draft_paths,
  set_selected_path,
  designer_new_journal,
  remove_draft_journal,
  reorder_draft_journals,
  set_selected_journal,
  designer_new_prompt,
  remove_draft_prompt,
  reorder_draft_internal_journal,
  designer_new_question,
  remove_draft_question,
  set_selected_question,
  update_draft_path_info,
  update_draft_journal_info,
  update_draft_prompt_info,
  update_draft_question_info,
  designer_collapse_columns,
  designer_breadcrumb,
  designer_toggle_context,
  designer_set_path_manager_id,
  designer_publish_path,
  designer_publish_path_success,
  designer_publish_path_error,
};
