import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles, ss } from "../common";
import {} from "../getters";
import { mark_todo } from "../actions";

import Hover from "../wrappers/Hover";

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 13,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
  },
  todo_done: {
    textDecoration: "line-through",
    opacity: 0.8,
    backgroundColor: "#162a09",
  },
};

class Clickable extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    update_entry: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    entry_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  todo_click() {
    const { item } = this.props;

    this.props.update_entry(
      this.props.entry_id,
      this.props.question.question_id,
      "list",
      mark_todo(!item.completed, item)
    );
  }

  render_as_todo() {
    const { item } = this.props;
    const style = ss(item.completed, styles.chip, styles.todo_done);
    return (
      <Hover style={style} action={() => this.todo_click()}>
        {item.name}
      </Hover>
    );
  }

  render() {
    const { question } = this.props;
    const { style: question_style } = question;

    if (question_style === "todos") {
      return this.render_as_todo();
    } else {
      throw new Error(
        `question_style '${question_style}' not handled for entry:Clickable for question_id ${question.question_id}`
      );
    }
  }
}

function state_to_props(state, ownProps) {
  if (!ownProps.entry_id) {
    throw new Error(
      `entry:clickable rendered without an entry_id. question_id: ${ownProps.question_id}, entry_id: ${ownProps.entry_id}`
    );
  }

  return {
    question: state.db[ownProps.question_id],
    entry_id: ownProps.entry_id,
    item: state.db[ownProps.id],
  };
}
export default connect(state_to_props, { update_entry })(Clickable);
