import React, { Component } from "react";
import PropTypes from "prop-types";

import { ss, cstyles } from "../common";

const styles = {
  button_div: {
    borderRadius: 16,
    fontFamily: "nunito",
    fontWeight: 700,
    userSelect: "none",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 20,
    transition: "background-color 250ms, opacity 250ms",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.5,
    cursor: "pointer",
  },
  icon: {
    height: 18,
    width: 18,
  },
};

const icon = require("../icons/delete.png");

export default class DeleteButton extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  render() {
    const div_style = ss(this.state.hover, styles.button_div, {
      backgroundColor: cstyles.remove,
      opacity: 1,
    });

    return (
      <div
        style={div_style}
        onClick={this.props.callback}
        onMouseOver={() => {
          this.setState({
            hover: true,
          });
        }}
        onMouseOut={() => {
          this.setState({
            hover: false,
          });
        }}
      >
        <img style={styles.icon} src={icon} alt={"remove row"} />
      </div>
    );
  }
}
