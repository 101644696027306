import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles, ss } from "../common";

import Hover from "../wrappers/Hover";

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 15,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: cstyles.raised(1),
  },
};

class SSChip extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    update_entry: PropTypes.func.isRequired,
    entry: PropTypes.object,
    question_id: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_click() {
    this.props.update_entry(
      this.props.entry ? this.props.entry.entry_id : null,
      this.props.question_id,
      "single_select",
      this.props.selected ? null : this.props.name
    );
  }

  render() {
    const style = ss(this.props.selected, styles.chip, cstyles.chip_selected);

    return (
      <Hover style={style} action={() => this.on_click()}>
        {this.props.name}
      </Hover>
    );
  }
}

function state_to_props(state, ownProps) {
  const entry = state.entry.entry_id ? state.db[state.entry.entry_id] : null;

  const { question_id } = state.editor;

  const selected = entry
    ? entry.answers[question_id]
      ? entry.answers[question_id] === ownProps.name
      : false
    : false;

  return {
    entry,
    question_id,
    selected,
  };
}
export default connect(state_to_props, { update_entry })(SSChip);
