import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles } from "../common";
import { get_answer } from "../getters";
import { new_schedule_row } from "../create_new";

import TSLRow from "./TSLRow";

import HoverIcon from "../wrappers/HoverIcon";

const add_icon = require("../icons/add_light.png");

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "95%",
    overflow: "hidden",
    padding: 10,
  },
  schedule: {
    height: "100%",
    overflowY: "auto",
    flex: 12,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  header_item: {
    fontSize: 12,
    color: cstyles.text("rest"),
  },
  stats_row: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  stats_item: {},
  stats_label: {
    fontSize: 11,
  },
  stats_text: {
    fontSize: 20,
    fontWeight: 600,
  },
  empty_message: {
    height: 100,
    width: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  add_column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
};

class TableScheduleList extends Component {
  static propTypes = {
    question_id: PropTypes.string.isRequired,
    entry_id: PropTypes.string,
    answer: PropTypes.array.isRequired,
    ref_answer: PropTypes.array.isRequired,
    update_entry: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_header() {
    return (
      <div style={styles.header}>
        <div style={{ ...styles.header_item, minWidth: 200 }}>Name</div>
        <div style={{ ...styles.header_item, minWidth: 160 }}>Time</div>
        <div style={{ ...styles.header_item, minWidth: 100 }}>Length</div>
        <div style={{ ...styles.header_item, minWidth: 300 }}>Items</div>
      </div>
    );
  }

  render_rows() {
    const { answer } = this.props;

    if (!answer) {
      return <div style={styles.empty_message}>Add a row →</div>;
    }

    return (
      <div>
        {answer.map(item => {
          return (
            <TSLRow
              key={item.item_id}
              item_id={item.item_id}
              options={this.get_item_options()}
            />
          );
        })}
      </div>
    );
  }

  on_new_row() {
    this.props.update_entry(
      this.props.entry_id,
      this.props.question_id,
      "table_schedule_list",
      {
        action: "new",
        item: new_schedule_row(),
      }
    );
  }

  // options for "Items" column (eg, todos)
  // This allows  each TSLRow to see what all the possible options are
  get_item_options() {
    const { answer, ref_answer } = this.props;

    // If there's no answer in the referenced question
    if (!ref_answer) {
      return [];
    }

    // Get all options already answered
    let already_answered = [];
    answer.forEach(row => {
      row.items.forEach(item_id => {
        already_answered.push(item_id);
      });
    });

    return ref_answer.map(item => {
      return {
        value: item.item_id,
        label: item.name,
        assigned: already_answered.includes(item.item_id),
      };
    });
  }

  render_stats_row() {
    const { answer } = this.props;

    // Return empty if no rows
    if (!answer || answer.length === 0) {
      return <div></div>;
    }

    let stats = {
      count: 0,
      total: 0,
    };
    answer.forEach(ans => {
      if (["free time", ""].includes(ans.name.trim().toLowerCase())) {
        stats.count += 1;
        stats.total += parseFloat(ans.length);
      }
    });

    stats.total = stats.total.toFixed(2);

    return (
      <div style={styles.stats_row}>
        <div style={{ minWidth: 200 }}>
          <div style={styles.stats_label}># windows</div>
          <div style={styles.stats_text}>{stats.count}</div>
        </div>
        <div style={{ minWidth: 160 }}>
          <div style={styles.stats_label}>total</div>
          <div style={styles.stats_text}>{stats.total}</div>
        </div>
        <div style={{ minWidth: 100 }}>
          <div style={styles.stats_label}>avg</div>
          <div style={styles.stats_text}>
            {stats.count ? (stats.total / stats.count).toFixed(1) : 0}
          </div>
        </div>
        <div style={{ minWidth: 300 }}></div>
      </div>
    );
  }

  render_add() {
    return (
      <div style={styles.add_column}>
        <HoverIcon
          alt={"new row"}
          icon={add_icon}
          action={() => this.on_new_row()}
          icon_size={32}
        />
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.schedule}>
          {this.render_header()}
          {this.render_rows()}
          {this.render_stats_row()}
          <div style={{ minHeight: 75 }} />
        </div>
        {this.render_add()}
      </div>
    );
  }
}

function state_to_props(state) {
  const { question_id } = state.editor;
  const { entry_id } = state.entry;

  const answer = get_answer(state, question_id, entry_id, true);
  const ref_answer = get_answer(
    state,
    state.db[question_id].ref_question_id,
    entry_id,
    true
  );

  return {
    question_id,
    answer,
    entry_id: entry_id ? entry_id : null,
    ref_answer: ref_answer ? ref_answer : [],
  };
}
export default connect(state_to_props, { update_entry })(TableScheduleList);
