import React, { Component } from "react";
import PropTypes from "prop-types";

import swal from "sweetalert";

import { connect } from "react-redux";
import {
  set_journal_active,
  set_entry_new,
  change_screens,
  restart_journey,
} from "../redux/actions";

import { cstyles, ss } from "../common";
import { get_available_journals } from "../getters";

import Hover from "../wrappers/Hover";

const styles = {
  path: {
    marginTop: 10,
    marginBottom: 40,
    marginLeft: 25,
    maxWidth: 450,
  },
  path_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  path_name: {
    fontWeight: 600,
    fontSize: 22,
  },
  deactivate_button: {
    fontSize: 12,
    color: cstyles.text("rest"),
    padding: 9,
    paddingTop: 4,
    paddingBottom: 4,
  },
  journal: {
    marginLeft: 5,
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  journal_name: {
    fontWeight: 600,
    fontSize: 18,
  },
  journal_info: {
    fontSize: 14,
    fontStyle: "italic",
    color: cstyles.text("rest"),
  },
  journal_left: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 30,
  },
};

class Path extends Component {
  static propTypes = {
    path: PropTypes.object.isRequired,
    inactive_ids: PropTypes.object.isRequired,
    set_journal_active: PropTypes.func.isRequired,
    set_entry_new: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    restart_journey: PropTypes.func.isRequired,
    available_journals: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_toggle_status(active, id) {
    this.props.set_journal_active(active, id);
  }

  render_journal(path_active, journal) {
    const { inactive_ids } = this.props;

    const journal_active = !inactive_ids[journal.journal_id];

    const journal_style = ss(path_active && !journal_active, styles.journal, {
      opacity: 0.4,
    });

    const num_questions = journal.prompts.reduce((count, prompt) => {
      return count + prompt.questions.length;
    }, 0);
    const num_questions_text =
      num_questions === 1 ? `${num_questions} question` : `${num_questions} questions`;

    return (
      <div style={journal_style} key={journal.journal_id}>
        <Hover
          style={styles.journal_left}
          action={() => {
            this.props.set_entry_new(journal.journal_id);
            this.props.change_screens("entry");
          }}
        >
          <div style={styles.journal_name}>{journal.name}</div>
          <div
            style={styles.journal_info}
          >{`${journal.type} - ${num_questions_text}`}</div>
        </Hover>
        {path_active ? (
          <Hover
            style={styles.deactivate_button}
            action={() => {
              this.on_toggle_status(!journal_active, journal.journal_id);
            }}
          >
            toggle journal
          </Hover>
        ) : null}
      </div>
    );
  }

  render_button(path_active, button) {
    const { inactive_ids } = this.props;

    const button_active = !inactive_ids[button.button_id];

    const button_style = ss(path_active && !button_active, styles.journal, {
      opacity: 0.4,
    });

    return (
      <div style={button_style} key={button.button_id}>
        <div style={styles.journal_left}>
          <div style={styles.journal_name}>{button.name}</div>
          <div style={styles.journal_info}>buttons</div>
        </div>
        {path_active ? (
          <Hover
            style={styles.deactivate_button}
            action={() => {
              this.on_toggle_status(!button_active, button.button_id);
            }}
          >
            toggle button
          </Hover>
        ) : null}
      </div>
    );
  }

  render() {
    const { path, inactive_ids } = this.props;

    const path_active = !inactive_ids[path.path_id];

    const path_style = ss(!path_active, styles.path, { opacity: 0.4 });

    return (
      <div key={path.path_id} style={path_style}>
        <div style={styles.path_header}>
          <div style={cstyles.col}>
            <div style={styles.path_name}>{path.name}</div>
            {path.journey ? (
              <Hover
                style={styles.deactivate_button}
                action={() => {
                  swal({
                    title: `Restart journey: ${path.name}`,
                    text: `This will restart your journey to the beginning. You can restart and choose to keep or remove your existing journal entries on this path/journey.\n\nNOTE - If you keep your journal entries, you may not end up back at the beginning.`,
                    icon: "warning",
                    buttons: {
                      cancel: "Don't do it",
                      remove: "Restart + REMOVE my entries",
                      keep: "Restart + KEEP my entries",
                    },
                    dangerMode: true,
                  }).then(choice => {
                    if (choice === "remove") {
                      swal({
                        title: `Remove all entries for '${path.name}'?`,
                        text: `This will delete all of your journal entries for this path. Are you sure you want to do that?`,
                        icon: "warning",
                        buttons: {
                          cancel: "Cancel",
                          remove: "REMOVE entries for this path",
                        },
                        dangerMode: true,
                      }).then(choice => {
                        if (choice) {
                          this.props.restart_journey(path.path_id, true);
                        }
                      });
                    } else if (choice === "keep") {
                      this.props.restart_journey(path.path_id, false);
                    } else {
                      // do nothing
                    }
                  });
                }}
              >
                restart journey
              </Hover>
            ) : null}
          </div>

          <Hover
            style={styles.deactivate_button}
            action={() => {
              this.on_toggle_status(!path_active, path.path_id);
            }}
          >
            toggle path
          </Hover>
        </div>

        <div>
          {path.journals
            .filter(journal => {
              return this.props.available_journals.includes(journal.journal_id);
            })
            .map(journal => this.render_journal(path_active, journal))}
        </div>
        <div>{path.buttons.map(button => this.render_button(path_active, button))}</div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  return {
    inactive_ids: state.inactive_ids,
    path: state.db[ownProps.path_id],
    available_journals: get_available_journals(state, ownProps.path_id),
  };
}
export default connect(state_to_props, {
  set_journal_active,
  set_entry_new,
  change_screens,
  restart_journey,
})(Path);
