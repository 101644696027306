import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles } from "../common";
import { get_answer } from "../getters";
import { new_list_item } from "../create_new";

import ListItem from "./ListItem";

import Input from "../compkit/Input";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    overflowY: "hidden",
    height: "95%",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "wrap",
    flex: 4,
    marginRight: 10,
    overflowX: "scroll",
  },
  empty: {
    flex: 4,
    ...cstyles.col,
    justifyContent: "center",
    alignItems: "center",
    color: cstyles.text("active"),
    fontSize: 24,
  },
};

class List extends Component {
  static propTypes = {
    entry_id: PropTypes.string,
    question_id: PropTypes.string.isRequired,
    update_entry: PropTypes.func.isRequired,
    answer: PropTypes.array.isRequired,
    question_style: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_list() {
    const { answer } = this.props;

    if (!answer || answer.length === 0) {
      return (
        <div style={styles.empty}>
          <div>Add something to your list →</div>
          <div>Answer by making a list.</div>
          <div>You make a new list every time you answer.</div>
        </div>
      );
    }

    return (
      <div style={styles.list}>
        {answer.map(item_id => {
          return (
            <ListItem
              key={item_id}
              item_id={item_id}
              on_remove={item_id => this.on_remove(item_id)}
            />
          );
        })}
      </div>
    );
  }

  on_create(text) {
    const { question_style } = this.props;

    let item_style = null;
    if (question_style === "todos") {
      item_style = "todo";
    }

    this.props.update_entry(this.props.entry_id, this.props.question_id, "list", {
      action: "new",
      item: new_list_item(text, item_style),
    });
  }

  on_remove(item_id) {
    this.props.update_entry(this.props.entry_id, this.props.question_id, "list", {
      action: "remove",
      item_id,
    });
  }

  render_adder() {
    return (
      <div style={{ flex: 1 }}>
        <Input action={text => this.on_create(text)} />
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        {this.render_list()}
        {this.render_adder()}
      </div>
    );
  }
}

function state_to_props(state) {
  const { question_id } = state.editor;
  const { entry_id } = state.entry;
  const answer = get_answer(state, question_id, entry_id);

  return {
    question_id: state.editor.question_id,
    question_style: state.db[question_id].style,
    entry_id: entry_id ? entry_id : null,
    answer: answer ? answer : [],
  };
}
export default connect(state_to_props, { update_entry })(List);
