import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_data_editor, show_data_editor } from "../redux/actions";

import { get_answer, filter_resolves } from "../getters";
import { ss, cstyles } from "../common";

import Hover from "../wrappers/Hover";

import {
  render_scale,
  render_free_schedule,
  render_chips,
  render_yesno,
  render_text_entry,
  render_check,
  render_simple_answer,
} from "./renders";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 4,
    padding: 4,
    maxWidth: 650,
    marginBottom: 15,
  },
  question_container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
    maxHeight: 600,
    overflowY: "auto",
    paddingRight: 20,
  },
  active_indicator: {
    width: 0,
    marginRight: 0,
    transition: "width 250ms, margin-right 250ms",
    borderRadius: "0px 4px 4px 0px",
    backgroundColor: cstyles.raised(2),
  },
  question: {
    fontSize: 22,
    color: "#FDC7D7",
    fontWeight: 300,
    transition: "color 250ms, filter 250ms, opacity 250ms",
  },
  question_inactive: {
    filter: "blur(0.2px) saturate(10%)",
    opacity: 0.9,
  },
  answer_container: {
    minHeight: 60,
  },
  answer_text: {
    fontSize: 14,
    userSelect: "text",
  },
};

class Question extends Component {
  static propTypes = {
    question_id: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    answer: PropTypes.any,
    set_data_editor: PropTypes.func.isRequired,
    show_data_editor: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    accent_color: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_active_indicator() {
    const style = ss(this.props.active, styles.active_indicator, {
      width: 5,
      minWidth: 5,
      marginRight: 12,
    });

    return <div style={style} />;
  }

  render_answer() {
    let { answer } = this.props;

    if (!answer) {
      return;
    }

    const question_type = this.props.question.type;
    const { style } = this.props.question;

    if (question_type === "text_entry") {
      return render_text_entry(answer);
    } else if (
      ["multiple_select", "affects", "subset", "shuffle", "list"].includes(
        question_type
      )
    ) {
      return render_chips(answer);
    } else if (question_type === "scale") {
      return render_scale(answer);
    } else if (question_type === "table_schedule_list" && style === "free_time") {
      return render_free_schedule(answer);
    } else if (question_type === "yes_no") {
      return render_yesno(answer);
    } else if (question_type === "checkbox") {
      return render_check(answer);
    } else if (question_type === "single_select") {
      return render_simple_answer(answer);
    } else {
      throw new Error("question type not handled in entry:question");
    }
  }

  on_click() {
    this.props.set_data_editor(null, this.props.question_id);
    this.props.show_data_editor();
  }

  render() {
    const { question, accent_color } = this.props;

    // Apply accent color
    let question_style = ss(true, styles.question, { color: accent_color });

    // Dim the question if it's NOT active
    question_style = ss(!this.props.active, question_style, styles.question_inactive);
    const answer_container_style = ss(!this.props.active, styles.answer_container, {
      opacity: 0.9,
    });

    return (
      <Hover
        style={styles.container}
        action={() => this.on_click()}
        disabled={this.props.disabled}
      >
        {this.render_active_indicator()}
        <div style={styles.question_container}>
          <div style={question_style}>{question.question}</div>
          <div style={answer_container_style}>{this.render_answer()}</div>
        </div>
      </Hover>
    );
  }
}

function state_to_props(state, ownProps) {
  const question = state.db[ownProps.question_id];
  const { entry_id } = state.entry;
  // Get response for this question, if available

  const disabled = entry_id
    ? state.db[entry_id].reacts[question.from_prompt_id] === "disable"
      ? true
      : false
    : false;

  let answer = get_answer(
    state,
    question.question_id,
    entry_id,
    question.type === "table_schedule_list" ? true : false
  );

  if (["subset"].includes(question.type)) {
    answer = filter_resolves(state, answer);
  }

  return {
    question,
    active: state.editor.question_id === ownProps.question_id,
    answer,
    disabled,
    accent_color: state.settings.accent_color,
  };
}
export default connect(state_to_props, { set_data_editor, show_data_editor })(Question);
