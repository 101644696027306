// Renders for different question types
import React from "react";

import Chip from "./Chip";
import RefItem from "./RefItem";
import Clickable from "./Clickable";

const check_icon = require("../icons/done.png");

const styles = {
  answer_chip: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  scale_text: {
    fontSize: 20,
    fontWeight: 600,
    minWidth: 70,
  },
  scale: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 5,
  },
  scale_label: {
    fontSize: 11,
    marginTop: -4,
  },
  scale_avg_container: {
    marginLeft: 25,
  },
  stats_row: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  stats_label: {
    fontSize: 11,
  },
  stats_text: {
    fontSize: 20,
    fontWeight: 600,
  },
  schedule_row: {
    display: "flex",
    flexDirection: "row",
  },
  schedule_item: {
    minWidth: 150,
  },
  schedule_list_items: {
    display: "flex",
    flexDirection: "row",
  },
  check_icon: {
    marginLeft: 10,
    height: 42,
    width: 42,
  },
};

function render_scale(answer) {
  // Sort answers ascending
  const answers = [...answer].sort();

  function render_avg() {
    if (answers.length > 1) {
      let average = answers.reduce((acc, item) => acc + item, 0) / answers.length;
      average = (Math.round(average * 10) / 10).toFixed(1);
      return (
        <div style={styles.scale_avg_container}>
          <div style={styles.scale_text}>{average}</div>
          <div style={styles.scale_label}>avg</div>
        </div>
      );
    }
  }

  return (
    <div style={styles.scale}>
      <div style={styles.scale_text}>{answers.join(", ")}</div>
      {render_avg()}
    </div>
  );
}

// The stats row - summary view for a question
function render_free_schedule(answer) {
  let stats = {
    count: 0,
    total: 0,
  };
  answer.forEach(ans => {
    if (["free time", ""].includes(ans.name.trim().toLowerCase())) {
      stats.count += 1;
      stats.total += parseFloat(ans.length);
    }
  });

  stats.total = stats.total.toFixed(2);

  return (
    <div style={styles.stats_row}>
      <div style={{ marginRight: 30 }}>
        <div style={styles.stats_label}># windows</div>
        <div style={styles.stats_text}>{stats.count}</div>
      </div>
      <div style={{ marginRight: 30 }}>
        <div style={styles.stats_label}>total</div>
        <div style={styles.stats_text}>{stats.total}</div>
      </div>
      <div style={{ marginRight: 30 }}>
        <div style={styles.stats_label}>avg</div>
        <div style={styles.stats_text}>
          {stats.count ? (stats.total / stats.count).toFixed(1) : 0}
        </div>
      </div>
    </div>
  );
}

function render_chips(answer) {
  return (
    <div style={styles.answer_chip}>
      {answer.map(answer_id => {
        return <Chip key={answer_id} id={answer_id} />;
      })}
    </div>
  );
}

function render_clickable(answer, question_id, entry_id) {
  return (
    <div style={styles.answer_chip}>
      {answer.map(item_id => {
        return (
          <Clickable
            key={item_id}
            id={item_id}
            question_id={question_id}
            entry_id={entry_id}
          />
        );
      })}
    </div>
  );
}

function render_yesno(answer) {
  return (
    <div style={styles.stats_text}>{answer[0].toUpperCase() + answer.slice(1)}</div>
  );
}

function render_text_entry(answer) {
  const text = answer.split("\n").map((t, idx) => {
    if (idx === 0) {
      return <div key={`text:${idx}:${t}`}>{t}</div>;
    } else {
      return <p key={`text:${idx}:${t}`}>{t}</p>;
    }
  });

  return <div style={styles.answer_text}>{text}</div>;
}

// lists out the rows from a schedule
function render_schedule(answer) {
  return answer.map(answer => {
    return (
      <div key={answer.item_id} style={styles.schedule_row}>
        <div style={styles.schedule_item}>{answer.name}</div>
        <div style={styles.schedule_item}>{answer.time}</div>
        <div style={styles.schedule_item}>{answer.length} hrs</div>
        <div style={styles.schedule_list_items}>
          {answer.items.map(item_id => {
            return <RefItem key={item_id} id={item_id} />;
          })}
        </div>
      </div>
    );
  });
}

function render_check(answer) {
  if (answer) {
    return (
      <div>
        <img src={check_icon} style={styles.check_icon} alt={"checked"} />{" "}
      </div>
    );
  } else {
    return <div />;
  }
}

function render_simple_answer(answer) {
  return <div style={styles.stats_text}>{answer}</div>;
}

export {
  render_scale,
  render_free_schedule,
  render_chips,
  render_yesno,
  render_text_entry,
  render_schedule,
  render_clickable,
  render_check,
  render_simple_answer,
};
