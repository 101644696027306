import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

const styles = {
  container: {
    marginLeft: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    marginBottom: 20,
  },
  path: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
  },
  path_name: {
    fontSize: 18,
    fontWeight: 600,
  },
  num_journals_text: {
    fontSize: 11,
    color: cstyles.text("inactive"),
  },
  path_left: {
    flex: 1,
  },
  path_right: {
    flex: 2,
    paddingBottom: 20,
    marginLeft: 5,
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
  },
  description: {
    fontSize: 14,
  },
};

class PathsSection extends Component {
  static propTypes = {
    summary: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_path(path) {
    return (
      <div key={path.path_id} style={styles.path}>
        <div style={styles.path_left}>
          <div style={styles.path_name}>{path.path_name}</div>
          <div
            style={styles.num_journals_text}
          >{`${path.journals.length} journals`}</div>
        </div>
        <div style={{ minHeight: 60 }}>&nbsp;{/*placeholder for future tags*/}</div>
        <div style={styles.path_right}>
          <div style={styles.description}>{path.description}</div>
        </div>
      </div>
    );
  }

  render() {
    const { summary } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.title}>Paths</div>
        <div>{summary.paths.map(this.render_path)}</div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    summary: state.db[`creator_data:${state.creator.id}`].summary,
  };
}
export default connect(state_to_props, {})(PathsSection);
