import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { new_feeling } from "../redux/actions";

import NewButton from "./NewButton";
import FeelingRow from "./FeelingRow";
import { cstyles } from "../common";

const styles = {
  section_header: {
    marginLeft: 4,
    marginBottom: 8,
    fontWeight: 600,
    fontSize: 18,
    color: "white",
  },
  card: {
    backgroundColor: cstyles.raised(1),
    padding: 16,
    borderRadius: 16,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  header_item: {
    fontWeight: 700,
    userSelect: "none",
    color: "white",
  },
};

class Feelings extends Component {
  static propTypes = {
    new_feeling: PropTypes.func.isRequired,
    feelings_list: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  on_new() {
    this.props.new_feeling();
  }

  render_header() {
    return (
      <div style={styles.row}>
        <div style={{ ...styles.header_item, ...{ width: "200px" } }}>NAME</div>
        <div style={{ ...styles.header_item, ...{ width: "200px" } }}>CATEGORY</div>
        <div style={{ ...styles.header_item, ...{ flex: 4 } }}>DESCRIPTION</div>
        <NewButton
          callback={() => {
            this.on_new();
          }}
        />
      </div>
    );
  }

  render_rows() {
    const { feelings_list } = this.props;

    return feelings_list.map((feeling_id, idx) => {
      const order = this.state.mounted ? 0 : idx;
      return <FeelingRow key={feeling_id} feeling_id={feeling_id} order={order} />;
    });
  }

  render() {
    return (
      <div>
        <div style={styles.section_header}>Feelings</div>
        <div style={styles.card}>
          {this.render_header()}
          {this.render_rows()}
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    feelings_list: state.affects.feelings,
  };
}
export default connect(state_to_props, { new_feeling })(Feelings);
