import React, { Component } from "react";
import PropTypes from "prop-types";

import AffectChip from "./AffectChip";

import { connect } from "react-redux";
import {} from "../redux/actions";
import { cstyles } from "../common";

const styles = {
  container: {
    flex: 1,
    padding: 10,
    display: "flex",
    overflow: "hidden",
    overflowX: "auto",
    scrollbarWidth: "thin",
    userSelect: "none",
    height: "100%",
  },
  category: {
    minWidth: "240px",
    maxWidth: "340px",
    height: "100%",
    padding: 5,
    marginLeft: 1,
    marginRight: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  category_header: {
    marginLeft: 3,
    fontSize: 14,
    fontWeight: 600,
    color: cstyles.text("active"),
  },
  category_body: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    flexWrap: "wrap",
    overflowY: "auto",
    scrollbarWidth: "thin",
  },
  add_affects_message: {
    color: "white",
    fontSize: 24,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -10,
  },
};

class AffectSelect extends Component {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    feelings_by_category: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_categories() {
    // Renders each category into a category column
    const { categories } = this.props;
    let category_columns = [];
    for (let [cat_id, cat] of Object.entries(categories)) {
      // Get name
      const name = cat.name ? cat.name : "<empty name>";

      // Make AffectChips
      const chips = this.props.feelings_by_category[cat_id].map(feel_id => {
        return <AffectChip feeling_id={feel_id} key={cat_id + feel_id} />;
      });

      // Don't add empty columns with empty names
      if (name === "<empty name>" && chips.length === 0) {
        continue;
      }

      // Build column and add to list
      category_columns.push(
        <div style={styles.category} key={cat_id}>
          <div style={styles.category_header}>{name}</div>
          <div style={styles.category_body}>{chips}</div>
        </div>
      );
    }

    // Add uncategorized column (if applicable)
    if (this.props.feelings_by_category["uncategorized"].length > 0) {
      // Make AffectChips
      const uncat_chips = this.props.feelings_by_category["uncategorized"].map(
        feel_id => {
          return <AffectChip feeling_id={feel_id} key={"uncategorized" + feel_id} />;
        }
      );

      category_columns.push(
        <div style={styles.category} key={"uncategorized"}>
          <div style={{ ...styles.category_header, ...{ fontStyle: "italic" } }}>
            uncategorized
          </div>
          <div style={styles.category_body}>{uncat_chips}</div>
        </div>
      );
    }

    // If there's no feelings to display, show a message
    if (category_columns.length === 0) {
      return (
        <div style={styles.add_affects_message}>
          <div>Answer by choosing feelings.</div>
          <div>You haven't added any feelings to your feelings database.</div>
          <div>← Add them in the feelings menu</div>
        </div>
      );
    }

    return category_columns;
  }

  render() {
    return <div style={styles.container}> {this.render_categories()} </div>;
  }
}

function state_to_props(state) {
  // Get affect definitions
  const affects = state.affects;

  // Retreive category definitions
  let categories = {};
  let feelings_by_category = {};
  affects.categories.forEach(cat_id => {
    categories[cat_id] = state.db[cat_id];
    feelings_by_category[cat_id] = [];
  });

  // Retreive feeling definitions
  const feelings = affects.feelings.map(feel_id => {
    return state.db[feel_id];
  });

  // Setup uncategorized category
  feelings_by_category["uncategorized"] = [];

  // Sort feelings into categories
  feelings.forEach(feeling => {
    if (feeling.category_id) {
      if (feelings_by_category[feeling.category_id]) {
        feelings_by_category[feeling.category_id].push(feeling.feeling_id);
      }
      // If a feeling has an old/deleted category, put in uncategorized
      else {
        feelings_by_category["uncategorized"].push(feeling.feeling_id);
      }
    }

    // If a feeling wasn't given a category
    else {
      feelings_by_category["uncategorized"].push(feeling.feeling_id);
    }
  });

  return {
    categories,
    feelings_by_category,
  };
}
export default connect(state_to_props, {})(AffectSelect);
