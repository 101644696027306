import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import { get_ref_by_entryday } from "../getters";

import ButtonSection from "./ButtonSection";

const styles = {
  column: {
    width: 400,
  },
  container: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    minHeight: 150,
    height: "50vh",
    overflowY: "auto",
    flexWrap: "wrap",
  },
  column_header: {
    fontSize: 20,
    fontWeight: 300,
    paddingBottom: 10,
    paddingTop: 5,
  },
};

class HomeButtons extends Component {
  static propTypes = {
    button_ids: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.button_ids.length === 0) {
      return null;
    }

    return (
      <div style={styles.column}>
        <div style={styles.column_header}>Buttons</div>
        <div style={styles.container}>
          <div style={styles.content}>
            {this.props.button_ids.map(button_id => {
              return <ButtonSection key={button_id} button_id={button_id} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const button_ids = state.path_comps.button_ids
    .map(button_id => {
      return state.db[button_id];
    })
    // filter out buttons that are inactive
    .filter(button => {
      if (
        state.inactive_ids[button.from_path_id] ||
        state.inactive_ids[button.button_id]
      ) {
        return false;
      }
      return true;
    })
    // filter out buttons with no content
    .filter(button => {
      const { ref_answer } = get_ref_by_entryday(state, button.ref_question_id, null);
      if (ref_answer) {
        return true;
      }
      return false;
    })
    .map(button => {
      return button.button_id;
    });

  return {
    button_ids,
  };
}
export default connect(state_to_props, {})(HomeButtons);
