import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles, boxShadows, ss } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const bell_sound = new Audio(require("../icons/bell.mp3"));

const checked_icon = require("../icons/checkbox_checked.png");
const not_checked_icon = require("../icons/checkbox_not_checked.png");

const styles = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  choose_row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  choose_time_option: {
    borderRadius: 4,
    marginLeft: 6,
    marginRight: 6,
    marginTop: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 20,
    userSelect: "none",
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    whiteSpace: "nowrap",
  },
  center_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  timer_control: {
    borderRadius: "50%",
    width: 60,
    height: 60,
    border: cstyles.border("rest"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: boxShadows[2],
  },
  timer: {
    marginRight: 40,
    marginLeft: 40,
    position: "relative",
  },
  timer_circle: {
    transition: "stroke-dashoffset 0.25s",
    transform: "rotate(90deg) scaleX(-1)",
    transformOrigin: "50% 50%",
  },
  timer_text_container: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timer_text: {
    fontSize: 22,
    fontWeight: 300,
    textAlign: "center",
    marginTop: -5,
  },
  options_column: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 10,
    right: 10,
  },
  side_option: {
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    color: cstyles.text("rest"),
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
};

class Timer extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      choosing_time: true,
      time_left: 0, // seconds left on the clock
      start_time: 0, // seconds the clock started with
      play_sound: true,
      timer_started: false,
    };
  }

  componentDidMount() {
    if (this.props.question.available_times.length === 1) {
      this.choose_time(this.props.question.available_times[0]);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval_timer);
  }

  // converts time def to seconds
  normalize_time(time) {
    let [number, unit] = time.split(" ");

    number = parseInt(number);

    if (unit.includes("minute")) {
      number = number * 60;
    }

    return number;
  }

  // on choosing a time (either automatically or from a list)
  choose_time(time) {
    const seconds_on_the_clock = this.normalize_time(time);

    this.setState({
      choosing_time: false,
      time_left: seconds_on_the_clock,
      start_time: seconds_on_the_clock,
    });
  }

  start_timer() {
    if (this.state.timer_started) {
      return;
    }

    this.setState({
      timer_started: true,
    });

    this.interval_timer = setInterval(() => {
      if (this.state.time_left <= 1) {
        this.setState({
          time_left: 0,
        });

        this.on_timer_finish();
      } else {
        this.setState({
          time_left: this.state.time_left - 1,
        });
      }
    }, 1000);
  }

  pause_timer() {
    clearInterval(this.interval_timer);
    this.setState({
      timer_started: false,
    });
  }

  on_timer_finish() {
    clearInterval(this.interval_timer);

    if (this.state.play_sound) {
      bell_sound.play();
    }

    this.setState({
      timer_started: false,
    });

    setTimeout(() => {
      this.reset_timer();
    }, 3000);
  }

  reset_timer() {
    clearInterval(this.interval_timer);
    this.setState({
      time_left: this.state.start_time,
      timer_started: false,
    });
  }

  // the first menu where the user chooses a time
  // only shown if there is more than one time
  render_time_chooser() {
    const { available_times } = this.props.question;
    return (
      <div style={styles.container}>
        <div style={{ marginBottom: 5, color: cstyles.text("rest") }}>
          Choose a time to begin
        </div>
        <div style={styles.choose_row}>
          {available_times.map((time, idx) => {
            return (
              <Hover
                key={`time-${idx}-${time}`}
                style={styles.choose_time_option}
                action={() => {
                  this.choose_time(time);
                }}
              >
                {time}
              </Hover>
            );
          })}
        </div>
      </div>
    );
  }

  // formats time for display
  format_time_left() {
    const { time_left } = this.state;

    const minutes = Math.floor(time_left / 60);
    const seconds = time_left % 60;

    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }

  render_timer() {
    const radius = 60;
    const stroke = 6;
    const progress = (this.state.time_left / this.state.start_time) * 100;

    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    // see here for info on how to build circle: https://css-tricks.com/building-progress-ring-quickly/

    const play_sound_style = ss(!this.state.play_sound, styles.side_option, {
      opacity: 0.5,
    });

    return (
      <div style={styles.container}>
        <div style={styles.center_row}>
          <Hover
            style={styles.timer_control}
            action={() => {
              this.reset_timer();
            }}
          >
            reset
          </Hover>
          <div style={styles.timer}>
            <svg height={radius * 2} width={radius * 2}>
              <circle
                stroke="#bae7ff"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + " " + circumference}
                style={{ ...styles.timer_circle, strokeDashoffset }}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
              />
            </svg>
            <div style={styles.timer_text_container}>
              <div style={styles.timer_text}>{this.format_time_left()}</div>
            </div>
          </div>
          <Hover
            style={styles.timer_control}
            action={() => {
              if (this.state.timer_started) {
                this.pause_timer();
              } else {
                this.start_timer();
              }
            }}
          >
            {this.state.timer_started ? "pause" : "start"}
          </Hover>
        </div>
        <div style={styles.options_column}>
          <Hover
            style={play_sound_style}
            action={() => {
              this.setState({
                play_sound: !this.state.play_sound,
              });
            }}
          >
            <img
              src={this.state.play_sound ? checked_icon : not_checked_icon}
              alt={"checkbox"}
              style={{ width: 16, height: 16, marginRight: 6 }}
            />
            play sound
          </Hover>
          {this.props.question.available_times.length > 1 ? (
            <Hover
              action={() => {
                this.setState({ choosing_time: true });
              }}
              style={styles.side_option}
            >
              go back
            </Hover>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.choosing_time) {
      return this.render_time_chooser();
    }

    return this.render_timer();
  }
}

function state_to_props(state) {
  const { question_id } = state.editor;
  const question = state.db[question_id];
  return {
    question,
  };
}
export default connect(state_to_props, {})(Timer);
