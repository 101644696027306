import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import {} from "../getters";

import SSChip from "./SSChip";

const styles = {
  list_style: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
};

class SingleSelect extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_list_style() {
    const { options } = this.props;
    return (
      <div style={styles.list_style}>
        {options.map(name => {
          return <SSChip key={`ss:${name}`} name={name} />;
        })}
      </div>
    );
  }

  render() {
    const { question } = this.props;
    if (question.style === "list") {
      return this.render_list_style();
    } else {
      throw new Error(`unrecognized style in single_select render`);
    }
  }
}

function state_to_props(state) {
  const { question_id } = state.editor;
  const question = state.db[question_id];

  // get options
  let options;
  if (question.options_source === "static") {
    options = question.static_options;
  } else {
    throw new Error(`unrecognized options_source in single_select state`);
  }

  return {
    question,
    options,
  };
}
export default connect(state_to_props, {})(SingleSelect);
