import React, { Component } from "react";

const logo = require("./icons/logo-light.png");

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  logo: {
    height: "35vh",
  },
};

export default class Splash extends Component {
  render() {
    return (
      <div style={styles.container}>
        <img alt={"logo"} src={logo} style={styles.logo} />
      </div>
    );
  }
}
