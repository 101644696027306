import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles, is_today, is_yesterday, is_same_date } from "../common";

import Entry from "./Entry";
import FilterSelect from "./FilterSelect";

const styles = {
  page: {
    userSelect: "none",
    height: "100%",
    overflowY: "scroll",
  },
  timeline: {
    marginTop: 15,
    marginBottom: 50,
  },
  block_label: {
    fontSize: 14,
    color: cstyles.text("rest"),
    marginTop: 25,
    marginBottom: 5,
  },
  filters_container: {
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
  },
  no_entries_message: {
    width: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
    fontStyle: "italic",
    userSelect: "none",
  },
};

class HistoryScreen extends Component {
  static propTypes = {
    entries: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      path: [], // paths to filter
      type: [], // daily || responsive, to filter
    };
  }

  render_day_block(block) {
    return (
      <div key={block.label}>
        <div style={styles.block_label}>{block.label}</div>
        <div>
          {block.entries.map(entry_id => {
            return <Entry key={entry_id} entry_id={entry_id} />;
          })}
        </div>
      </div>
    );
  }

  // Splits a list of entries by day, and separates into "day" blocks
  // Expects that entries is already sorted
  entries_to_blocks(entries) {
    let entries_by_day = [];

    for (let i = 0; i < entries.length; ) {
      // setup block
      let block = {
        label: "",
        entries: [], // entry_ids
      };

      // Set current day
      let current_day = entries[i].timestamp;

      // Add all entries to block that are on the same day
      while (i < entries.length && is_same_date(entries[i].timestamp, current_day)) {
        block.entries.push(entries[i].entry_id);
        i++;
      }

      // Determine label
      if (is_today(current_day)) {
        block.label = "today";
      } else if (is_yesterday(current_day)) {
        block.label = "yesterday";
      } else {
        block.label = new Date(current_day).toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
        });
      }

      // Add block to list
      entries_by_day.push(block);
    }

    return entries_by_day;
  }

  render_timeline() {
    const entries = this.props.entries
      // Apply path filter
      .filter(entry => {
        if (this.state.path.length === 0) {
          return true;
        }

        if (entry.no_journal_def) {
          return false;
        }

        return this.state.path.includes(entry.path_id);
      })
      // Apply type filter
      .filter(entry => {
        if (this.state.type.length === 0) {
          return true;
        }

        if (entry.no_journal_def) {
          return false;
        }

        return this.state.type.includes(entry.journal_type);
      });

    const entries_by_day = this.entries_to_blocks(entries);

    if (entries_by_day.length === 0) {
      return <div style={styles.no_entries_message}>no journal entries to show</div>;
    }

    return (
      <div>
        {entries_by_day.map(block => {
          return this.render_day_block(block);
        })}
      </div>
    );
  }

  on_filter_change(type, values) {
    let new_state = {};
    new_state[type] = values;
    this.setState(new_state);
  }

  render_filters() {
    return (
      <div style={styles.filters_container}>
        <FilterSelect
          type={"path"}
          on_change={(type, selected) => this.on_filter_change(type, selected)}
        />
        <FilterSelect
          type={"type"}
          on_change={(type, selected) => this.on_filter_change(type, selected)}
        />
      </div>
    );
  }

  render() {
    return (
      <div style={styles.page}>
        {this.render_filters()}
        <div styles={styles.timeline}>{this.render_timeline()}</div>
      </div>
    );
  }
}

function state_to_props(state) {
  const entries = state.entries
    .map(entry_id => {
      return state.db[entry_id];
    })
    // add path ids + journal_type
    .map(entry => {
      // if this an entry for a journal we don't have anymore
      if (!(entry.journal_id in state.db)) {
        return {
          ...entry,
          no_journal_def: true,
        };
      }

      return {
        ...entry,
        path_id: state.db[entry.journal_id].from_path_id,
        journal_type: state.db[entry.journal_id].type,
      };
    })
    .sort((a, b) => {
      return a.timestamp < b.timestamp;
    });

  return {
    entries,
  };
}
export default connect(state_to_props, {})(HistoryScreen);
