import React, { Component } from "react";
import PropTypes from "prop-types";

import { ss } from "../common";

// auto scales text so that the container doesn't overflow
export default class ScaleText extends Component {
  static propTypes = {
    style: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    min_size: PropTypes.number.isRequired, // minimum font size
  };

  constructor(props) {
    super(props);

    // validate that the style prop has certain keys
    this.validate_style();

    // give current_size a default value
    this.state = {
      current_size: this.props.style.fontSize,
    };

    // setup for first time
    setTimeout(() => {
      this.on_new_text();
    }, 0);

    // resize handler for window resize events
    this.resize_handler = () => {
      this.on_resize();
    };

    // ref for container
    this.container_ref = React.createRef();
  }

  // validates that the style prop has expected keys
  validate_style() {
    const expected_keys = ["fontSize", "overflow"];

    expected_keys.forEach(key => {
      if (!Object.keys(this.props.style).includes(key)) {
        throw new Error(`ScaleText style expected to contain ${key} key`);
      }
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize_handler, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize_handler, false);
  }

  // For if we're not unmounted, but get new text
  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.on_new_text();
    }
  }

  // handles if the browser window was resized
  on_resize() {
    // reset the known overflow font size
    this.overflow_at = this.start_size;

    this.downsizing = false;

    // schedule a size check
    this.schedule_recheck();
  }

  // handles if the display text changed
  on_new_text() {
    // reset the starting size (aka the max size we expand to)
    this.start_size = this.props.style.fontSize;

    // reset the known overflow font size
    this.overflow_at = this.start_size;

    this.downsizing = false;

    // reset current size
    this.setState({
      current_size: this.start_size,
    });

    // schedule a size check
    this.schedule_recheck();
  }

  change_font_size(amount) {
    this.setState({
      current_size: this.state.current_size + amount,
    });
  }

  schedule_recheck() {
    setTimeout(() => {
      this.check_size();
    }, 0);
  }

  // checks whether we're currently over or underflowing and makes adjustments
  check_size() {
    // get element reference
    const el = this.container_ref.current;

    const { current_size } = this.state;
    const { start_size, overflow_at, downsizing } = this;

    // Check whether we're currently overflowed
    const overflowed =
      el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

    // decrease font size
    if (overflowed) {
      // remember that we overflow at our current font size
      this.overflow_at = current_size;

      this.downsizing = true;

      if (current_size > this.props.min_size) {
        this.change_font_size(-0.5);
        this.schedule_recheck();
      }
    }

    // increase font size
    else {
      // if we're not currently downsizing
      if (!downsizing) {
        // if we have room to grow (ie, are less than where we started)
        if (current_size < start_size) {
          // if we did grow, we wouldn't expand to a known overflowing size
          if (current_size + 1 < overflow_at) {
            this.change_font_size(0.5);
            this.schedule_recheck();
          }
        }
      }
    }
  }

  render() {
    const container_style = ss(true, this.props.style, {
      fontSize: this.state.current_size,
    });

    return (
      <div style={container_style} ref={this.container_ref}>
        {this.props.children}
      </div>
    );
  }
}
