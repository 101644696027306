import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_new, change_screens } from "../redux/actions";

import { cstyles, ss } from "../common";

import Hover from "../wrappers/Hover";

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: 150,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: cstyles.border("rest"),
    fontSize: 14,
    fontWeight: 700,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    opacity: 0,
    transition: "opacity 500ms",
  },
};

class QuickOption extends Component {
  static propTypes = {
    journal_id: PropTypes.string.isRequired,
    journal_name: PropTypes.string.isRequired,
    set_entry_new: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    fade_idx: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    const fade_idx = this.props.fade_idx ? this.props.fade_idx : 0;
    setTimeout(() => {
      this.setState({
        show: true,
      });
    }, 100 * fade_idx);
  }

  on_click() {
    const { journal_id, change_screens, set_entry_new } = this.props;

    set_entry_new(journal_id);
    change_screens("entry");
  }

  render() {
    const style = ss(this.state.show, styles.chip, { opacity: 1 });
    return (
      <Hover style={style} action={() => this.on_click()}>
        {this.props.journal_name}
      </Hover>
    );
  }
}

function state_to_props(state, ownProps) {
  const journal = state.db[ownProps.journal_id];

  return {
    journal_name: journal.name,
  };
}
export default connect(state_to_props, { set_entry_new, change_screens })(QuickOption);
