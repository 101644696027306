import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_creator_id, change_screens } from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const styles = {
  container: {
    backgroundColor: cstyles.raised(1),
    display: "flex",
    flexDirection: "row",
    minHeight: 110,
    minWidth: 600,
    maxWidth: 600,
    borderRadius: 3,
    marginBottom: 20,
    marginLeft: 10,
  },
  name: {
    fontWeight: 300,
    fontSize: 26,
  },
  image: {
    width: 160,
    height: "auto",
    borderRadius: 3,
  },
  content: {
    paddingTop: 8,
    paddingBottom: 10,
    paddingRight: 40,
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

class Creator extends Component {
  static propTypes = {
    summary: PropTypes.object.isRequired,
    set_creator_id: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_image() {
    const { summary } = this.props;

    // if no photo, load a default one
    const src = summary.photo
      ? summary.photo
      : `https://public-creator-assets.s3-us-west-2.amazonaws.com/default${Math.floor(
          Math.random() * 3
        ) + 1}.png`;

    return <img alt={"profile"} src={src} style={styles.image} />;
  }

  render() {
    const { summary } = this.props;

    const paths_count = summary.paths.length;
    const num_paths_text =
      paths_count === 1 ? `${paths_count} path` : `${paths_count} paths`;

    return (
      <Hover
        style={styles.container}
        action={() => {
          this.props.set_creator_id(summary.creator_id);
          this.props.change_screens("creator");
        }}
      >
        {this.render_image()}
        <div style={styles.content}>
          <div style={styles.name}>{summary.name}</div>
          <div>{num_paths_text}</div>
        </div>
      </Hover>
    );
  }
}

function state_to_props(state, ownProps) {
  return {
    summary: state.db[`${ownProps.creator_id}:summary`],
  };
}
export default connect(state_to_props, { set_creator_id, change_screens })(Creator);
