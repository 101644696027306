import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";

import Select from "react-select";

const styles = {
  filter: {
    margin: 4,
    marginRight: 15,
  },
  filter_label: {
    marginLeft: 3,
    marginBottom: 3,
    fontSize: 15,
    color: cstyles.text("rest"),
  },
  select: cstyles.react_select_style,
};

class FilterSelect extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    paths: PropTypes.array.isRequired,
    on_change: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  get_path_options() {
    return this.props.paths.map(path => {
      return { value: path.path_id, label: path.name };
    });
  }

  get_type_options() {
    return [
      { value: "daily", label: "Daily" },
      { value: "responsive", label: "Responsive" },
      { value: "onboarding", label: "Onboarding" },
    ];
  }

  get_options() {
    switch (this.props.type) {
      case "path":
        return this.get_path_options();
      case "type":
        return this.get_type_options();
      default:
        throw new Error("invalid type for FilterSelect");
    }
  }

  on_change(selected) {
    if (!selected) {
      this.props.on_change(this.props.type, []);
    } else {
      this.props.on_change(
        this.props.type,
        selected.map(option => {
          return option.value;
        })
      );
    }
  }

  render() {
    return (
      <div style={styles.filter}>
        <div style={styles.filter_label}>{this.props.type}</div>
        <Select
          styles={styles.select}
          options={this.get_options()}
          placeholder={"Show All"}
          isMulti
          onChange={selected => this.on_change(selected)}
        />
      </div>
    );
  }
}

function state_to_props(state) {
  const paths = state.path_comps.path_ids.map(path_id => {
    return state.db[path_id];
  });

  return {
    paths,
  };
}
export default connect(state_to_props, {})(FilterSelect);
