import React from "react";

function HDivider() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e6e6e6",
          height: "1px",
          width: "95%",
        }}
      />
    </div>
  );
}

const boxShadows = {
  0: "0",
  1: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  2: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  3: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  4: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  5: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
};

const today = new Date();
function is_today(date) {
  // determines if a date is today
  return is_same_date(date, today);
}

const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
function is_yesterday(date) {
  return is_same_date(date, yesterday);
}

function is_same_date(_a, _b) {
  // determines if two dates are on the same day

  // Convert a timestamp to date object
  const a = typeof _a === "number" ? new Date(_a) : _a;
  const b = typeof _b === "number" ? new Date(_b) : _b;

  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

function is_within_last_X_days(_date, days) {
  const date = typeof _date === "number" ? new Date(_date) : _date;

  const comparison_date = (d => new Date(d.setDate(d.getDate() - days)))(new Date());

  return date > comparison_date;
}

// Set Style
// Merges in a key to a style dict
function ss(check, original, to_merge) {
  if (check) {
    return {
      ...original,
      ...to_merge,
    };
  } else {
    return original;
  }
}

const cstyles = {
  raised: function(amount) {
    switch (amount) {
      case 0:
        return "#090609";
      case 1:
        return "#1a191a";
      case 2:
        return "#262526";
      case 3:
        return "#302f30";
      case 4:
        return "#404040";
      default:
        throw new Error("cstyles.raised() expected an amount");
    }
  },
  text: function(style) {
    switch (style) {
      case "inactive":
        return "#b3b3b3";
      case "faded":
        return "#cccccc";
      case "rest":
        return "#e6e6e6";
      case "active":
        return "white";
      default:
        throw new Error("cstyles.text() expected a style");
    }
  },
  border: function(style) {
    switch (style) {
      case "subtle":
        return "1px solid #262626";
      case "input":
        return "1px solid #595959";
      case "rest":
        return "1px solid #666666";
      case "active":
        return "1px solid white";
      case "warn":
        return "1px solid #ccc500";
      default:
        throw new Error("cstyles.border() expected a style");
    }
  },
  spinner: function(type) {
    switch (type) {
      case "small":
        return {
          border: "5px solid #1E1E1E",
          borderTop: "5px solid #bae7ff",
          borderRadius: "50%",
          width: 16,
          height: 16,
          animation: "spin 1s linear infinite",
          marginRight: 10,
        };

      default:
        throw new Error(`cstyles.spinner invalid type: ${type}`);
    }
  },
  bright_hover: { filter: "brightness(115%)" },
  brighter_hover: { filter: "brightness(180%)" },
  remove: "#CF6679",
  action_confirm: "#95de64",
  app_border: "#35373B",
  subtle: "#262626",
  react_select_style: {
    // see https://react-select.com/styles
    control: provided => ({
      ...provided,
      minWidth: 220,
      maxWidth: 400,
      backgroundColor: cstyles.raised(1),
      border: "none",
      userSelect: "none",
      color: cstyles.text("rest"),
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: cstyles.raised(1),
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? cstyles.text("active") : cstyles.text("rest"),
      backgroundColor: state.isFocused ? cstyles.raised(3) : cstyles.raised(1),
    }),
    input: provided => ({
      ...provided,
      color: cstyles.text("active"),
    }),
    singleValue: provided => ({
      ...provided,
      color: cstyles.text("rest"),
    }),
    placeholder: provided => ({
      ...provided,
      color: cstyles.text("rest"),
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: cstyles.raised(2),
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: cstyles.text("active"),
    }),
    multiValueRemove: (provided, state) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 4,
      backgroundColor: cstyles.raised(2),
    }),
    indicatorSeparator: provided => ({
      ...provided,
      opacity: 0.2,
    }),
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  centered_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  // space-between, centered row
  sbc_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  sb_col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  end_col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  small_button: {
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
  },
  medium_button: {
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
};

// Add self-referencing styles to cstyles
cstyles.chip_hover = {
  border: cstyles.border("active"),
  color: cstyles.text("active"),
};
cstyles.chip_selected = {
  backgroundColor: cstyles.raised(3),
  color: cstyles.text("active"),
};

const dev_mode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const uuid_regex = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;

function is_uuid(id) {
  return uuid_regex.test(id);
}

// Checks whether a key is syncable to the API
function is_syncable_key(key) {
  return is_uuid(key) || key.substring(0, 2) === "@@";
}

// used for the sync queue
function new_sync_item(id, action) {
  return {
    id,
    action,
  };
}

// gets the key name for common listed items in answers
//  multi_select item -> "option_id"
//  list item -> "item_id"
function get_answer_item_key(item) {
  if (item.item_id) {
    return "item_id";
  } else if (item.option_id) {
    return "option_id";
  } else {
    throw new Error(
      `can't determine item's key name in 
      getters get_answer_item_key - ${JSON.stringify(item)}`
    );
  }
}

// boy howdy is finding the proper regex for a URL a whole can of worms
// this is good enough for now - but certainly isn't perfect
const url_regex = /https?:\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;

export {
  HDivider,
  boxShadows,
  is_today,
  is_yesterday,
  is_same_date,
  is_within_last_X_days,
  ss,
  cstyles,
  dev_mode,
  is_uuid,
  new_sync_item,
  is_syncable_key,
  get_answer_item_key,
  url_regex,
};
