import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import {} from "../getters";

import Tag from "./Tag";

const styles = {
  title: {
    fontSize: 28,
    fontWeight: 600,
  },
  section: {
    marginTop: 10,
    marginBottom: 20,
  },
  section_title: {
    fontSize: 20,
    marginBottom: 5,
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    flexWrap: "wrap",
  },
};

class AboutSection extends Component {
  static propTypes = {
    sections: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_section(section) {
    if (section.style === "tags") {
      return (
        <div key={section.title} style={styles.section}>
          <div style={styles.section_title}>{section.title}</div>
          <div style={styles.tags}>
            {section.tags.map((tag, idx) => {
              return <Tag tag={tag} key={`${tag.text}:${idx}`} />;
            })}
          </div>
        </div>
      );
    } else {
      throw new Error(`unknown section type for creator page: '${section.style}'`);
    }
  }

  render() {
    const { sections } = this.props;

    if (!sections || sections.length === 0) {
      return null;
    }

    return (
      <div>
        <div style={styles.title}>About us</div>
        {sections.map(this.render_section)}
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    sections: state.db[`creator_data:${state.creator.id}`].page.about_sections,
  };
}
export default connect(state_to_props, {})(AboutSection);
