import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

const styles = {
  text: {
    color: cstyles.remove,
    fontSize: 13,
    fontWeight: 600,
    textAlign: "right",
    margin: 4,
    height: 16,
  },
};

class Error extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  get_error_text() {
    const { error } = this.props.auth;

    if (!error) {
      return "";
    }

    switch (error) {
      case "NotAuthorizedException":
        return "Incorrect password";
      case "UserNotFoundException":
        return "Email not found";
      case "InvalidParameterException":
        return "Invalid email";
      case "UsernameExistsException":
        return "Email already used";
      case "LimitExceededException":
        return "Try again after some time";
      case "CodeMismatchException":
        return "Incorrect code";
      default:
        return "Error";
    }
  }

  render() {
    return <div style={styles.text}>{this.get_error_text()}</div>;
  }
}

function state_to_props(state) {
  return {
    auth: state.auth,
  };
}
export default connect(state_to_props, {})(Error);
