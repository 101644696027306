import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_journals_screen_type } from "../redux/actions";

import { cstyles, ss } from "../common";

const styles = {
  container: {
    background: cstyles.raised(1),
    display: "flex",
    flexDirection: "row",
    width: "min-content",
    borderRadius: 8,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
    marginBottom: 30,
    position: "relative",
    height: 36,
    cursor: "pointer",
    userSelect: "none",
  },
  option: {
    marginLeft: 5,
    marginRight: 5,
    width: 115,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
    zIndex: 1,
  },
  slider: {
    backgroundColor: cstyles.raised(3),
    borderRadius: 8,
    position: "absolute",
    width: 115,
    height: 36,
    top: 5,
    left: 15,
    transition: "left 550ms",
  },
};

class DRSelector extends Component {
  static propTypes = {
    selected: PropTypes.string.isRequired,
    set_journals_screen_type: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_click() {
    if (this.props.selected === "dailys") {
      this.props.set_journals_screen_type("responsive");
    } else {
      this.props.set_journals_screen_type("dailys");
    }
  }

  render() {
    const slider_style = ss(this.props.selected === "responsive", styles.slider, {
      left: 140,
    });

    return (
      <div style={styles.container} onClick={() => this.on_click()}>
        <div style={slider_style} />
        <div style={styles.option}>Dailys</div>
        <div style={styles.option}>Responsive</div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    selected: state.journals.type,
  };
}
export default connect(state_to_props, { set_journals_screen_type })(DRSelector);
