import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import { get_ref_by_entryday } from "../getters";

import Button from "./Button";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    marginRight: 20,
    maxWidth: 200,
  },
  buttons: {
    maxHeight: 400,
    overflowY: "auto",
  },
  name: {
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 4,
  },
};

class ButtonSection extends Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    ref_answer: PropTypes.array,
    ref_entry: PropTypes.object,
    quick: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { button, ref_answer, ref_entry } = this.props;

    if (!ref_answer || ref_answer.length === 0) {
      return <div />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.name}>{button.name}</div>
        <div style={styles.buttons}>
          {ref_answer.map(item_id => {
            return (
              <Button
                key={item_id}
                id={item_id}
                question_id={button.ref_question_id}
                entry_id={ref_entry.entry_id}
                quick={this.props.quick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const button = state.db[ownProps.button_id];
  const { ref_answer, ref_entry } = get_ref_by_entryday(
    state,
    button.ref_question_id,
    null
  );

  return {
    button,
    ref_answer,
    ref_entry,
  };
}
export default connect(state_to_props, {})(ButtonSection);
