import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_screens, set_journals_screen_type } from "../redux/actions";

import { cstyles, is_within_last_X_days, ss } from "../common";
import { get_usable_journals } from "../getters";

import Hover from "../wrappers/Hover";

import QuickOption from "./QuickOption";

const styles = {
  container: {
    maxWidth: 345,
  },
  journals: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  view_all: {
    padding: 8,
    marginTop: 10,
    fontSize: 11,
    fontWeight: 600,
    color: cstyles.text("rest"),
    width: "min-content",
    display: "inline-block",
    whiteSpace: "nowrap",
    opacity: 0,
    transition: "opacity 500ms",
  },
};

class QuickSelect extends Component {
  static propTypes = {
    journal_type: PropTypes.string.isRequired,
    journal_ids: PropTypes.array.isRequired,
    change_screens: PropTypes.func.isRequired,
    set_journals_screen_type: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show_view_all: false,
    };

    setTimeout(() => {
      this.setState({
        show: true,
      });
    }, 251);

    setTimeout(() => {
      this.setState({
        show_view_all: true,
      });
    }, 251 + this.props.journal_ids.length * 100);
  }

  on_view_all() {
    this.props.change_screens("journals");
    this.props.set_journals_screen_type(this.props.journal_type);
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    const view_all_style = ss(this.state.show_view_all, styles.view_all, {
      opacity: 1,
    });

    return (
      <div style={styles.container}>
        <div style={styles.journals}>
          {this.props.journal_ids.map((journal_id, idx) => {
            return (
              <QuickOption
                key={`quickop:${journal_id}`}
                journal_id={journal_id}
                fade_idx={idx}
              />
            );
          })}
        </div>
        <Hover style={view_all_style} action={() => this.on_view_all()}>
          view all{" "}
          {this.props.journal_type === "dailys" ? "daily" : this.props.journal_type}{" "}
          journals
        </Hover>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  // Goal: Find the right (up to 6) journals to show, and in the right order

  const journal_type =
    ownProps.journal_type === "dailys" ? "daily" : ownProps.journal_type;

  // Get usable journals
  const usable_journal_ids = state.path_comps.path_ids.reduce(
    (journal_ids, path_id) => {
      return [...journal_ids, ...get_usable_journals(state, path_id)];
    },
    []
  );

  // Only get journals for our specified type
  let journal_ids = usable_journal_ids
    .map(journal_id => {
      return state.db[journal_id];
    })
    .filter(journal => {
      return journal.type === journal_type;
    })
    .map(journal => {
      return journal.journal_id;
    });

  // Set stats for each to 0
  let stats = {};
  journal_ids.forEach(journal_id => {
    stats[journal_id] = 0;
  });

  // Get entries within last 14 days for our journals, and
  // Build stats for journals with entries in the last 14 days

  state.entries
    .map(entry_id => {
      return state.db[entry_id];
    })
    .filter(entry => {
      return is_within_last_X_days(entry.timestamp, 14);
    })
    .filter(entry => {
      return journal_ids.includes(entry.journal_id);
    })
    .forEach(entry => {
      stats[entry.journal_id] += 1;
    });

  // Sort by total # of entries in the last two weeks
  journal_ids = journal_ids.sort((a, b) => {
    return stats[a] < stats[b];
  });

  // Limit to 6 journals
  journal_ids = journal_ids.slice(0, 6);

  return {
    journal_ids,
  };
}
export default connect(state_to_props, { change_screens, set_journals_screen_type })(
  QuickSelect
);
