import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import {} from "../getters";

const img_loading_placeholder = require("../icons/profile-default.jpg");

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 25,
  },
  name: {
    fontWeight: 600,
    fontSize: 36,
    marginBottom: 20,
  },
  body: {
    display: "flex",
    flexDirection: "row",
  },
  section: {
    flex: 1,
  },
  image_container: {
    minHeight: 300,
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    backgroundImage: `url(${img_loading_placeholder})`,
    backgroundSize: "cover",
  },
  quote: {
    fontSize: 20,
    textAlign: "justify",
    marginLeft: 20,
  },
  quote_subtext: {
    fontSize: 10,
    opacity: 0.75,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "right",
  },
  header_text: {
    fontSize: 24,
    marginLeft: 20,
  },
};

class HeroSection extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  // renders the big text next to the photo for image style
  render_big_text() {
    const { hero_header } = this.props.page;

    if (hero_header.quote) {
      const subtext = hero_header.quote.subtext.split("\n").map((line, idx) => {
        return <div key={`subtext:${idx}`}>{line}</div>;
      });

      return (
        <>
          <div style={styles.quote}>{`"${hero_header.quote.text}"`}</div>
          <div style={styles.quote_subtext}>{subtext}</div>
        </>
      );
    } else if (hero_header.text) {
      return <div style={styles.header_text}>{`${hero_header.text}`}</div>;
    } else {
      throw new Error(`unrecognized hero_header style`);
    }
  }

  // render if page.hero_header.style === image
  render_hero_image_style() {
    const { hero_header, page_style } = this.props.page;

    const name =
      page_style === "creator"
        ? `Meet ${this.props.page.short_name}`
        : `${this.props.page.short_name}`;

    return (
      <div>
        <div style={styles.name}>{name}</div>
        <div style={styles.body}>
          <div style={styles.section}>
            <div style={styles.image_container}>
              <img alt={"creator"} src={hero_header.source} style={styles.image} />
            </div>
          </div>
          <div style={styles.section}>{this.render_big_text()}</div>
        </div>
      </div>
    );
  }

  // renders when hero_header style === "none"
  render_none() {
    return (
      <div>
        <div style={styles.name}>{`${this.props.page.full_name}`}</div>
      </div>
    );
  }

  render() {
    const { page } = this.props;

    const render_by_style = style => {
      switch (style) {
        case "image":
          return this.render_hero_image_style();
        case "none":
          return this.render_none();
        default:
          throw new Error(`hero section style not recognized: ${style}`);
      }
    };

    return (
      <div style={styles.container}>{render_by_style(page.hero_header.style)}</div>
    );
  }
}

function state_to_props(state) {
  return {
    page: state.db[`creator_data:${state.creator.id}`].page,
  };
}
export default connect(state_to_props, {})(HeroSection);
