import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles } from "../common";
import { get_answer } from "../getters";

import HoverIcon from "../wrappers/HoverIcon";

import Confetti from "react-dom-confetti";

const styles = {
  container: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  },
  left: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 25,
  },
  right: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: 25,
  },
  icon_div: {
    marginRight: 150,
  },
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 13,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: cstyles.raised(1),
  },
  chip_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  confetti: {
    position: "absolute",
    bottom: 0,
    left: "45vw",
    backgroundColor: "red",
  },
};

const shuffle_icon = require("../icons/shuffle.png");

class Shuffle extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    update_entry: PropTypes.func.isRequired,
    question_id: PropTypes.string.isRequired,
    entry_id: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      final: false,
      rand_idx: 0,
    };
    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  on_key_press(e) {
    if (e.key === "Enter") {
      this.shuffle();
    }
  }

  set_random_item() {
    const { items } = this.props;

    let rand_idx = Math.floor(Math.random() * items.length);
    while (rand_idx === this.state.rand_idx) {
      rand_idx = Math.floor(Math.random() * items.length);
    }

    this.setState({
      rand_idx,
      selected: this.props.items[rand_idx].item_id,
    });
  }

  finalize() {
    this.setState({
      final: true,
    });

    this.props.update_entry(this.props.entry_id, this.props.question_id, "shuffle", [
      this.state.selected,
    ]);
  }

  set_time(delay) {
    setTimeout(() => {
      this.set_random_item();
      if (delay < 400) {
        this.set_time(Math.floor(delay * 1.2));
      } else {
        setTimeout(() => {
          this.finalize();
        }, 500);
      }
    }, delay);
  }

  shuffle() {
    if (this.props.items.length === 1) {
      this.setState(
        {
          selected: this.props.items[0].item_id,
        },
        () => {
          this.finalize();
        }
      );

      return;
    } else if (this.props.items.length === 0) {
      return;
    }

    this.set_time(20);
    this.setState({
      final: false,
    });
  }

  render_selected() {
    if (!this.state.selected) {
      return;
    }

    const item = this.props.items.filter(item => {
      return item.item_id === this.state.selected;
    })[0];

    return (
      <div style={styles.chip_container}>
        <div style={styles.chip}>{item.name}</div>
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.left}>
          <HoverIcon
            alt={"shuffle"}
            icon={shuffle_icon}
            action={() => this.shuffle()}
            icon_size={62}
          />
        </div>
        <div style={styles.right}>{this.render_selected()}</div>
        <div style={styles.confetti}>
          <Confetti
            active={this.state.final}
            config={{
              spread: 55,
              startVelocity: 30,
            }}
          />
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const question = state.db[state.editor.question_id];

  const my_journal_id = state.db[question.from_prompt_id].from_journal_id;
  const ref_journal_id =
    state.db[state.db[question.ref_question_id].from_prompt_id].from_journal_id;

  if (my_journal_id !== ref_journal_id) {
    throw new Error("unhandled Shuffle case: shuffling from a different journal");
  }

  const items = get_answer(
    state,
    question.ref_question_id,
    state.entry.entry_id,
    true
  ).filter(item => {
    return item ? true : false;
  });

  return {
    question,
    items,
    entry_id: state.entry.entry_id ? state.entry.entry_id : null,
    question_id: question.question_id,
  };
}
export default connect(state_to_props, { update_entry })(Shuffle);
