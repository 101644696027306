import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles, ss } from "../common";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  input: {
    padding: 6,
    fontSize: 12,
    border: cstyles.border("input"),
    background: cstyles.raised(2),
    borderRadius: 8,
    resize: "none",
    color: cstyles.text("rest"),
  },
};

class DelayInput extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    start: PropTypes.string,
    validator: PropTypes.func, // function for validating input. should return true/false
    on_focus: PropTypes.func, // func to call on focus and blur
    bold: PropTypes.bool, // whether to bold the text or not
    width: PropTypes.number, // how wide to make input
  };

  constructor(props) {
    super(props);
    this.state = {
      text: this.props.start || "",
      valid: true,
    };

    this.timer = null;
  }

  on_change(text) {
    this.setState({
      text,
    });

    if (text && this.props.validator) {
      const valid = this.props.validator(text.trim());

      this.setState({
        valid,
      });

      if (!valid) {
        return;
      }
    } else if (!text) {
      this.setState({
        valid: true,
      });
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.action(this.state.text.trim());
    }, 350);
  }

  on_focus(focus) {
    if (this.props.on_focus) {
      this.props.on_focus(focus);
    }
  }

  render() {
    const placeholder = this.props.placeholder || "new item";

    // Put yellow border on if invalid input
    let style = ss(!this.state.valid, styles.input, {
      border: cstyles.border("warn"),
    });

    style = ss(this.props.bold, style, {
      fontWeight: "bold",
    });

    style = ss(this.props.width, style, {
      width: this.props.width,
    });

    return (
      <div style={styles.container}>
        <input
          style={style}
          placeholder={placeholder}
          value={this.state.text}
          onChange={e => this.on_change(e.target.value)}
          maxLength={60}
          onFocus={e => this.on_focus(true)}
          onBlur={e => this.on_focus(false)}
        />
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(DelayInput);
