import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change_screens } from "./redux/actions";
import { ss, cstyles, dev_mode } from "./common";

import { ID } from "./appinfo";

const styles = {
  container: {
    width: 150,
    backgroundColor: cstyles.raised(1),
    userSelect: "none",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    fontWeight: 600,
    fontSize: 18,
    color: "white",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
  },
  row: {
    margin: 5,
    marginLeft: 10,
    padding: 10,
    paddingLeft: 10,
    fontSize: 18,
    borderRadius: 8,
    color: "#F5F5F5",
    transition: "padding 125ms, font-weight 125ms",
    cursor: "pointer",
  },
  row_selected: {
    backgroundColor: cstyles.raised(3),
    color: "white",
    fontWeight: 600,
    paddingLeft: 13,
  },
  id: {
    margin: 2,
    fontSize: 7,
    color: cstyles.text("inactive"),
  },
};

class RowComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    screen_name: PropTypes.string.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  render() {
    // Set row style on hover
    const row_style = ss(this.state.hover, styles.row, styles.row_selected);

    return (
      <div
        style={row_style}
        onClick={() => {
          this.props.change_screens(this.props.screen_name);
        }}
        onMouseEnter={() => {
          this.setState({
            hover: true,
          });
        }}
        onMouseOut={() => {
          this.setState({
            hover: false,
          });
        }}
      >
        {this.props.name}
      </div>
    );
  }
}

function row_state_to_props(state) {
  return { screen: state.app.screen };
}
const Row = connect(row_state_to_props, { change_screens })(RowComponent);

class Sidebar extends Component {
  static propTypes = {
    designer_access: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.container}>
        <div>
          <div style={styles.header}>wellsayer</div>
          <Row name="Home" screen_name="home" />
          <Row name="History" screen_name="history" />
          <Row name="Feelings" screen_name="affects" />
          <Row name="Paths" screen_name="paths" />
          <Row name="Search" screen_name="search" />
          {this.props.designer_access ? (
            <Row name="Designer" screen_name="designer" />
          ) : null}

          {dev_mode ? <Row name="Dev Tools" screen_name="dev" /> : null}
        </div>
        <div style={styles.id}>{ID}</div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    auth: state.auth,
    designer_access: !!state.profile.designer_access,
  };
}
export default connect(state_to_props, {})(Sidebar);
