import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_specific, change_screens, remove_entry } from "../redux/actions";

import { cstyles } from "../common";

import Hover from "../wrappers/Hover";
import HoverIcon from "../wrappers/HoverIcon";

import swal from "sweetalert";

const remove_icon = require("../icons/delete.png");

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  time_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: 100,
    marginRight: 10,
    marginTop: 5,
    fontSize: 14,
  },
  entry_card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 8,
    margin: 2,
    border: cstyles.border("subtle"),
    userSelect: "none",
    padding: "6px 12px 6px 12px",
    width: 220,
    minHeight: 55,
  },
  journal_title: {
    fontSize: 16,
    fontWeight: 600,
  },
  card_bottom_info_line: {
    fontSize: 12,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  no_journal_text: {},
  empty_card: {
    fontStyle: "italic",
    color: cstyles.text("inactive"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    margin: 2,
    border: cstyles.border("subtle"),
    userSelect: "none",
    padding: "6px 12px 6px 12px",
    width: 220,
    height: 45,
  },
};

class Entry extends Component {
  static propTypes = {
    entry: PropTypes.object.isRequired,
    journal: PropTypes.object,
    path_name: PropTypes.string.isRequired,
    set_entry_specific: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    remove_entry: PropTypes.func.isRequired,
    no_journal_def: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  format_time() {
    return new Date(this.props.entry.timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  on_click() {
    const { entry, journal } = this.props;
    this.props.set_entry_specific(entry.entry_id, journal.journal_id);
    this.props.change_screens("entry");
  }

  on_remove() {
    const { journal } = this.props;
    const date = new Date(this.props.entry.timestamp).toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const num_answers = Object.keys(this.props.entry.answers).length;

    swal({
      title: `Delete entry for ${journal.name} journal`,
      text: `Do you want to delete this entry?\n\nJournal Name: ${journal.name}\nEntry time: ${date}\nAnswers: ${num_answers}`,
      icon: "warning",
      buttons: ["Go back", "Delete entry"],
      dangerMode: true,
    }).then(choice => {
      if (choice) {
        this.props.remove_entry(this.props.entry.entry_id);
      }
    });
  }

  on_remove_with_no_journal() {
    const date = new Date(this.props.entry.timestamp).toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const num_answers = Object.keys(this.props.entry.answers).length;

    swal({
      title: `Delete entry?`,
      text: `Do you want to delete this entry?\n\n\nEntry time: ${date}\nAnswers: ${num_answers}`,
      icon: "warning",
      buttons: ["Go back", "Delete entry"],
      dangerMode: true,
    }).then(choice => {
      if (choice) {
        this.props.remove_entry(this.props.entry.entry_id);
      }
    });
  }

  render() {
    const { journal, path_name, no_journal_def } = this.props;

    if (no_journal_def) {
      return (
        <div style={styles.container}>
          <div style={styles.time_container}>{this.format_time()}</div>
          <div style={styles.empty_card}>
            <div style={{ marginLeft: 3 }}>
              journal has <br />
              been removed
            </div>
            <HoverIcon
              alt={"remove entry"}
              icon={remove_icon}
              action={() => {
                this.on_remove_with_no_journal();
              }}
              icon_size={16}
              stop_prop
            />
          </div>
        </div>
      );
    }

    return (
      <div style={styles.container}>
        <div style={styles.time_container}>{this.format_time()}</div>
        <Hover style={styles.entry_card} action={() => this.on_click()}>
          <div style={styles.header}>
            <div style={styles.journal_title}>{journal.name}</div>
            <HoverIcon
              alt={"remove entry"}
              icon={remove_icon}
              action={() => {
                this.on_remove();
              }}
              icon_size={16}
              stop_prop
            />
          </div>

          <div>
            <div style={styles.card_bottom_info_line}>
              {journal.type} | {path_name}
            </div>
          </div>
        </Hover>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const entry = state.db[ownProps.entry_id];
  const journal = state.db[entry.journal_id];
  const no_journal_def = journal ? false : true;
  return {
    entry,
    journal,
    path_name: no_journal_def ? "" : state.db[journal.from_path_id].name,
    no_journal_def,
  };
}
export default connect(state_to_props, {
  set_entry_specific,
  change_screens,
  remove_entry,
})(Entry);
