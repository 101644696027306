import React, { Component } from "react";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { ss } from "../common";
import {} from "../getters";

import SignUp from "../auth/SignUp";
import { clear_error } from "../auth/auth_actions";

const styles = {
  page: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    width: "100vw",
  },
  header: {
    fontSize: 36,
  },
  left: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 40,
    opacity: 0,
    transition: "opacity 1000ms",
  },
  right: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    transition: "opacity 1000ms",
  },
};

class NewScreen extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      show_left: false,
      show_right: false,
    };

    setTimeout(() => {
      this.setState({
        show_left: true,
      });
    }, 50);

    setTimeout(() => {
      this.setState({
        show_right: true,
      });
    }, 2000);

    clear_error();
  }

  render() {
    const left_style = ss(this.state.show_left, styles.left, { opacity: 1 });
    const right_style = ss(this.state.show_right, styles.right, { opacity: 1 });
    return (
      <div style={styles.page}>
        <div style={left_style}>
          <div style={styles.header}>Welcome to Wellsayer</div>
          <p>A platform for digital guided journaling</p>
          <p>
            Once you have signed in, click "getting started" on the top bar for an intro
            guide
          </p>
        </div>
        <div style={right_style}>
          <div>
            <SignUp />
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(NewScreen);
