import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { show_settings, toggle_quick_buttons } from "./redux/actions";

import { cstyles } from "./common";

import HoverIcon from "./wrappers/HoverIcon";
import Hover from "./wrappers/Hover";
import SyncIndicator from "./SyncIndicator";

const styles = {
  header: {
    paddingLeft: 28,
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
    position: "fixed",
    backgroundColor: cstyles.raised(0),
    left: 150,
    right: 0,
    top: 0,
    zIndex: 10,
    userSelect: "none",
  },
  header_right: {
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  screen_name: {
    color: "white",
    fontWeight: 600,
    fontSize: 28,
  },
  getting_started: {
    fontSize: 14,
    marginRight: 20,
    padding: 4,
    paddingRight: 8,
    paddingLeft: 8,
    color: cstyles.text("inactive"),
    fontWeight: 300,
  },
};

const settings_icon = require("./icons/settings.png");
const buttons_icon = require("./icons/button.png");

class Header extends Component {
  static propTypes = {
    screen: PropTypes.string.isRequired,
    show_settings: PropTypes.func.isRequired,
    toggle_quick_buttons: PropTypes.func.isRequired,
  };

  get_screen_name() {
    switch (this.props.screen) {
      case "home":
        return "Home";
      case "affects":
        return "Feelings";
      case "dev":
        return "Dev Tools";
      case "journals":
        return "Journals";
      case "entry":
        return "Journal Entry";
      case "history":
        return "Entry History";
      case "paths":
        return "My Paths";
      case "search":
        return "Search";
      case "creator":
        return "Creator Page";
      default:
        throw new Error("Need to set screen name in Header.js");
    }
  }

  render() {
    const screen_name = this.get_screen_name();

    return (
      <div style={styles.header}>
        <div style={styles.screen_name}>{screen_name}</div>
        <div style={styles.header_right}>
          <Hover
            style={styles.getting_started}
            action={() => {
              window.open(
                "https://public-creator-assets.s3-us-west-2.amazonaws.com/Wellsayer+-+getting+started.pdf",
                "_blank"
              );
            }}
          >
            getting started
          </Hover>
          <SyncIndicator />
          <HoverIcon
            alt={"buttons"}
            icon={buttons_icon}
            action={() => this.props.toggle_quick_buttons()}
            style={{ marginLeft: 20 }}
          />
          <HoverIcon
            alt={"settings"}
            icon={settings_icon}
            action={() => this.props.show_settings()}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    screen: state.app.screen,
  };
}
export default connect(state_to_props, { show_settings, toggle_quick_buttons })(Header);
