import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import {} from "../getters";

import SpotlightEntry from "./SpotlightEntry";

const styles = {
  section: {
    marginBottom: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  section_title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 2,
    marginLeft: 6,
  },
  entry_column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
  },
};

class SpotlightEntries extends Component {
  static propTypes = {
    entries: PropTypes.array,
  };

  render() {
    const { entries } = this.props;

    if (!entries || entries.length === 0) {
      return null;
    }

    // Split spotlight entries into two lists/columns
    let left_col = [];
    let right_col = [];
    entries.forEach((entry, idx) => {
      if (idx % 2 === 0) {
        left_col.push(entry);
      } else {
        right_col.push(entry);
      }
    });

    return (
      <div style={styles.section}>
        <div style={styles.section_title}>Spotlighted entries</div>
        <div style={styles.container}>
          <div style={styles.entry_column}>
            {left_col.map(entry => {
              return (
                <div style={{ width: "100%" }} key={`spotlight-entry:${entry.name}`}>
                  <SpotlightEntry entry={entry} />
                </div>
              );
            })}
          </div>
          <div style={styles.entry_column}>
            {right_col.map(entry => {
              return (
                <div style={{ width: "100%" }} key={`spotlight-entry:${entry.name}`}>
                  <SpotlightEntry entry={entry} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    entries: state.db[`creator_data:${state.creator.id}`].page.spotlight_entries,
  };
}
export default connect(state_to_props, {})(SpotlightEntries);
