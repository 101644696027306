import React, { Component } from "react";
import PropTypes from "prop-types";
import "./App.css";

import Sidebar from "./Sidebar";
import Header from "./Header";
import HomeScreen from "./home/HomeScreen";
import AffectsScreen from "./affects/AffectsScreen";
import Editor from "./editor/Editor";
import DevScreen from "./devtools/DevScreen";
import SettingOverlay from "./settings/SettingsOverlay";
import JournalsScreen from "./journals/JournalsScreen";
import EntryScreen from "./entry/EntryScreen";
import HistoryScreen from "./history/HistoryScreen";
import Whoops from "./Whoops";
import QuickButtons from "./buttons/QuickButtons";
import Welcome from "./landing/Welcome";
import Splash from "./Splash";
import NewScreen from "./landing/NewScreen";
import PathsScreen from "./paths/PathsScreen";
import SearchScreen from "./search/SearchScreen";
import CreatorScreen from "./creator/CreatorScreen";
import SideContext from "./entry/SideContext";
import StageComplete from "./entry/StageComplete";
import Loading from "./Loading";
import ShareModal from "./entry/ShareModal";

import { connect } from "react-redux";
import { app_open } from "./redux/actions";

// auth
import Amplify from "aws-amplify";
import { check_signed_in } from "./auth/auth_actions";
import awsconfig from "./auth/aws_configure.js";
import { cstyles } from "./common";

// Lazy loads to allow for code splitting
const DesignerScreen = React.lazy(() => import("./designer/DesignerScreen"));

Amplify.configure(awsconfig);

const styles = {
  page_container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    backgroundColor: cstyles.raised(0),
    color: cstyles.text("rest"),
    fontFamily: "Nunito",
  },
  screen_container: {
    position: "fixed",
    top: 60,
    left: 160,
    right: 0,
    bottom: 0,
  },
};

class App extends Component {
  static propTypes = {
    app_open: PropTypes.func.isRequired,
    screen: PropTypes.string.isRequired,
    show_editor: PropTypes.bool.isRequired,
    show_settings: PropTypes.bool.isRequired,
    show_quick_buttons: PropTypes.bool.isRequired,
    signed_in: PropTypes.bool,
    show_share_modal: PropTypes.bool.isRequired,
    show_side_context: PropTypes.bool.isRequired,
    show_stage_complete: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      logged_in: false,
      loading: true,
    };

    this.check_logged_in();
    this.props.app_open();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, info) {
    this.setState({
      errorInfo: info,
    });
  }

  render_editor() {
    if (this.props.show_editor) {
      return <Editor />;
    }
  }

  render_modal() {
    if (this.props.show_settings) {
      return <SettingOverlay />;
    } else if (this.props.show_share_modal && this.props.screen === "entry") {
      return <ShareModal />;
    }
  }

  render_quick_buttons() {
    if (this.props.show_quick_buttons) {
      return <QuickButtons />;
    }
  }

  ScreenLoader(screen) {
    if (screen === "home") {
      return <HomeScreen />;
    } else if (screen === "affects") {
      return <AffectsScreen />;
    } else if (screen === "dev") {
      return <DevScreen />;
    } else if (screen === "journals") {
      return <JournalsScreen />;
    } else if (screen === "entry") {
      return <EntryScreen />;
    } else if (screen === "history") {
      return <HistoryScreen />;
    } else if (screen === "paths") {
      return <PathsScreen />;
    } else if (screen === "search") {
      return <SearchScreen />;
    } else if (screen === "creator") {
      return <CreatorScreen />;
    } else {
      throw new Error("need to add screen to app.js");
    }
  }

  // check_signed_in() also runs sync_account and sync_latest actions after signed in
  // which are very important on-open syncs
  check_logged_in() {
    check_signed_in().then(result => {
      this.setState({
        loading: false,
      });

      // check again in 2s
      if (!result) {
        setTimeout(() => {
          check_signed_in();
        }, 2000);
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return <Whoops error={this.state.error} error_info={this.state.errorInfo} />;
    }

    if (this.state.loading) {
      return <Splash />;
    }

    if (!this.props.signed_in) {
      if (this.props.screen === "new") {
        return <NewScreen />;
      }

      return <Welcome />;
    }

    if (this.props.screen === "designer") {
      return (
        <React.Suspense fallback={<Loading page />}>
          <DesignerScreen />
        </React.Suspense>
      );
    }

    return (
      <div style={styles.page_container}>
        <Header />
        <Sidebar />

        <div style={styles.screen_container}>
          {this.ScreenLoader(this.props.screen)}
        </div>
        {this.render_editor()}
        {this.render_modal()}
        {this.render_quick_buttons()}
        {this.props.show_side_context ? <SideContext /> : null}
        {this.props.show_stage_complete ? <StageComplete /> : null}
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    screen: state.app.screen,
    show_editor: state.editor.show,
    show_settings: state.app.show_settings,
    show_quick_buttons: state.app.show_quick_buttons,
    signed_in: state.auth.signed_in,
    show_share_modal: state.entry.show_share_modal,
    show_side_context: state.entry.show_side_context,
    show_stage_complete: state.entry.show_stage_complete,
  };
}

// export default App;
export default connect(state_to_props, {
  app_open,
})(App);
