import {
  put,
  takeEvery,
  takeLeading,
  all,
  select,
  delay,
  takeLatest,
} from "redux-saga/effects";

import * as AT from "./action_types";
import * as actions from "./actions";

import { new_entry } from "../create_new";
import { resolve_item } from "./resolvers";
import API from "../auth/API";

import { new_sync_item } from "../common";

import swal from "sweetalert";

// Syncs items in queue to API
function* sync_queue() {
  const state = yield select();
  let sync_queue = [...state.sync.queue]; // can be extended in the loop below

  // list of ids that have been succesfully synced to the API
  let succesfully_synced = [];

  for (let i = 0; i < sync_queue.length; i++) {
    if (sync_queue[i].action === "update") {
      const { item, key_name } = resolve_item(state, sync_queue[i].id);
      const { success, retry, err } = yield API.save_item(item, key_name);

      if (success) {
        succesfully_synced.push(sync_queue[i].id);

        // add any refs to the sync queue
        if (item.refs && item.refs.length > 0) {
          item.refs.forEach(id => {
            sync_queue.push(new_sync_item(id, "update"));
          });
        }
      } else if (!retry) {
        throw new Error(
          `could not sync item to API. item: '${JSON.stringify(
            item,
            null,
            2
          )}' --- err: ${err}`
        );
      }
      // no retry logic because it will be retried the next time the upload queue is processed
    }
    // remove
    else if (sync_queue[i].action === "remove") {
      const { success, retry, err } = yield API.remove_item(sync_queue[i].id);
      if (success) {
        succesfully_synced.push(sync_queue[i].id);
      } else if (!retry) {
        throw new Error(
          `could not execute API remove item. id: ${sync_queue[i].id} err: ${err}`
        );
      }
      // no retry logic because - same as above
    }
  }

  if (succesfully_synced.length > 0) {
    yield put(actions.sync_success(succesfully_synced));
  }
}

function* get_latest() {
  const { sync } = yield select();

  // determine cutoff for syncing
  const cutoff = sync.last_sync
    ? sync.last_sync - 60 * 1000 // one minute before the last sync
    : new Date().getTime() - 1209600000; // two weeks ago

  // get latest from API
  let synced_items = [];
  let success, data, retry, err, page_token;
  do {
    ({ success, data, retry, err } = yield API.get_latest(cutoff, page_token));

    if (retry) {
      yield delay(3000); // delay for 3s then try again
      return;
    }

    if (err) {
      throw new Error(`error syncing latest changes: ${err}`);
    }

    if (success) {
      synced_items = synced_items.concat(data.items);
    }

    if (data.page_token) {
      page_token = data.page_token;
    }
  } while (retry || page_token);

  yield put(actions.sync_latest_success(synced_items));
}

function* sync_account() {
  const { success, data, retry, err } = yield API.sync_account();

  if (retry) {
    yield delay(5000); // delay for 5s then try again
    yield put(actions.sync_account());
    return;
  }

  if (err) {
    throw new Error(`error syncing account: ${err}`);
  }

  if (success) {
    yield put(actions.sync_account_success(data));
  }
}

// Watches for things that do syncs
function* watch_syncs() {
  yield takeEvery(
    [
      AT.APP_OPEN,
      AT.ENTRY_UPDATE,
      AT.ENTRY_REMOVE,
      AT.UPDATE_QUESTION_OPS,
      AT.NEW_AFFECT_CATEGORY,
      AT.UPDATE_AFFECT_CATEGORY,
      AT.REMOVE_AFFECT_CATEGORY,
      AT.NEW_FEELING,
      AT.UPDATE_FEELING,
      AT.REMOVE_FEELING,
      AT.SET_JOURNAL_ACTIVE,
      AT.ENTRY_SHARING_UPDATED,
      // designer actions
      AT.DESIGNER_NEW_PATH,
      AT.REMOVE_DRAFT_PATH,
      AT.DESIGNER_NEW_JOURNAL,
      AT.REMOVE_DRAFT_JOURNAL,
      AT.REORDER_DRAFT_JOURNALS,
      AT.DESIGNER_NEW_PROMPT,
      AT.REMOVE_DRAFT_PROMPT,
      AT.DESIGNER_NEW_QUESTION,
      AT.REMOVE_DRAFT_QUESTION,
      AT.UPDATE_DRAFT_PATH_INFO,
      AT.UPDATE_DRAFT_JOURNAL_INFO,
      AT.UPDATE_DRAFT_PROMPT_INFO,
      AT.UPDATE_DRAFT_QUESTION_INFO,
      AT.REORDER_DRAFT_PATHS,
    ],
    () => sync_queue()
  );
  yield takeLatest(AT.SYNC_LATEST, () => get_latest());
  yield takeLatest(AT.SYNC_ACCOUNT, () => sync_account());
}

function* load_creator(id) {
  let loop_retry;
  do {
    const { success, data, retry, err } = yield API.get_creator_page(id);
    loop_retry = retry;

    if (retry) {
      yield delay(5000); // delay for 5s then try again
    }

    if (err) {
      yield put(actions.set_error("creator_page", "error_get_creator", err.toString()));
      throw new Error(`error loading creator data: ${err}`);
    }

    if (success) {
      yield put(actions.load_creator_success(data));
      loop_retry = false; // loop_retry should already be falsy, but this ensures it
    }
  } while (loop_retry);
}

function* watch_creator_actions() {
  yield takeLeading(AT.LOAD_CREATOR_BY_ID, action => load_creator(action.id));
}

function* on_account_change(action) {
  if (["subscribe_to_creator", "unsubscribe_from_creator"].includes(action.change)) {
    const { success, retry } = yield API.change_account(action.change, action.data);

    if (success) {
      yield put(actions.account_change_success(action.change, action.data));

      // resync account
      yield delay(1000); // delay was necessary otherwise the change hadn't propogated yet, and the API call would return old data - I haven't validated if less delay works
      yield put(actions.sync_account());
    } else if (retry) {
      yield put(actions.account_change(action.change, action.data));
    }
  }
}

function* watch_account_change() {
  yield takeEvery(AT.ACCOUNT_CHANGE, action => on_account_change(action));
}

function* add_entry(minus_days) {
  const state = yield select();
  const { journal_ids } = state.path_comps;

  let entry;
  let today = new Date();

  let new_date = new Date().setDate(today.getDate() - minus_days);

  // Add a random amount of minutes
  new_date += Math.floor(Math.random() * 24 * 60 * 60 * 1000) - 12 * 60 * 60 * 1000;

  entry = new_entry(
    journal_ids[Math.floor(Math.random() * journal_ids.length)],
    new_date
  );
  yield put(
    actions.dev_action("update_db", {
      id: entry.entry_id,
      value: entry,
    })
  );
  yield put(actions.dev_action("add_entry_id", entry.entry_id));
}

function* on_dev(action) {
  if (action === "add_random_entries") {
    yield add_entry(0);
    yield add_entry(0);
    yield add_entry(1);
    yield add_entry(Math.floor(Math.random() * 15));
    yield add_entry(Math.floor(Math.random() * 15));
    yield add_entry(Math.floor(Math.random() * 15));
    yield add_entry(Math.floor(Math.random() * 20));
    yield add_entry(Math.floor(Math.random() * 20));
    yield add_entry(Math.floor(Math.random() * 20));
    yield add_entry(Math.floor(Math.random() * 20));
    yield add_entry(Math.floor(Math.random() * 25));
    yield add_entry(Math.floor(Math.random() * 25));
    yield add_entry(Math.floor(Math.random() * 25));
    yield add_entry(Math.floor(Math.random() * 25));
    yield add_entry(Math.floor(Math.random() * 25));
  }
}

function* watch_dev() {
  yield takeEvery(AT.DEV_ACTION, action => on_dev(action.action));
}

function* load_pathdef(creator_id, path_id) {
  let loop_retry;
  do {
    const { success, data, retry, err } = yield API.get_pathdef(creator_id, path_id);
    loop_retry = retry;

    if (retry) {
      yield delay(5000); // delay for 5s then try again
    }

    if (err) {
      throw new Error(`error loading pathdef: ${err}`);
    }

    if (success) {
      yield put(actions.load_pathdef_success(data.pathdef));
      loop_retry = false; // loop_retry should already be falsy, but this ensures it
    }
  } while (loop_retry);
}

// Assures that we have the assets we need loaded from the API
function* asset_manager() {
  const state = yield select();

  // ---- pathdefs ----
  // Make sure we have pathdefs loaded for all the paths we're subscribed to
  for (let i = 0; i < state.profile.subscribed_creators.length; i++) {
    const sub = state.profile.subscribed_creators[i];

    // get creator summary
    const summary = state.db[`${sub.creator_id}:summary`];

    for (let j = 0; j < summary.paths.length; j++) {
      const pathdef = state.db[summary.paths[j].path_id];

      // load the pathdef if we don't have it
      if (!pathdef) {
        yield load_pathdef(sub.creator_id, summary.paths[j].path_id);
      }
      // Check to see if we're missing a journal (if so, load pathdef)
      else if (
        pathdef.journals.some(journal => {
          if (!state.db[journal.journal_id]) {
            return true;
          }
          return false;
        })
      ) {
        yield load_pathdef(sub.creator_id, summary.paths[j].path_id);
      }
      // TODO - check to see if something changed through a hash check or similar
    }
  }
}

function* watch_assets() {
  yield takeLatest(AT.SYNC_ACCOUNT_SUCCESS, () => asset_manager());
}

// Does all the steps to share an entry
function* share_entry(entry_id) {
  const state = yield select();

  const entry = state.db[entry_id];
  if (!entry) {
    throw new Error(`entry not found in share_entry`);
  }

  // Prepare the questions list
  let questions = [];
  const journal = state.db[entry.journal_id];
  if (!journal) {
    throw new Error(`couldn't get journal during share_entry`);
  }
  journal.prompts.forEach(prompt => {
    prompt.questions.forEach(question => {
      // add the question if we answered it
      if (question.question_id in entry.answers) {
        questions.push({
          question_id: question.question_id,
          question: question.question,
          type: question.type,
        });
      }
      // add if it's a checkbox (no answer is an answer)
      else if (question.type === "checkbox") {
        questions.push({
          question_id: question.question_id,
          question: question.question,
          type: question.type,
        });
      }
    });
  });

  const when = new Date(entry.timestamp).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  // Inform the API we'd like to share this entry, and get our share token
  let loop_retry;
  let token;
  do {
    const { success, data, retry, err } = yield API.share_entry(
      entry.entry_id,
      questions,
      journal.name,
      when,
      state.settings.accent_color
    );
    loop_retry = retry;

    if (retry) {
      yield delay(5000); // delay for 5s then try again
    }

    if (err) {
      throw new Error(`error loading pathdef: ${err}`);
    }

    if (success) {
      token = data;
      loop_retry = false; // loop_retry should already be falsy, but this ensures it
    }
  } while (loop_retry);

  // quick validation
  if (!token.token_id) {
    throw new Error(
      `token error in share_entry saga: token: ${JSON.stringify(token, null, 2)}`
    );
  }
  if (token.entry_id !== entry.entry_id) {
    throw new Error(`token entry_id and local entry_id don't match`);
  }

  // Update the entry with shared info
  // every shared item needs a shared key, which:
  //    1. contains the details
  //    2. is used as an additional permissions layer
  yield put(actions.entry_sharing_updated(entry.entry_id, true, token));
}

// Does all the steps to revoke an entry's sharing
function* revoke_entry_sharing(entry_id) {
  const state = yield select();

  const entry = state.db[entry_id];
  if (!entry) {
    throw new Error(`entry not found in share_entry`);
  }

  // Inform the API we'd like to share this entry, and get our share token
  let loop_retry;
  do {
    const { success, retry, err } = yield API.revoke_entry_sharing(entry.entry_id);
    loop_retry = retry;

    if (retry) {
      yield delay(5000); // delay for 5s then try again
    }

    if (err) {
      throw new Error(`error loading pathdef: ${err}`);
    }

    if (success) {
      loop_retry = false; // loop_retry should already be falsy, but this ensures it
    }
  } while (loop_retry);

  // Remove sharing info from entry
  yield put(actions.entry_sharing_updated(entry.entry_id, false));
}

function* search_creators(action) {
  const { search_type } = action;

  let loop_retry;
  let res;
  do {
    const { success, data, retry, err } = yield API.search_creators(search_type);
    loop_retry = retry;

    if (retry) {
      yield delay(3000); // delay for 3s then try again
    }

    if (err) {
      throw new Error(`error searching for creators: ${err}`);
    }

    if (success) {
      res = data;
      loop_retry = false; // loop_retry should already be falsy, but this ensures it
    }
  } while (loop_retry);

  if (res) {
    yield put(actions.search_creators_success(search_type, res));
  }
}

function* watch_sharing() {
  yield takeLatest(AT.SHARE_ENTRY, action => share_entry(action.entry_id));
  yield takeLatest(AT.REVOKE_ENTRY_SHARING, action =>
    revoke_entry_sharing(action.entry_id)
  );
}

function* watch_search() {
  yield takeLatest(AT.SEARCH_CREATORS, action => search_creators(action));
}

function* publish_path(action) {
  const { path_id, name } = action; // name is temporary - TODO, update
  const state = yield select();
  const path = state.my_content.draft_paths[path_id];

  const { success, err } = yield loop_request(API.publish_path, [path, name]);

  if (success) {
    yield put(actions.designer_publish_path_success());
    yield put(actions.sync_account());
  } else {
    // ask for name sequence. This is a temporary solution until a page manager is developed
    if (err.includes("No creator profile. Please supply name param in request.")) {
      const name = yield swal({
        title: "Set your creator name",
        text: `!! Note you can't undo this - so double check spelling!\n\nEnter the name that you want on your creator profile.`,
        content: "input",
        button: {
          text: "Set my creator name",
        },
      });

      yield put(actions.designer_publish_path(path_id, name));
    } else {
      yield put(actions.designer_publish_path_error());
    }
  }
}

function* watch_path_manager() {
  yield takeLatest(AT.DESIGNER_PUBLISH_PATH, action => publish_path(action));
}

function* sagas() {
  yield all([
    watch_dev(),
    watch_syncs(),
    watch_creator_actions(),
    watch_account_change(),
    watch_assets(),
    watch_sharing(),
    watch_search(),
    watch_path_manager(),
  ]);
}

function* loop_request(func, args, ms_delay = 5000) {
  // set func's this to API class
  func = func.bind(API);

  let loop_retry;
  do {
    const { success, data, retry, err } = yield func(...args);
    loop_retry = retry;

    if (retry) {
      yield delay(ms_delay); // delay then try again
    }

    if (err) {
      return { err };
    }

    if (success) {
      return { success, data, retry, err };
    }
  } while (loop_retry);
}

export { sagas };

// ------------------------------------------------------------------------------------
// ----------------------------------- ARCHIVE ----------------------------------------
// ------------------------------------------------------------------------------------

/** 

const Airtable = require("airtable");
function get_base(settings) {
  return new Airtable({ apiKey: settings.api_key }).base(settings.base);
}

function get_records(base, max_records = 10) {
  return new Promise((resolve, reject) => {
    const retrieved_records = {};
    base("Habyts")
      .select({
        maxRecords: max_records,
        sort: [{ field: "Date", direction: "desc" }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function(record) {
            retrieved_records[record.id] = record.fields;
            retrieved_records[record.id]["id"] = record.id;
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) resolve();
          resolve(retrieved_records);
        }
      );
  });
}

function* load_recent(action) {
  const base = get_base(action.settings);
  const res = yield get_records(base, 10);
  if (res) {
    yield put(actions.load_recent_success(res));
  } else {
    yield put(actions.load_recent_error());
  }
}

function check_airtable_creds(base, table) {
  // checks whether or not airtable credentials are good
  // true = good, false = error
  return new Promise((resolve, reject) => {
    base(table)
      .select({
        maxRecords: 1,
      })
      .firstPage(function(err, records) {
        if (err) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
  });
}
function* save_airtable(action) {
  // setup airtable base
  Airtable.configure({
    apiKey: action.api_key,
  });
  const base = Airtable.base(action.base);

  // check connection to airtable
  const creds_okay = yield check_airtable_creds(base, action.table);
  if (creds_okay === true) {
    yield put(actions.save_airtable_success());
  } else {
    yield put(actions.save_airtable_err());
  }
}

*/
