// Standardized data structures for actions (eg, on update_entry)

function mark_todo(completed, item) {
  return {
    action: "update",
    item: {
      ...item,
      completed,
    },
  };
}

export { mark_todo };
