import { v4 as uuidv4 } from "uuid";

function new_qops(question_id) {
  return {
    qops_id: uuidv4(),
    question_id: question_id,
    options: {},
    obj_type: "qops",
  };
}

function new_multi_option(name) {
  return {
    option_id: uuidv4(),
    name: name,
    obj_type: "multi_option",
  };
}

function new_entry(journal_id, timestamp = null) {
  // Creates a new entry for a journal

  if (!timestamp) {
    timestamp = new Date().getTime();
  }

  return {
    entry_id: uuidv4(),
    journal_id: journal_id,
    timestamp,
    answers: {},
    reacts: {},
    obj_type: "entry",
  };

  /* reacts structure - saves react status for prompts 
  reacts: {
    prompt_id: action
  }

  action: null || disable || etc
  */
}

function new_affect_category() {
  return {
    category_id: uuidv4(),
    name: "",
    description: "",
    obj_type: "affect_cat",
  };
}

function new_feeling() {
  return {
    feeling_id: uuidv4(),
    name: "",
    category_id: "",
    description: "",
    obj_type: "feeling",
  };
}

// Editor/List
function new_list_item(name, item_type = null) {
  if (!item_type) {
    return {
      item_id: uuidv4(),
      name,
      obj_type: "list_item",
    };
  } else if (item_type === "todo") {
    return {
      item_id: uuidv4(),
      name,
      style: "todo",
      completed: false,
      obj_type: "list_item",
    };
  }
}

// Editor/ScheduleTableList
function new_schedule_row() {
  return {
    item_id: uuidv4(),
    name: "",
    time: "",
    items: [],
    length: 0, // length / duration between start and end time - auto calculated
    obj_type: "schedule_row",
  };
}

function new_path() {
  return {
    creator_id: null,
    path_id: uuidv4(),
    name: "",
    description: "",
    journals: [],
    buttons: [],
  };
}

function new_journal() {
  return {
    journal_id: uuidv4(),
    name: "",
    description: "",
    type: "",
    highlight_color: "#",
    prompts: [],
  };
}

function new_prompt() {
  return {
    prompt_id: uuidv4(),
    name: "",
    questions: [],
  };
}

function new_question() {
  return {
    question_id: uuidv4(),
    type: "",
    question: "",
  };
}

export {
  new_qops,
  new_multi_option,
  new_entry,
  new_affect_category,
  new_feeling,
  new_list_item,
  new_schedule_row,
  new_path,
  new_journal,
  new_prompt,
  new_question,
};
