import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_affect_category, remove_affect_category } from "../redux/actions";

import swal from "sweetalert";

import Divider from "./Divider";
import DeleteButton from "./DeleteButton";
import { cstyles } from "../common";

const styles = {
  row: {
    marginTop: 3,
    userSelect: "none",
    opacity: 0,
    transition: "opacity 350ms",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
    marginRight: 8,
  },
  name: {
    fontSize: 14,
    border: "none",
    width: "180px",
    backgroundColor: cstyles.raised(1),
    color: cstyles.text("active"),
  },
  description: {
    fontSize: 14,
    width: "100%",
    borderRadius: 4,
    border: cstyles.border("active"),
    padding: 4,
    backgroundColor: cstyles.raised(2),
    color: cstyles.text("active"),
  },
};

class CategoryRow extends Component {
  static propTypes = {
    category_id: PropTypes.string.isRequired,
    category: PropTypes.object.isRequired,
    update_affect_category: PropTypes.func.isRequired,
    remove_affect_category: PropTypes.func.isRequired,
    order: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { name, description } = this.props.category;
    this.state = {
      name: name,
      opacity: 0,
      description: description,
    };

    this.name_change_timer = null;
    this.description_change_timer = null;

    setTimeout(() => {
      this.setState({
        opacity: 1,
      });
    }, this.props.order * 75);
  }

  on_update_name(e) {
    const text = e.target.value;
    this.setState({
      name: text,
    });

    clearTimeout(this.name_change_timer);
    this.name_change_timer = setTimeout(() => {
      this.props.update_affect_category(
        this.props.category_id,
        "name",
        this.state.name
      );
    }, 500);
  }

  on_update_description(e) {
    const text = e.target.value;
    this.setState({
      description: text,
    });

    clearTimeout(this.description_change_timer);
    this.description_change_timer = setTimeout(() => {
      this.props.update_affect_category(
        this.props.category_id,
        "description",
        this.state.description
      );
    }, 500);
  }

  on_remove() {
    // Get name, set empty if not present
    let { name } = this.props.category;
    if (!name) {
      name = "<no name>";
    }

    swal({
      title: "Remove " + name,
      text: `Are you sure want to remove ${name} from your categories?\n\n`,
      icon: "warning",
      buttons: ["Keep " + name, "Remove " + name],
      dangerMode: true,
    }).then(choice => {
      if (choice) {
        this.props.remove_affect_category(this.props.category.category_id);
      }
    });
  }

  render() {
    const row_style = { ...styles.row, ...{ opacity: this.state.opacity } };

    return (
      <div style={row_style}>
        <Divider />{" "}
        <div style={styles.content}>
          <div style={{ width: "200px" }}>
            <input
              style={styles.name}
              placeholder={"enter a name"}
              value={this.state.name}
              onChange={e => this.on_update_name(e)}
              maxLength={25}
            />
          </div>
          <div style={{ flex: 1 }}>
            <input
              style={styles.description}
              placeholder={"enter a description"}
              value={this.state.description}
              onChange={e => this.on_update_description(e)}
            />
          </div>
          <DeleteButton callback={() => this.on_remove()} />
        </div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  return {
    category: state.db[ownProps.category_id],
  };
}
export default connect(state_to_props, {
  update_affect_category,
  remove_affect_category,
})(CategoryRow);
