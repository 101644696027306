import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  load_creator_by_id,
  change_screens,
  set_creator_id,
  set_error,
  account_change,
} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import PathsSection from "./PathsSection";
import SpotlightEntries from "./SpotlightEntries";

const plus_icon = require("../icons/add_light.png");
const remove_icon = require("../icons/remove.png");

const styles = {
  page: {
    height: "100%",
    overflowY: "scroll",
    padding: 10,
    marginRight: 20,
  },
  container: {
    maxWidth: 1000,
  },
  centered_page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  spinner: {
    border: "8px solid " + cstyles.raised(0),
    borderTop: "8px solid #bae7ff",
    borderRadius: "50%",
    width: 40,
    height: 40,
    animation: "spin 1s linear infinite",
  },
  small_spinner: {
    border: "4px solid " + cstyles.raised(0),
    borderTop: "4px solid #bae7ff",
    borderRadius: "50%",
    width: 20,
    height: 20,
    animation: "spin 1s linear infinite",
  },
  error_button: {
    padding: 12,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 3,
    marginTop: 30,
  },
  body: {
    display: "flex",
    flexDirection: "row",
  },
  body_column: {
    width: "50%",
  },
  subscribe_button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 20,
    border: cstyles.border("subtle"),
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 22,
    borderRadius: 4,
  },
};

class CreatorScreen extends Component {
  static propTypes = {
    load_creator_by_id: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    set_creator_id: PropTypes.func.isRequired,
    set_error: PropTypes.func.isRequired,
    creator_id: PropTypes.string,
    creator_data: PropTypes.object,
    error: PropTypes.string,
    is_subscribed: PropTypes.bool.isRequired,
    account_change: PropTypes.func.isRequired,
    sub_unsub_loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    // check to see if we need to fetch the creator page
    const { creator_id } = this.props;

    // this means that the creator_id wasn't saved in the cache somehow
    if (!creator_id) {
      this.props.change_screens("search");
      return;
    }

    // get / refresh creator data
    this.props.load_creator_by_id(creator_id);

    this.check_if_loaded();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.creator_data !== this.props.creator_data ||
      prevProps.error !== this.props.error
    ) {
      this.check_if_loaded();
    }
  }

  check_if_loaded() {
    const { creator_data, error } = this.props;

    if (error || creator_data) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
    }
  }

  render_error() {
    const { error } = this.props;

    let error_message = "error loading creator";
    if (error === "error_creator_not_found") {
      error_message = "Creator not found";
    }

    return (
      <div style={styles.centered_page}>
        <div style={{ fontSize: 18 }}>{error_message}</div>
        <Hover
          action={() => {
            this.props.set_creator_id("");
            this.props.set_error("creator_page", null, null);
            this.props.change_screens("search");
          }}
          style={styles.error_button}
        >
          reset
        </Hover>
      </div>
    );
  }

  // param subscribe = true ?  -> subscribe
  //       subscribe = false ? -> UNsubscribe
  on_subscribe(subscribe) {
    if (subscribe) {
      this.props.account_change("subscribe_to_creator", {
        creator_id: this.props.creator_id,
      });
    } else {
      this.props.account_change("unsubscribe_from_creator", {
        creator_id: this.props.creator_id,
      });
    }
  }

  render_subscribe() {
    const { is_subscribed, sub_unsub_loading } = this.props;

    const button = () => {
      if (sub_unsub_loading) {
        return (
          <div style={styles.subscribe_button}>
            <div style={styles.small_spinner} />
          </div>
        );
      }

      if (is_subscribed) {
        return (
          <Hover
            style={styles.subscribe_button}
            action={() => this.on_subscribe(false)}
          >
            <img
              src={remove_icon}
              alt={"remove"}
              style={{ width: 40, marginRight: 5 }}
            />
            unsubscribe
          </Hover>
        );
      } else {
        return (
          <Hover style={styles.subscribe_button} action={() => this.on_subscribe(true)}>
            <img src={plus_icon} alt={"add"} style={{ width: 40, marginRight: 5 }} />
            subscribe
          </Hover>
        );
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {button()}
      </div>
    );
  }

  render_body() {
    // give conditional rendering -- if everything in the left column is blank - just don't render it

    const page = this.props.creator_data.page;

    const about_is_empty = !page.about_sections || page.about_sections.length === 0;
    const spotlight_entries_is_empty =
      !page.spotlight_entries || page.spotlight_entries.length === 0;

    if (about_is_empty && spotlight_entries_is_empty) {
      return (
        <div style={styles.body_column}>
          {this.render_subscribe()}
          <PathsSection />
        </div>
      );
    } else {
      return (
        <>
          <div style={styles.body_column}>
            <SpotlightEntries />
            <AboutSection />
          </div>
          <div style={styles.body_column}>
            {this.render_subscribe()}
            <PathsSection />
          </div>
        </>
      );
    }
  }

  render() {
    const { loading } = this.state;
    const { error } = this.props;

    if (loading) {
      return (
        <div style={styles.centered_page}>
          <div style={styles.spinner} />
        </div>
      );
    }

    if (error) {
      return this.render_error();
    }

    return (
      <div style={styles.page}>
        <div style={styles.container}>
          <div>
            <HeroSection />
          </div>
          <div style={styles.body}>{this.render_body()}</div>
          <div style={{ minHeight: 200 }} />
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const creator_id = state.creator.id;

  const is_subscribed = state.profile.subscribed_creators.some(sub => {
    return sub.creator_id === creator_id;
  });

  return {
    creator_id,
    creator_data: state.db[`creator_data:${state.creator.id}`],
    error: state.errors.creator_page,
    is_subscribed,
    sub_unsub_loading: state.loading.sub_unsub_creator || state.loading.sync_account,
  };
}
export default connect(state_to_props, {
  load_creator_by_id,
  change_screens,
  set_creator_id,
  set_error,
  account_change,
})(CreatorScreen);
