import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { ss } from "../common";
import {} from "../getters";

const styles = {
  default_container_additions: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    userSelect: "none",
    position: "relative",
    transition: "filter 200ms",
  },
  blur: {
    filter: "blur(4px)",
  },
  on_hover_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: "opacity 250ms",
  },
};

class HoverReplace extends Component {
  static propTypes = {
    style: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    hover_children: PropTypes.element.isRequired,
    stop_prop: PropTypes.bool, // whether to stop click event propagation
    action: PropTypes.func.isRequired, // on click callback
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  on_click(e) {
    if (this.props.stop_prop) {
      e.stopPropagation();
    }

    this.props.action(e);
  }

  render() {
    // add default styling additions
    let container_style = {
      ...styles.default_container_additions,
      ...this.props.style,
    };

    // add blur on hover
    container_style = ss(this.state.hover, container_style, styles.blur);

    // show hover container
    const hover_container_style = ss(this.state.hover, styles.on_hover_container, {
      opacity: 1,
    });

    return (
      <div
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={e => this.on_click(e)}
        style={{ position: "relative", cursor: "pointer" }}
      >
        <div style={container_style}>{this.props.children}</div>
        <div style={hover_container_style}>{this.props.hover_children}</div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(HoverReplace);
