import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_journal_active, set_creator_id, change_screens } from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Path from "./Path";

import Hover from "../wrappers/Hover";

const styles = {
  container: {
    maxWidth: 650,
    marginTop: 20,
    marginBottom: 10,
    paddingTop: 15,
    borderStyle: "solid none none none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
  },
  header_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  creator_name: {
    fontWeight: 700,
    fontSize: 32,
  },
  header_right_section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  header_view_creator: {
    marginRight: 25,
    fontSize: 14,
    padding: 9,
    paddingTop: 4,
    paddingBottom: 4,
  },
};

class Creator extends Component {
  static propTypes = {
    summary: PropTypes.object.isRequired,
    inactive_ids: PropTypes.object.isRequired,
    set_creator_id: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  go_to_creator_page() {
    this.props.set_creator_id(this.props.summary.creator_id);
    this.props.change_screens("creator");
  }

  render() {
    const { summary } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.header_row}>
          <div style={styles.creator_name}>{summary.name}</div>
          <div style={styles.header_right_section}>
            <Hover
              style={styles.header_view_creator}
              action={() => this.go_to_creator_page()}
            >
              view creator page
            </Hover>
          </div>
        </div>
        <div>
          {summary.paths.map(path => {
            return <Path key={path.path_id} path_id={path.path_id} />;
          })}
        </div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const summary = state.db[ownProps.summary_id];

  return {
    summary,
    inactive_ids: state.inactive_ids,
  };
}
export default connect(state_to_props, {
  set_journal_active,
  set_creator_id,
  change_screens,
})(Creator);
