// wellsayer API abstraction

import { Auth } from "aws-amplify";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const API_URL = "https://ecow8ciebe.execute-api.us-west-2.amazonaws.com/production";

class API {
  static async get(params) {
    let token;
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      return {
        success: false,
        retry: true,
        err: JSON.stringify(err),
      };
    }

    try {
      const res = await axios({
        method: "get",
        url: API_URL,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        timeout: 15000,
      });

      return {
        success: true,
        data: res.data,
      };
    } catch (err) {
      return this.parse_axios_error(err);
    }
  }

  static async post(data) {
    let token;
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      return {
        success: false,
        retry: true,
        err: JSON.stringify(err),
      };
    }

    try {
      const res = await axios({
        method: "post",
        url: API_URL,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(data),
        timeout: 3000,
      });

      return {
        success: true,
        data: res.data,
      };
    } catch (err) {
      return this.parse_axios_error(err);
    }
  }

  // helper function to parse errors + return helpful guidance
  static parse_axios_error(err) {
    if (err.response) {
      if (err.response.status === 400) {
        return {
          success: false,
          retry: false,
          err: JSON.stringify(err.response.data),
        };
      } else {
        return {
          success: false,
          retry: true,
          err: JSON.stringify(err.response.data),
        };
      }
    } else {
      return {
        success: false,
        retry: true,
        err: `${err}`,
      };
    }
  }

  // gets all recent changes since a cutoff (max 5MB data)
  static async get_latest(cutoff, page_token = null) {
    const params = {
      action: "get_latest",
      cutoff,
      req_id: uuidv4(), // to stop any axios caching
    };

    if (page_token) {
      params.page_token = page_token;
    }

    return await this.get(params);
  }

  // saves item to API
  static async save_item(item, key_name) {
    const data = {
      action: "sync",
      key_name, // what key is the unique id. eg., entry_id
      item,
    };

    return await this.post(data);
  }

  // notifies API to remove item
  static async remove_item(id) {
    const data = {
      action: "remove",
      id,
    };

    return await this.post(data);
  }

  // API call to get latest account info
  static async sync_account() {
    const params = {
      action: "sync_account",
    };

    return this.get(params);
  }

  // Get a creator page
  static async get_creator_page(id) {
    const params = {
      action: "get_creator_page",
      id,
    };

    return this.get(params);
  }

  static async change_account(change, data) {
    const post_data = {
      action: "account",
      change,
      ...data,
    };

    return this.post(post_data);
  }

  // Get a pathdef
  static async get_pathdef(creator_id, path_id) {
    const params = {
      action: "get_pathdef",
      creator_id,
      path_id,
    };

    return this.get(params);
  }

  static async share_entry(entry_id, questions, journal_name, when, accent_color) {
    const post_data = {
      action: "share_entry",
      access: "public",
      entry_id,
      questions,
      journal_name,
      when, // done here for time localization
      accent_color,
    };

    return this.post(post_data);
  }

  static async revoke_entry_sharing(entry_id) {
    const post_data = {
      action: "share_entry",
      access: "revoke",
      entry_id,
    };

    return this.post(post_data);
  }

  static async search_creators(search_type) {
    const params = {
      action: "search_creators",
      search_type,
    };

    return this.get(params);
  }

  // name is a temp param until something proper can be setup
  static async publish_path(path, name = null) {
    const post_data = {
      action: "path_manager",
      operation: "publish",
      path,
    };

    if (name) {
      post_data.name = name;
    }

    return this.post(post_data);
  }
}

export default API;
