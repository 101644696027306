import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { ss, cstyles } from "../common";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    transition: "background-color 250ms, border 250ms, margin 250ms, color 250ms",
    userSelect: "none",
    marginRight: -1,
    height: "min-content",
    color: cstyles.text("rest"),
    cursor: "pointer",
  },
  inner: {
    padding: 12,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 14,
  },
  start: {
    borderRadius: "8px 0px 0px 8px",
  },
  end: {
    borderRadius: "0px 8px 8px 0px",
  },
};

class ScaleOption extends Component {
  static propTypes = {
    on_hover: PropTypes.func.isRequired,
    update_entry: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    question_id: PropTypes.string.isRequired,
    entry_id: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_select() {
    this.props.update_entry(this.props.entry_id, this.props.question_id, "scale", {
      selected: !this.props.selected,
      id: this.props.number,
    });
  }

  render() {
    // Change border styling based on type (start/end/top/bottom)
    const { type } = this.props;

    // Style depending on which side we're on
    let container_style = styles.container;
    container_style = ss(type === "start", container_style, styles.start);
    container_style = ss(type === "end", container_style, styles.end);

    // Change background and spacing due to hover
    container_style = ss(this.state.hover, container_style, {
      ...{
        marginLeft: 4,
        marginRight: 4,
        marginTop: -4,
      },
      ...cstyles.chip_hover,
    });

    // Add styling if selected
    container_style = ss(this.props.selected, container_style, cstyles.chip_selected);

    let { number } = this.props;
    return (
      <div
        style={container_style}
        onMouseEnter={() => {
          this.setState({ hover: true });
          this.props.on_hover(true, number);
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
          this.props.on_hover(false, number);
        }}
        onClick={() => this.on_select()}
      >
        <div style={styles.inner}>{number}</div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  let entry_id = null;
  let selected = false;
  try {
    let entry = state.db[state.entry.entry_id];
    entry_id = entry.entry_id;

    let answer = entry.answers[state.editor.question_id];

    if (answer.includes(ownProps.number)) {
      selected = true;
    }
  } catch (err) {}

  const { question_id } = state.editor;

  return {
    question_id,
    entry_id,
    selected,
  };
}
export default connect(state_to_props, { update_entry })(ScaleOption);
