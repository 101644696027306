// journal context rendering engine

import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles, url_regex } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const styles = {
  page: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    overflowY: "auto",
    maxWidth: 680,
  },
  title: {
    fontSize: 40,
    fontWeight: 300,
    transition: "color 250ms",
  },
  subtitle: {
    fontSize: 14,
    color: cstyles.text("faded"),
    display: "flex",
    flexDirection: "row",
  },
  underline: {
    textDecoration: "underline",
  },
  get_started: {
    fontSize: 30,
    fontWeight: 300,
    transition: "color 250ms",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    paddingBottom: 20,
    border: cstyles.border("subtle"),
    marginTop: 30,
    marginLeft: 20,
  },
  body_paragraph: {
    color: cstyles.text("rest"),
  },
  video: {
    width: 680,
    height: 383,
    marginTop: 20,
    marginBottom: 20,
  },
  video_title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: -15,
    maxWidth: 680,
  },
  divider: {
    width: 680,
    maxWidth: 680,
    marginTop: 70,
    marginBottom: 20,
  },
  header: {
    fontSize: 28,
    fontWeight: 600,
    marginBottom: 20,
  },
  image: {
    maxWidth: 680,
    maxHeight: 500,
    marginTop: 20,
    marginBottom: 20,
  },
};

class JournalContext extends Component {
  static propTypes = {
    journal: PropTypes.object.isRequired,
    accent_color: PropTypes.string.isRequired,
    on_finish: PropTypes.func.isRequired,
    path_name: PropTypes.string.isRequired,
    creator_name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_body_paragraph(section) {
    // Do url/link replacing
    const text = section.text
      .replace(url_regex, url => {
        return `<$$$SPLITHERE$$$><$$$URL$$$>${url}<$$$SPLITHERE$$$>`;
      })
      .split("<$$$SPLITHERE$$$>")
      .map(sec => {
        if (sec.includes("<$$$URL$$$>")) {
          sec = sec.replace("<$$$URL$$$>", "");
          return React.createElement("a", { href: sec }, sec);
        }
        return sec;
      });

    return (
      <p style={styles.body_paragraph} key={section.text}>
        {text}
      </p>
    );
  }

  render_body_header(section) {
    return (
      <p style={styles.header} key={section.header}>
        {section.header}
      </p>
    );
  }

  render_body_image(section) {
    let style = styles.image;
    if (section.width && section.width < 680) {
      style = { ...style, width: section.width };
    }

    return (
      <div>
        <img src={section.source} alt={"context"} style={style} />
      </div>
    );
  }

  render_video_title(title) {
    if (!title) {
      return null;
    }

    return <div style={styles.video_title}>{title}</div>;
  }

  render_body_video(section) {
    if (section.type === "video-direct") {
      return (
        <div>
          {this.render_video_title(section.title)}
          <video style={styles.video} controls>
            <source src={section.source} />
          </video>
        </div>
      );
    } else if (section.type === "video-frame") {
      // note -- these need to be specific embed links. the journal designer should handle this
      return (
        <div>
          {this.render_video_title(section.title)}
          <iframe
            style={styles.video}
            src={section.source}
            frameBorder="0"
            allowFullScreen
            title={"iframe context video"}
          />
        </div>
      );
    }
  }

  render_body_divider(section) {
    return (
      <div>
        <hr style={styles.divider} />
        {section.title ? <div style={styles.header}>{section.title}</div> : null}
      </div>
    );
  }

  render_body() {
    const { context } = this.props.journal;

    return context.body.map((section, idx) => {
      if (section.type === "paragraph") {
        return (
          <div key={`context-section:${idx}`}>
            {this.render_body_paragraph(section)}
          </div>
        );
      } else if (section.type.includes("image")) {
        return (
          <div key={`context-section:${idx}`}>{this.render_body_image(section)}</div>
        );
      } else if (section.type.includes("video")) {
        return (
          <div key={`context-section:${idx}`}>{this.render_body_video(section)}</div>
        );
      } else if (section.type === "divider") {
        return (
          <div key={`context-section:${idx}`}>{this.render_body_divider(section)}</div>
        );
      } else if (section.type === "header") {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_header(section)}
          </div>
        );
      } else {
        throw new Error(
          `JournalContext encountered an unknown section type: ${section.type}`
        );
      }
    });
  }

  render_get_started() {
    return (
      <Hover
        action={() => {
          this.props.on_finish();
        }}
        style={{ ...styles.get_started, color: this.props.accent_color }}
      >
        Get Started
      </Hover>
    );
  }

  render() {
    const { journal, accent_color, path_name, creator_name } = this.props;

    return (
      <div style={styles.page}>
        <div style={{ ...styles.title, color: accent_color }}>{journal.name}</div>
        <div style={styles.subtitle}>
          A journal on the&nbsp;
          <div style={styles.underline}>{path_name}</div>
          &nbsp;path, published by&nbsp;
          <div style={styles.underline}>{creator_name}</div>
        </div>
        {this.render_body()}
        {this.render_get_started()}
        <div style={{ minHeight: 150 }}>&nbsp;</div>
      </div>
    );
  }
}

function state_to_props(state) {
  const journal = state.db[state.entry.journal_id];
  const path = state.db[journal.from_path_id];
  const creator_summary = state.db[`${path.creator_id}:summary`];

  return {
    journal,
    accent_color: state.settings.accent_color,
    path_name: path.name,
    creator_name: creator_summary.name,
  };
}
export default connect(state_to_props, {})(JournalContext);
