import React, { Component } from "react";
import PropTypes from "prop-types";
import { ss } from "../common";

const styles = {
  default_container_additions: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    userSelect: "none",
    cursor: "pointer",
    position: "relative",
  },
  disabled: {
    opacity: 0.4,
    cursor: "auto",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0)",
    opacity: 0,
    transition: "opacity 250ms, background-color 250ms",
  },
  overlay_one_step: {
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
};

export default class Hover extends Component {
  static propTypes = {
    style: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    children: PropTypes.any,
    disable_overlay: PropTypes.bool, // if true, disables hover overlay
    disabled: PropTypes.bool, // component is disabled - disable hover and fade
    stop_prop: PropTypes.bool, // whether to stop click event propagation
    hover_style: PropTypes.object, // optional style to add on hover
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  on_click(e) {
    if (this.props.stop_prop) {
      e.stopPropagation();
    }

    if (this.props.disabled) {
      return;
    }

    this.props.action(e);
  }

  render() {
    const base_style = ss(true, styles.default_container_additions, this.props.style);

    // container style
    let style = ss(this.props.disabled, base_style, styles.disabled);

    // optional supplied hover styling
    style = ss(
      this.props.hover_style && this.state.hover && !this.props.disabled,
      style,
      this.props.hover_style
    );

    // overlay style
    let overlay_style = ss(
      !this.props.disabled && !this.props.disable_overlay && this.state.hover,
      styles.overlay,
      styles.overlay_one_step
    );

    // copy parent border radius for overlay
    if (style.borderRadius) {
      overlay_style = ss(true, overlay_style, { borderRadius: style.borderRadius });
    }

    return (
      <div
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={e => this.on_click(e)}
        style={style}
      >
        {this.props.children}
        {this.props.disable_overlay ? null : <div style={overlay_style} />}
      </div>
    );
  }
}
