import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_specific, change_screens } from "../redux/actions";

import { is_today, is_yesterday } from "../common";
import { get_ref_by_entryday } from "../getters";

import Hover from "../wrappers/Hover";

import {
  render_scale,
  render_schedule,
  render_chips,
  render_yesno,
  render_text_entry,
  render_clickable,
  render_check,
  render_simple_answer,
} from "./renders";

const styles = {
  container: {
    borderRadius: 4,
    padding: 4,
    maxWidth: 650,
    marginLeft: 4,
    marginBottom: 15,
  },
  from_where_text: {
    fontSize: 13,
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
    color: "#A5D8F3",
    opacity: 0.75,
  },
  from_where_bold: {
    marginLeft: 8,
    marginRight: 8,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  answer_container: {
    minHeight: 60,
  },
};

class Portal extends Component {
  static propTypes = {
    portal: PropTypes.object.isRequired,
    ref_question: PropTypes.object.isRequired,
    ref_journal: PropTypes.object.isRequired,
    ref_answer: PropTypes.any,
    set_entry_specific: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    ref_entry: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  get_short_date_label(ref_entry) {
    if (!ref_entry) {
      return "the";
    }

    const date = ref_entry.timestamp;

    if (is_today(date)) {
      return "today's";
    } else if (is_yesterday(date)) {
      return "yesterday's";
    } else {
      return new Date(date).toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
      });
    }
  }

  render_answer() {
    let { ref_answer, ref_entry, portal } = this.props;

    if (!ref_answer) {
      return (
        <div style={{ marginBottom: 20, userSelect: "none" }}>No entry for today</div>
      );
    }

    const question_type = this.props.ref_question.type;
    const { style } = this.props.ref_question;

    if (portal.editable === true) {
      return render_clickable(
        ref_answer,
        this.props.ref_question.question_id,
        ref_entry ? ref_entry.entry_id : null
      );
    } else if (question_type === "text_entry") {
      return render_text_entry(ref_answer);
    } else if (
      ["multiple_select", "affects", "subset", "shuffle", "list"].includes(
        question_type
      )
    ) {
      return render_chips(ref_answer);
    } else if (question_type === "scale") {
      return render_scale(ref_answer);
    } else if (question_type === "table_schedule_list" && style === "free_time") {
      return render_schedule(ref_answer);
    } else if (question_type === "yes_no") {
      return render_yesno(ref_answer);
    } else if (question_type === "checkbox") {
      return render_check(ref_answer);
    } else if (question_type === "single_select") {
      return render_simple_answer(ref_answer);
    } else {
      throw new Error("question type not handled in entry:portal");
    }
  }

  render_from_where() {
    const { ref_question, ref_journal, ref_entry } = this.props;
    return (
      <Hover
        style={styles.from_where_text}
        disable_overlay={true}
        action={() => {
          this.props.set_entry_specific(
            ref_entry ? ref_entry.entry_id : null,
            ref_journal.journal_id
          );
          this.props.change_screens("entry");
        }}
      >
        viewing
        <div style={styles.from_where_bold}>{ref_question.question}</div>
        from {this.get_short_date_label(ref_entry)}{" "}
        <div style={styles.from_where_bold}>{ref_journal.name}</div>
        daily journal
      </Hover>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        {this.render_from_where()}
        {this.render_answer()}
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const portal = state.db[ownProps.question_id];
  const ref_question = state.db[portal.ref_question_id];
  const ref_journal = state.db[state.db[ref_question.from_prompt_id].from_journal_id];

  const { ref_answer, ref_entry } = get_ref_by_entryday(
    state,
    portal.ref_question_id,
    state.entry.entry_id,
    ref_question.type === "table_schedule_list" ? true : false
  );

  return {
    portal,
    ref_question,
    ref_journal,
    ref_entry,
    ref_answer,
  };
}
export default connect(state_to_props, { set_entry_specific, change_screens })(Portal);
