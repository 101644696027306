/* handlers for transforming data to/from the API

Many items retain their data with lists of IDs. 
If we want the API to give us those referenced objects, we need to add them to a "refs" key on the object sent to the API
*/

// ------------------------------------------------------------------------------------
// ---------------------------------  resolvers  --------------------------------------
// ------------------------------------------------------------------------------------
const { is_uuid } = require("../common");

// logic to determine whether input is an array of uuids
function is_id_list(input) {
  if (!input || !Array.isArray(input) || !input[0] || !is_uuid(input[0])) {
    return false;
  }
  return true;
}

// gets the unique id key name for an object
function get_key_name(obj_type) {
  switch (obj_type) {
    case "entry":
      return "entry_id";
    case "qops":
      return "qops_id";
    case "affect_cat":
      return "category_id";
    case "feeling":
      return "feeling_id";
    case "multi_option":
      return "option_id";
    case "list_item":
      return "item_id";
    case "schedule_row":
      return "item_id";
    default:
      throw new Error(`get_key_name encountered an unknown obj_type: '${obj_type}'`);
  }
}

// preps items for syncing
function resolve_item(state, id) {
  // if it's a named key
  if (id.slice(0, 2) === "@@") {
    return resolve_named_key(state, id);
  }

  const item = state.db[id];

  if (item.obj_type === "entry") {
    return {
      item: resolve_entry(state.db, item),
      key_name: get_key_name(item.obj_type),
    };
  } else {
    return {
      item,
      key_name: get_key_name(item.obj_type),
    };
  }
}

// For entries, we need to:
//  - create a refs key for answers that are lists of ids
function resolve_entry(db, _entry) {
  // create a copy of the entry
  let entry = JSON.parse(JSON.stringify(_entry));

  let refs = [];

  // Resolve answers
  for (const [answer_id, answer] of Object.entries(entry.answers)) {
    // if the answer is a ref list of ids, add it
    if (is_id_list(answer) && db[answer_id].type !== "scale") {
      refs = refs.concat(answer);
    }
  }

  if (refs.length > 0) {
    // de-duplicate refs
    refs = [...new Set(refs)];

    entry.refs = refs;
  }

  return entry;
}

function resolve_named_key(state, id) {
  let item;

  if (id === "@@inactive_ids") {
    item = {
      data: state.inactive_ids,
      obj_type: "named_key",
      key_name: id,
    };
  } else if (id === "@@stats") {
    item = {
      data: state.stats,
      obj_type: "named_key",
      key_name: id,
    };
  } else if (id === "@@journeys") {
    item = {
      data: state.journeys,
      obj_type: "named_key",
      key_name: id,
    };
  } else if (id === "@@draft_path_order") {
    item = {
      data: state.my_content.draft_path_order,
      obj_type: "named_key",
      key_name: id,
    };
  } else if (id.startsWith("@@draft:")) {
    const path_id = id.slice(8);

    item = {
      data: state.my_content.draft_paths[path_id],
      obj_type: "named_key",
      key_name: id,
    };
  } else {
    throw new Error(`syncing an unknown named key '${id}'`);
  }

  return {
    item,
    key_name: "key_name",
  };
}

export { resolve_item };
