import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles, ss } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const link_icon = require("../icons/link.png");

const styles = {
  tag: {
    border: cstyles.border("subtle"),
    borderRadius: 15,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 3,
    paddingBottom: 3,
    margin: 2,
    marginRight: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  link_icon: {
    marginLeft: 6,
    width: 18,
    height: 18,
  },
};

class Tag extends Component {
  static propTypes = {
    tag: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
    this.tag_ref = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      try {
        this.setState({
          height: this.tag_ref.current.offsetHeight,
        });
      } catch {}
    }, 0);
  }

  render() {
    // add more internal padding for big tags
    let tag_style = ss(this.state.height > 35, styles.tag, {
      paddingTop: 6,
      paddingBottom: 6,
    });

    if (this.props.tag.link) {
      return (
        <Hover
          style={tag_style}
          action={() => {
            window.open(this.props.tag.link, "_blank");
          }}
        >
          <div ref={this.tag_ref}>{this.props.tag.text}</div>

          <img src={link_icon} alt={"this is a link"} style={styles.link_icon} />
        </Hover>
      );
    } else {
      return (
        <div style={tag_style} ref={this.tag_ref}>
          {this.props.tag.text}
        </div>
      );
    }
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(Tag);
