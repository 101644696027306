import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  set_data_editor_first,
  show_data_editor,
  toggle_entry_share_modal,
  toggle_side_context,
} from "../redux/actions";

import { cstyles, is_today, is_yesterday } from "../common";
import { first_question_has_context } from "../getters";

import Question from "./Question";
import Portal from "./Portal";
import Hover from "../wrappers/Hover";
import JournalContext from "./JournalContext";

const styles = {
  page: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  questions: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    overflow: "auto",
    flex: 1,
    height: "100%",
    scrollBehavior: "smooth",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: 20,
    marginRight: 20,
    marginLeft: 20,
    userSelect: "none",
  },
  sidebar_section: {
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  sidebar_title: {
    fontSize: 14,
    opacity: 0.8,
  },
  sidebar_info: {
    fontSize: 20,
    fontWeight: 600,
  },
  prompt_header: {
    marginLeft: 4,
    fontSize: 14,
    color: cstyles.text("rest"),
    userSelect: "none",
  },
  prompt_section: {
    marginBottom: 20,
  },
  entry_time_section: {
    height: 50,
  },
  spacer: {
    minHeight: 500,
  },
  sidebar_button: {
    padding: 4,
    paddingRight: 12,
    paddingLeft: 12,
    marginRight: -8,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
};

class EntryScreen extends Component {
  static propTypes = {
    entry: PropTypes.object,
    journal: PropTypes.object.isRequired,
    path: PropTypes.object.isRequired,
    set_data_editor_first: PropTypes.func.isRequired,
    show_data_editor: PropTypes.func.isRequired,
    toggle_entry_share_modal: PropTypes.func.isRequired,
    toggle_side_context: PropTypes.func.isRequired,
    editor_question_id: PropTypes.string,
    prev_question_available: PropTypes.bool.isRequired,
    settings: PropTypes.object.isRequired,
    show_share_modal: PropTypes.bool.isRequired,
    has_entries: PropTypes.bool.isRequired,
    cur_question_has_context: PropTypes.bool.isRequired,
    show_side_context_on_setup: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      context_finished: false,
    };

    // Don't run setup if we show intro context
    if (!this.should_show_context()) {
      this.setup();
    }

    this.qRef = React.createRef();
    this.scrollRefs = {};
  }

  componentDidUpdate(prevProps) {
    // We switched directly from one entry to another, so we never unmounted
    const props = this.props;

    // we changed journals
    if (prevProps.journal.journal_id !== props.journal.journal_id) {
      if (!this.should_show_context()) {
        this.setup();
      }
    }
    // we changed entries
    else {
      const previously_had_an_entry = prevProps.entry ? true : false;
      const currently_have_an_entry = props.entry ? true : false;
      if (previously_had_an_entry && currently_have_an_entry) {
        if (prevProps.entry.entry_id !== props.entry.entry_id) {
          if (!this.should_show_context()) {
            this.setup();
          }
        }
      }
    }

    if (
      this.props.settings.entry_auto_scrolling &&
      prevProps.editor_question_id !== this.props.editor_question_id
    ) {
      this.scroll_to(this.props.editor_question_id);
    }
  }

  // logic for whether we should show the journal context
  should_show_context() {
    const { has_entries, journal } = this.props;
    if (
      !has_entries &&
      journal.context &&
      journal.context.body &&
      journal.context.body.length > 0
    ) {
      return true;
    }

    return false;
  }

  setup() {
    // Default open the editor to the first question
    const { journal } = this.props;
    this.props.set_data_editor_first(journal.journal_id);
    this.props.show_data_editor();
    window.scrollTo(0, 0);

    // scroll to top once page is loaded
    setTimeout(() => {
      try {
        this.qRef.current.scrollTop = 0;
      } catch (err) {
        // it's okay if we don't scroll
      }
    }, 0);

    // automatically open sidebar context
    if (this.props.show_side_context_on_setup) {
      this.props.toggle_side_context(true);
    }
  }

  // Scrolls to a question by id
  scroll_to(id) {
    try {
      const question_scroll_pos = !this.props.prev_question_available
        ? 0
        : this.scrollRefs[id].current.offsetTop - 40;

      this.qRef.current.scrollTop = question_scroll_pos;
    } catch (err) {
      // it's okay - not scrolling isn't a big deal
    }
  }

  // on clicking "get started" in JournalContext for a journal
  on_context_finished() {
    this.setState(
      {
        context_finished: true,
      },
      () => {
        setTimeout(() => {
          this.setup();
        }, 0);
      }
    );
  }

  render_questions() {
    const { prompts } = this.props.journal;

    return prompts.map(prompt => {
      return (
        <div style={styles.prompt_section} key={prompt.prompt_id}>
          <div style={styles.prompt_header}>{prompt.name}</div>
          {prompt.questions.map(question => {
            this.scrollRefs[question.question_id] = React.createRef();
            if (question.type === "portal") {
              return (
                <div
                  ref={this.scrollRefs[question.question_id]}
                  key={`entry:scroll:${question.question_id}`}
                >
                  <Portal question_id={question.question_id} />
                </div>
              );
            }
            return (
              <div
                ref={this.scrollRefs[question.question_id]}
                key={`entry:scroll:${question.question_id}`}
              >
                <Question question_id={question.question_id} />
              </div>
            );
          })}
        </div>
      );
    });
  }

  render_entry_time() {
    const { entry } = this.props;
    if (!entry) {
      return <div>{` `}</div>;
    }

    const time = new Date(entry.timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    let label;
    if (is_today(entry.timestamp)) {
      label = " today";
    } else if (is_yesterday(entry.timestamp)) {
      label = " yesterday";
    } else {
      label =
        ", " +
        new Date(entry.timestamp).toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
        });
    }

    return (
      <div>
        entry at {time}
        {label}
      </div>
    );
  }

  render() {
    const { path, journal } = this.props;

    // show journal context if applicable
    if (this.should_show_context()) {
      if (!this.state.context_finished) {
        return (
          <JournalContext
            on_finish={() => {
              this.on_context_finished();
            }}
          />
        );
      }
    }

    return (
      <div style={styles.page} ref={this.pageRef}>
        <div style={styles.questions} ref={this.qRef}>
          {this.render_questions()}
          <div style={styles.spacer} />
        </div>
        <div>
          <div style={styles.sidebar}>
            <div style={styles.entry_time_section}>{this.render_entry_time()}</div>
            <div style={styles.sidebar_section}>
              <div style={styles.sidebar_title}>path</div>
              <div style={styles.sidebar_info}>{path.name}</div>
            </div>
            <div style={styles.sidebar_section}>
              <div style={styles.sidebar_title}>journal</div>
              <div style={styles.sidebar_info}>{journal.name}</div>
            </div>
            <Hover
              style={styles.sidebar_button}
              action={() => {
                this.props.toggle_entry_share_modal();
              }}
              disabled={!this.props.entry}
            >
              <div style={styles.sidebar_info}>Share</div>
            </Hover>
            <Hover
              style={styles.sidebar_button}
              action={() => {
                this.props.toggle_side_context();
              }}
              disabled={!this.props.cur_question_has_context}
            >
              <div style={styles.sidebar_info}>Context</div>
            </Hover>
          </div>
        </div>
        {/* {this.props.show_share_modal ? <ShareModal /> : null} */}
      </div>
    );
  }
}

function state_to_props(state) {
  const entry = state.db[state.entry.entry_id];
  const journal = state.db[state.entry.journal_id];
  const path = state.db[journal.from_path_id];

  const prev_question_available = state.editor.previous_question ? true : false;

  const has_entries = state.stats.journals[journal.journal_id]
    ? state.stats.journals[journal.journal_id].entries > 0
      ? true
      : false
    : false;

  const cur_question_has_context = state.db[state.editor.question_id]
    ? !!state.db[state.editor.question_id].context
    : false;

  // Determine whether to show the sidebar question context on setup
  let show_side_context_on_setup = false;
  if (first_question_has_context(state, journal.journal_id)) {
    if (state.settings.auto_show_side_context === "always") {
      show_side_context_on_setup = true;
    } else if (state.settings.auto_show_side_context === "few") {
      if (has_entries && state.stats.journals[journal.journal_id].entries <= 3) {
        show_side_context_on_setup = true;
      }
    } else {
      if (!has_entries) {
        show_side_context_on_setup = true;
      }
    }
  }

  return {
    journal,
    entry,
    path,
    editor_question_id: state.editor.question_id,
    prev_question_available,
    settings: state.settings,
    show_share_modal: state.entry.show_share_modal,
    has_entries,
    cur_question_has_context,
    show_side_context_on_setup,
  };
}
export default connect(state_to_props, {
  set_data_editor_first,
  show_data_editor,
  toggle_entry_share_modal,
  toggle_side_context,
})(EntryScreen);
