import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_screens, set_journals_screen_type } from "../redux/actions";

import { cstyles, ss } from "../common";
import Hover from "../wrappers/Hover";

import HomeButtons from "../buttons/HomeButtons.js";
import QuickSelect from "./QuickSelect";
import Journey from "./Journey";

const styles = {
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    userSelect: "none",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginRight: 20,
    height: "100%",
    overflowX: "auto",
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    margin: 10,
    marginLeft: -40,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 2,
  },
  content: {
    flex: 5,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  content_inner: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 1150,
  },
  journals_column: {
    width: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: 15,
  },
  content_right: {
    flex: 1,
  },
  content_right_buttons: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
  },
  option_container: {
    height: 70,
    overflow: "hidden",
    transition: "height 500ms",
    transitionDelay: "100ms",
    width: "100%",
  },
  responsive_option_container: {
    height: 70,
    overflow: "hidden",
    transition: "height 500ms",
    transitionDelay: "100ms",
    width: "100%",
  },
  option_hover: {
    padding: 10,
  },
  option_header: {
    fontSize: 22,
    fontWeight: 600,
  },
  option_text: {
    fontWeight: 600,
    fontSize: 14,
    color: cstyles.text("rest"),
    opacity: 0.9,
  },
  divider_wrapper: {
    height: 22,
    overflow: "hidden",
    transition: "height 1000ms",
    width: "80%",
  },
  divider_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  divider: {
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
    height: 1,
    width: 100,
    opacity: 0.5,
  },
  shrinking: {
    height: 0,
  },
  journey_column: {
    width: 400,
    height: "100%",
    overflowY: "auto",
    maxHeight: 425,
  },
  journey_content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  column_header: {
    fontSize: 20,
    fontWeight: 300,
    paddingBottom: 10,
    paddingTop: 5,
  },
};

class Home extends Component {
  static propTypes = {
    change_screens: PropTypes.func.isRequired,
    set_journals_screen_type: PropTypes.func.isRequired,
    journeys: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  on_option_click(type) {
    const to_val = this.state.selected === type ? "" : type;
    this.setState({
      selected: to_val,
    });
    // this.props.change_screens("journals");
    // this.props.set_journals_screen_type(type);
  }

  render_quick_select() {
    let { selected } = this.state;
    if (selected === "") {
      return null;
    }

    return <QuickSelect journal_type={selected} />;
  }

  render_main() {
    // when you need a more advanced shrink animation, see: https://css-tricks.com/using-css-transitions-auto-dimensions/

    const { selected } = this.state;
    const daily_container = ss(
      selected === "responsive",
      styles.option_container,
      styles.shrinking
    );
    const responsive_container = ss(
      selected === "dailys",
      styles.responsive_option_container,
      styles.shrinking
    );
    const divider_wrapper = ss(
      selected !== "",
      styles.divider_wrapper,
      styles.shrinking
    );

    return (
      <div style={styles.journals_column}>
        <div style={{ ...styles.column_header, marginLeft: 8 }}>
          Individual Journals
        </div>
        <div style={daily_container}>
          <Hover
            style={styles.option_hover}
            action={() => this.on_option_click("dailys")}
          >
            <div style={styles.option_header}>Dailys</div>
            <div style={styles.option_text}>
              Continue exploring a question in my life
            </div>
          </Hover>
        </div>
        <div style={divider_wrapper}>
          <div style={styles.divider_container}>
            <div style={styles.divider} />
          </div>
        </div>
        <div style={responsive_container}>
          <Hover
            style={styles.option_hover}
            action={() => this.on_option_click("responsive")}
          >
            <div style={styles.option_header}>Responsive</div>
            <div style={styles.option_text}>
              Something happened, and I'd like to journal about it
            </div>
          </Hover>
        </div>
        {this.render_quick_select()}
      </div>
    );
  }

  render_journeys() {
    const { journeys } = this.props;

    if (journeys.length === 0) {
      return null;
    }

    return (
      <div style={styles.journey_column}>
        <div style={styles.column_header}>Continue a Journey</div>
        <div style={styles.journey_content}>
          {journeys.map(path_id => {
            return <Journey key={`j:${path_id}`} path_id={path_id} />;
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={styles.page}>
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={{ flex: 1 }}>&nbsp;</div>
            <div style={{ flex: 1 }}>
              <div style={styles.title}>What would you like to do?</div>
            </div>
          </div>
          <div style={styles.content}>
            <div style={styles.content_inner}>
              {this.render_main()}
              {this.render_journeys()}
              <HomeButtons />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const journeys = Object.keys(state.journeys).reduce((acc, path_id) => {
    // ignore inactive paths
    if (state.inactive_ids[path_id]) {
      return acc;
    }

    // ignore paths that aren't loaded yet
    if (!state.db[path_id]) {
      return acc;
    }

    acc.push(path_id);
    return acc;
  }, []);

  return {
    journeys,
  };
}
export default connect(state_to_props, { change_screens, set_journals_screen_type })(
  Home
);
