import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry, update_question_ops } from "../redux/actions";

import { ss, cstyles } from "../common";

const remove_icon = require("../icons/delete.png");

const styles = {
  render_chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 13,
    transition: "background-color 250ms, color 250ms, border 250ms",
    userSelect: "none",
    cursor: "pointer",
    color: cstyles.text("rest"),
  },
  inner: {
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 8,
  },
  remove_div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  remove_icon: {
    width: 0,
    height: 14,
    marginLeft: 0,
    marginRight: 0,
    transition: "width 250ms, margin-left 250ms, margin-right 250ms",
  },
};

class OptionChip extends Component {
  static propTypes = {
    option: PropTypes.object.isRequired,
    entry_id: PropTypes.any,
    question_id: PropTypes.string.isRequired,
    editor_mode: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    update_entry: PropTypes.func.isRequired,
    update_question_ops: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  on_select() {
    this.props.update_entry(
      this.props.entry_id,
      this.props.question_id,
      "multiple_select",
      {
        selected: !this.props.selected,
        id: this.props.option.option_id,
      }
    );
  }

  on_remove() {
    const { update_question_ops, entry_id, question_id, option } = this.props;

    if (this.state.remove_hover) {
      // Remove option from question
      update_question_ops(question_id, option, "remove");

      // If it was selected for the entry, remove it
      if (this.props.selected) {
        this.props.update_entry(entry_id, question_id, "multiple_select", {
          selected: false,
          option_id: this.props.option.option_id,
        });
      }
    }
  }

  render_x() {
    const icon_style = ss(this.props.editor_mode === "edit", styles.remove_icon, {
      width: 14,
      marginLeft: 10,
      marginRight: 4,
    });

    return (
      <div
        style={styles.remove_div}
        onMouseEnter={() => {
          this.setState({ remove_hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ remove_hover: false });
        }}
        onClick={() => this.on_remove()}
      >
        <img style={icon_style} src={remove_icon} alt={"remove"} />
      </div>
    );
  }

  render() {
    const { hover, remove_hover } = this.state;

    // Apply styling on hover
    let chip_style = ss(hover, styles.render_chip, cstyles.chip_hover);

    // Apply styling if selected
    chip_style = ss(this.props.selected, chip_style, cstyles.chip_selected);

    // Apply a different styling if hovering over the X remove icon
    chip_style = ss(remove_hover, chip_style, {
      backgroundColor: cstyles.remove,
      border: "1px solid #da8b99",
      color: "#f9ecee",
    });

    return (
      <div style={chip_style}>
        <div
          style={styles.inner}
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          onClick={() => this.on_select()}
        >
          {this.props.option.name}
        </div>

        {this.render_x()}
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const option = state.db[ownProps.option_id];

  let entry_id = null;
  let selected = false;
  try {
    let entry = state.db[state.entry.entry_id];
    entry_id = entry.entry_id;

    let answer = entry.answers[state.editor.question_id];

    if (answer.includes(option.option_id)) {
      selected = true;
    }
  } catch (err) {}

  const { question_id, mode } = state.editor;

  return {
    option,
    entry_id,
    question_id,
    selected,
    editor_mode: mode,
  };
}
export default connect(state_to_props, { update_entry, update_question_ops })(
  OptionChip
);
