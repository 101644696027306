import React, { Component } from "react";
import PropTypes from "prop-types";

import { ss, cstyles } from "../common";

const styles = {
  new_button_div: {
    borderRadius: 8,
    backgroundColor: cstyles.action_confirm,
    fontFamily: "nunito",
    fontWeight: 700,
    userSelect: "none",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 5,
    paddingBottom: 5,
    transition: "filter 250ms",
    cursor: "pointer",
  },
};

export default class NewButton extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  render() {
    const div_style = ss(this.state.hover, styles.new_button_div, cstyles.bright_hover);

    return (
      <div
        style={div_style}
        onClick={this.props.callback}
        onMouseEnter={() => {
          this.setState({
            hover: true,
          });
        }}
        onMouseOut={() => {
          this.setState({
            hover: false,
          });
        }}
      >
        NEW
      </div>
    );
  }
}
