import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_search_mode, search_creators } from "../redux/actions";

import { ss, cstyles } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

import Code from "./Code";
import Creator from "./Creator";

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 10,
    opacity: 1,
    transition: "opacity 350ms",
    overflowY: "auto",
  },
  header: {
    fontWeight: 600,
    fontSize: 28,
    marginBottom: 40,
  },
  subheader: {
    fontWeight: 600,
    fontSize: 20,
  },
  option: {
    width: 300,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    fontSize: 24,
  },
  spinner: {
    border: "4px solid #1E1E1E",
    borderTop: "4px solid #bae7ff",
    borderRadius: "50%",
    width: 12,
    height: 12,
    animation: "spin 1s linear infinite",
    marginRight: 10,
    marginLeft: 50,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  home_list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
};

class SearchScreen extends Component {
  static propTypes = {
    search_mode: PropTypes.string.isRequired,
    set_search_mode: PropTypes.func.isRequired,
    search_creators: PropTypes.func.isRequired,
    loading_search: PropTypes.bool.isRequired,
    home_creators_list: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      page_opacity: 1,
    };
  }

  componentDidMount() {
    // Initiate search to list creators for search page
    this.props.search_creators("list");
  }

  on_select_option(mode) {
    // fade out current screen
    this.setState({
      page_opacity: 0,
    });

    // change screens and fade back in
    setTimeout(() => {
      this.props.set_search_mode(mode);

      // reset opacity
      this.setState({
        page_opacity: 1,
      });
    }, 500);
  }

  render_page() {
    const { search_mode, loading_search, home_creators_list } = this.props;

    if (search_mode === "start") {
      return (
        <div>
          <div style={styles.header}>How would you like to search?</div>
          <Hover
            style={styles.option}
            action={() => {
              this.on_select_option("code");
            }}
          >
            I have a code
          </Hover>
          <hr
            style={{ width: "100%", border: cstyles.border("subtle"), marginTop: 60 }}
          />
          <div
            style={{
              ...styles.row,
              marginBottom: 30,
              marginTop: 20,
            }}
          >
            <div style={{ ...styles.subheader }}>Public creators</div>
            {loading_search ? (
              <div style={styles.row}>
                <div style={styles.spinner} /> refreshing
              </div>
            ) : null}
          </div>

          <div style={styles.home_list}>
            {home_creators_list.map(creator_id => {
              return (
                <Creator key={`search-creator:${creator_id}`} creator_id={creator_id} />
              );
            })}
            <div style={{ minHeight: 150 }} />
          </div>
        </div>
      );
    } else if (search_mode === "code") {
      return <Code />;
    }
  }

  render() {
    const page_style = ss(true, styles.page, { opacity: this.state.page_opacity });

    return <div style={page_style}>{this.render_page()}</div>;
  }
}

function state_to_props(state) {
  const { mode: search_mode, home_creators_list } = state.search;

  return {
    search_mode,
    loading_search: state.loading.search_creators,
    home_creators_list,
  };
}
export default connect(state_to_props, { set_search_mode, search_creators })(
  SearchScreen
);
