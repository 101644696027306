import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";

import HoverIcon from "../wrappers/HoverIcon";

const done_icon = require("../icons/done.png");

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    userSelect: "none",
  },
  input: {
    padding: 10,
    fontSize: 12,
    border: cstyles.border("input"),
    background: cstyles.raised(2),
    borderRadius: 8,
    resize: "none",
    color: cstyles.text("rest"),
  },
};

class Input extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  check_enter(e) {
    if (e.key === "Enter") {
      this.on_create();

      this.setState({
        text: "",
      });
    }
  }

  on_create() {
    this.props.action(this.state.text);
  }

  render() {
    return (
      <div style={styles.container}>
        <input
          style={styles.input}
          placeholder={"new item"}
          onKeyPress={e => this.check_enter(e)}
          value={this.state.text}
          onChange={e => this.setState({ text: e.target.value })}
          maxLength={60}
        />
        <HoverIcon
          alt={"done"}
          icon={done_icon}
          action={() => this.on_create()}
          icon_size={20}
        />
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(Input);
