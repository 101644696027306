import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_specific, change_screens } from "../redux/actions";

import { cstyles } from "../common";
import Hover from "../wrappers/Hover";

const styles = {
  container: {
    padding: 8,
    fontSize: 11,
    border: cstyles.border("subtle"),
    borderRadius: 6,
    marginBottom: 4,
    marginLeft: 10,
    color: cstyles.text("inactive"),
  },
};

class ResponsiveEntryQuickSelect extends Component {
  static propTypes = {
    entry_id: PropTypes.string.isRequired,
    entry: PropTypes.object.isRequired,
    set_entry_specific: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  format_date(timestamp) {
    return new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  on_click() {
    this.props.set_entry_specific(this.props.entry_id, this.props.entry.journal_id);
    this.props.change_screens("entry");
  }

  render() {
    const { entry } = this.props;
    return (
      <Hover style={styles.container} action={() => this.on_click()}>
        <div>edit entry at {this.format_date(entry.timestamp)} today</div>
      </Hover>
    );
  }
}

function state_to_props(state, ownProps) {
  return {
    entry: state.db[ownProps.entry_id],
  };
}
export default connect(state_to_props, { set_entry_specific, change_screens })(
  ResponsiveEntryQuickSelect
);
