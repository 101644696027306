import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import ScaleOption from "./ScaleOption";

import { ss } from "../common";

const styles = {
  options: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    userSelect: "none",
  },
  description_container: {
    height: "calc(1em + 5px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    marginTop: 15,
    marginBottom: 10,
    opacity: 0, // faded in on hover,
    transition: "opacity 250ms",
    color: "white",
  },
  description_text: {
    fontSize: "13",
  },
};

class ScaleSelect extends Component {
  static propTypes = {
    scale: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover_active: false,
      hover_number: null,
      selected: [],
    };
  }

  render_description() {
    // Get description from currently hovered scale #
    let description = "";
    let scale_def = this.props.scale.filter(sdef => {
      return sdef.number === this.state.hover_number;
    });
    if (scale_def.length > 0) {
      description = scale_def[0].description;
    }

    // Set styling based on hover (eg fade in/out)
    const container_style = ss(this.state.hover_active, styles.description_container, {
      opacity: 1,
    });

    return (
      <div style={container_style}>
        <div styles={styles.description_text}>{description}</div>
      </div>
    );
  }

  render_options() {
    // builds scale options
    const { scale } = this.props;
    const scale_length = scale.length;

    // build options
    let options = [];

    scale.forEach((op, idx) => {
      let type = "middle";
      if (idx === 0) {
        type = "start";
      } else if (idx === scale_length - 1) {
        type = "end";
      }

      options.push(
        <ScaleOption
          key={`op:${idx}`}
          number={op.number}
          type={type}
          on_hover={(hover, number) => this.on_hover(hover, number)}
        />
      );
    });

    return <div style={styles.options}>{options}</div>;
  }

  // callback given to scale options
  on_hover(hover, number) {
    this.setState({
      hover_active: hover,
      hover_number: number,
    });
  }

  render() {
    return (
      <div>
        {this.render_description()}
        {this.render_options()}
      </div>
    );
  }
}

function state_to_props(state) {
  // Get current editor info
  const { question_id } = state.editor;

  // Get scale from question definition
  const scale = state.db[question_id].scale;

  return {
    scale,
  };
}
export default connect(state_to_props, {})(ScaleSelect);
