import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  toggle_entry_share_modal,
  share_entry,
  revoke_entry_sharing,
} from "../redux/actions";

import { cstyles, ss } from "../common";
import {} from "../getters";

import Switch from "react-switch";

import Hover from "../wrappers/Hover";
import HoverIcon from "../wrappers/HoverIcon";

const globe_icon = require("../icons/globe.png");
const close_icon = require("../icons/close.png");

const styles = {
  screen_cover: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    backgroundColor: cstyles.raised(1),
    minWidth: 480,
    borderRadius: 8,
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    color: cstyles.text("rest"),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  row_title: {
    fontWeight: 600,
    fontSize: 20,
    color: cstyles.text("rest"),
  },
  row_explainer: {
    color: cstyles.text("inactive"),
  },
  spinner: {
    border: "5px solid #1E1E1E",
    borderTop: "5px solid #bae7ff",
    borderRadius: "50%",
    width: 16,
    height: 16,
    animation: "spin 1s linear infinite",
    marginRight: 10,
  },
  link_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  link_box: {
    backgroundColor: "#25400090",
    borderRadius: 3,
    padding: 5,
    paddingLeft: 10,
    paddingReft: 10,
    flexGrow: 1,
    fontSize: 14,
  },
  copy_link: {
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: cstyles.raised(2),
    borderRadius: 3,
    marginLeft: 10,
    color: cstyles.text("rest"),
    minWidth: 82,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  on_copy_style: {
    backgroundColor: "#143000",
  },
  access_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  access_header: {},
  access_text: {
    fontSize: 14,
    color: cstyles.text("inactive"),
  },
  globe: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
};

class ShareModal extends Component {
  static propTypes = {
    toggle_entry_share_modal: PropTypes.func.isRequired,
    share_entry: PropTypes.func.isRequired,
    revoke_entry_sharing: PropTypes.func.isRequired,
    shared: PropTypes.object,
    entry_id: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      link_copied: false,
    };
  }

  toggle_sharing() {
    // currently not shared -- share entry
    if (!this.props.shared) {
      this.props.share_entry(this.props.entry_id);
    }
    // revoke sharing
    else {
      this.props.revoke_entry_sharing(this.props.entry_id);
      this.setState({
        link_copied: false,
      });
    }
  }

  render_link_row() {
    if (!!this.props.shared) {
      const link = `https://wlsyr.live/${this.props.shared.token_id}`;

      const button_style = ss(
        this.state.link_copied,
        styles.copy_link,
        styles.on_copy_style
      );

      return (
        <div style={styles.link_row}>
          <div style={styles.link_box}>{link}</div>
          <Hover
            style={button_style}
            action={() => {
              navigator.clipboard.writeText(link);
              this.setState({
                link_copied: true,
              });
            }}
          >
            {this.state.link_copied ? "copied!" : "copy link"}
          </Hover>
        </div>
      );
    }
  }

  render_access_row() {
    if (!!this.props.shared) {
      return (
        <div style={styles.access_row}>
          <img src={globe_icon} alt={"globe"} style={styles.globe} />
          <div>
            <div>Viewable with link</div>
            <div style={styles.access_text}>
              Anyone on the internet with this link can view
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const is_shared = !!this.props.shared;
    return (
      <div
        style={styles.screen_cover}
        onClick={() => {
          this.props.toggle_entry_share_modal();
        }}
      >
        <div
          style={styles.modal}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div style={{ ...styles.row, marginBottom: 25 }}>
            <div style={styles.title}>Share journal entry with others</div>
            <HoverIcon
              icon={close_icon}
              icon_size={24}
              alt={"close sharing"}
              action={() => {
                this.props.toggle_entry_share_modal();
              }}
            />
          </div>

          <div style={styles.row}>
            <div>
              <div style={styles.row_title}>Sharing</div>
              <div style={styles.row_explainer}>Enable sharing of this entry</div>
            </div>
            {this.props.loading ? (
              <div style={styles.spinner} />
            ) : (
              <Switch
                onChange={() => {
                  this.toggle_sharing();
                }}
                checked={is_shared}
              />
            )}
          </div>
          {this.render_link_row()}
          {this.render_access_row()}
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  if (!state.entry.entry_id) {
    throw new Error(`ShareModal opened without an entry_id`);
  }
  if (!state.app.screen === "entry") {
    throw new Error(
      `ShareModal opened outside entry screen -- screen: ${state.app.screen}`
    );
  }

  const entry = state.db[state.entry.entry_id];
  if (!entry) {
    throw new Error(`ShareModal opened and had an entry_id, but entry doesn't exist`);
  }

  return {
    entry_id: entry.entry_id,
    shared: entry.shared,
    loading: state.loading.entry_sharing,
  };
}
export default connect(state_to_props, {
  toggle_entry_share_modal,
  share_entry,
  revoke_entry_sharing,
})(ShareModal);
