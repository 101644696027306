import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { show_data_editor, change_screens } from "../redux/actions";

import DRSelector from "./DRSelector";
import Journal from "./Journal";

import { is_today, cstyles } from "../common";
import Hover from "../wrappers/Hover";

const styles = {
  page: {
    userSelect: "none",
    height: "100%",
    overflowY: "auto",
  },
  inactive_journals: {
    color: cstyles.text("inactive"),
    fontSize: 14,
    paddingTop: 3,
    paddingBottom: 3,
    padding: 6,
    width: "min-content",
    whiteSpace: "nowrap",
  },
};

class JournalsScreen extends Component {
  static propTypes = {
    selected_journal_type: PropTypes.oneOf(["dailys", "responsive"]),
    journals: PropTypes.array.isRequired,
    show_data_editor: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    entries_today: PropTypes.array.isRequired,
    num_inactive_journals: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_with_entries(journals) {
    if (journals.length === 0) {
      return;
    }

    return (
      <div>
        <div style={{ marginLeft: 4 }}>Journals with an entry today</div>
        {journals.map(journal => {
          return <Journal key={journal.journal_id} journal_id={journal.journal_id} />;
        })}
      </div>
    );
  }

  render_without_entries(journals) {
    if (journals.length === 0) {
      return;
    }

    return (
      <div>
        <div style={{ marginLeft: 4 }}>Available</div>
        {journals.map(journal => {
          return <Journal key={journal.journal_id} journal_id={journal.journal_id} />;
        })}
      </div>
    );
  }

  render_daily() {
    // Get daily journals
    let { journals } = this.props;
    journals = journals.filter(journal => {
      return journal.type === "daily";
    });

    // Split out journals with entries today

    let journals_with_entries_map = {};
    this.props.entries_today.forEach(entry => {
      journals_with_entries_map[entry.journal_id] = true;
    });

    const with_entries = journals.filter(journal => {
      return journal.journal_id in journals_with_entries_map;
    });
    const without_entries = journals.filter(journal => {
      return !(journal.journal_id in journals_with_entries_map);
    });

    return (
      <div>
        {this.render_with_entries(with_entries)}
        {this.render_without_entries(without_entries)}
      </div>
    );
  }

  render_responsive() {
    return this.props.journals
      .filter(journal => {
        return journal.type === "responsive";
      })
      .map(journal => {
        return <Journal key={journal.journal_id} journal_id={journal.journal_id} />;
      });
  }

  // Shows how many journals are inactive and is a link to the Paths screen
  render_inactive_note() {
    const { num_inactive_journals } = this.props;
    const num_selected =
      this.props.selected_journal_type === "responsive"
        ? num_inactive_journals.responsive
        : num_inactive_journals.daily;

    if (num_selected > 0) {
      const text =
        num_selected === 1
          ? `${num_selected} journal is inactive`
          : `${num_selected} journals are inactive`;
      return (
        <Hover
          style={styles.inactive_journals}
          action={() => {
            this.props.change_screens("paths");
          }}
        >
          {text}
        </Hover>
      );
    }
  }

  render() {
    const { selected_journal_type } = this.props;
    return (
      <div style={styles.page}>
        <DRSelector />
        <div>
          {selected_journal_type === "dailys"
            ? this.render_daily()
            : this.render_responsive()}
        </div>
        {this.render_inactive_note()}
        <div style={{ minHeight: 50 }} />
      </div>
    );
  }
}

function state_to_props(state) {
  const inactive_ids = state.inactive_ids;
  let num_inactive_journals = {
    daily: 0,
    responsive: 0,
  };

  // get journals
  const journals = state.path_comps.journal_ids
    .map(journal_id => {
      return state.db[journal_id];
    })
    // filter out, and count, inactive journals
    .filter(journal => {
      if (inactive_ids[journal.journal_id] || inactive_ids[journal.from_path_id]) {
        if (journal.type === "daily") {
          num_inactive_journals.daily += 1;
        } else {
          num_inactive_journals.responsive += 1;
        }
        return false;
      }
      return true;
    });

  const selected_journal_type = state.journals.type;

  // For daily journals, get entries that are today
  let entries_today = [];
  if (selected_journal_type === "dailys") {
    entries_today = state.entries
      .map(entry_id => {
        return state.db[entry_id];
      })
      .filter(entry => {
        return is_today(entry.timestamp);
      });
  }

  return {
    selected_journal_type,
    entries_today,
    journals,
    num_inactive_journals,
  };
}
export default connect(state_to_props, { show_data_editor, change_screens })(
  JournalsScreen
);
