import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import {} from "../common";
import {} from "../getters";

import Creator from "./Creator";

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 10,
    overflowY: "auto",
  },
  title: {
    marginTop: 10,
    fontSize: 16,
  },
};

class PathsScreen extends Component {
  static propTypes = {
    summary_ids: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_creators() {
    return this.props.summary_ids.map(summary_id => {
      return <Creator summary_id={summary_id} key={summary_id} />;
    });
  }

  render() {
    return (
      <div style={styles.page}>
        <div style={styles.title}>All paths by the creators I'm subscribed to </div>
        {this.render_creators()}
        <div style={{ minHeight: 100 }} />
      </div>
    );
  }
}

function state_to_props(state) {
  const summary_ids = state.profile
    ? state.profile.subscribed_creators.map(sub => {
        return `${sub.creator_id}:summary`;
      })
    : [];
  return {
    summary_ids,
  };
}
export default connect(state_to_props, {})(PathsScreen);
