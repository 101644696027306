// very simple, full width+height page for quick loading screens

import React from "react";

import {} from "./common";

const styles = {
  full_page: {
    width: "100vw",
    height: "100vh",
  },
  inner: {
    minWidth: "100%",
    height: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    border: "1.5px solid rgba(0, 0, 0, 0)",
    borderTop: "1.5px solid #bae7ff",
    borderRadius: "50%",
    width: 10,
    height: 10,
    animation: "spin 1s linear infinite",
    opacity: 0.65,
  },
};

// pass in props.page to make it render as a full page (with 100vh/100vw)
export default function Loading(props) {
  if (props.page) {
    return (
      <div style={styles.full_page}>
        <div style={styles.inner}>
          <div style={styles.spinner} />
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.inner}>
        <div style={styles.spinner} />
      </div>
    );
  }
}
