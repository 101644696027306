import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "./redux/actions";

import { ss, cstyles } from "./common";
import {} from "./getters";

const check_icon = require("./icons/green_check.png");

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "opacity 1000ms",
    opacity: 0.8,
  },
  spinner: {
    border: "3px solid " + cstyles.raised(0),
    borderTop: "3px solid #bae7ff",
    borderRadius: "50%",
    width: 8,
    height: 8,
    animation: "spin 1.5s linear infinite",
  },
  check: {
    height: 20,
    width: 20,
  },
};

class SyncIndicator extends Component {
  static propTypes = {
    syncing: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      opacity: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.syncing && !this.props.syncing) {
      this.setState({
        opacity: 0.6,
      });

      setTimeout(() => {
        this.setState({
          opacity: 0.1,
        });
      }, 6000);
    } else if (!prevProps.syncing && this.props.syncing) {
      this.setState({
        opacity: 0.8,
      });
    }
  }

  render() {
    const { syncing } = this.props;

    const container_style = ss(true, styles.container, {
      opacity: this.state.opacity,
    });

    return (
      <div style={container_style}>
        {syncing ? (
          <div style={styles.spinner} />
        ) : (
          <img style={styles.check} src={check_icon} alt={"check"} />
        )}
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    syncing: state.sync.syncing,
  };
}
export default connect(state_to_props, {})(SyncIndicator);
