import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_new, change_screens } from "../redux/actions";

import { ss, cstyles, is_today } from "../common";
import Hover from "../wrappers/Hover";

import ResponsiveEntryQuickSelect from "./ResponsiveEntryQuickSelect";

const small_logo = require("../icons/logo-small.png");

const styles = {
  container: {
    margin: 5,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    height: 130,
    userSelect: "none",
  },
  card: {
    backgroundColor: cstyles.raised(1),
    display: "flex",
    flexDirection: "row",
    width: 540,
  },
  image_container: {
    position: "relative",
    backgroundColor: "red",
    width: 170,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 5,
    paddingLeft: 10,
  },
  title: {
    fontSize: 21,
    fontWeight: 600,
  },
  author: {
    fontSize: 14,
  },
  about: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
  },
  logo: {
    width: 48,
    height: 48,
    position: "absolute",
    top: 10,
    left: 8,
    opacity: 0.5,
  },
  entry_container: {
    display: "flex",
    flexDirection: "column",
    scroll: "auto",
    overflowY: "auto",
    paddingRight: 15,
    scrollbarWidth: "thin",
  },
};

class Journal extends Component {
  static propTypes = {
    journal_id: PropTypes.string.isRequired,
    journal: PropTypes.object.isRequired,
    path: PropTypes.object.isRequired,
    entries: PropTypes.array.isRequired,
    set_entry_new: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_logo() {
    if (this.props.path.author === "wellsayer") {
      return <img src={small_logo} style={styles.logo} alt={"logo"} />;
    }
  }

  render_entries() {
    if (this.props.journal.type === "responsive") {
      return (
        <div style={styles.entry_container}>
          {this.props.entries.map(entry => {
            return (
              <ResponsiveEntryQuickSelect
                key={entry.entry_id}
                entry_id={entry.entry_id}
              />
            );
          })}
        </div>
      );
    }
  }

  on_click() {
    const { journal, change_screens, set_entry_new } = this.props;

    set_entry_new(journal.journal_id);
    change_screens("entry");
  }

  render() {
    const { journal, path } = this.props;

    const image_container_style = ss(true, styles.image_container, {
      backgroundColor: journal.highlight_color,
    });

    return (
      <div style={styles.container}>
        <Hover style={styles.card} action={() => this.on_click()}>
          <div style={image_container_style}>{this.render_logo()}</div>
          <div style={styles.body}>
            <div>
              <div style={styles.title}>{journal.name}</div>
              <div style={styles.author}>{path.author}</div>
            </div>
            <div style={styles.about}>
              <div>
                A <b>{journal.type}</b> journal from the <b>{path.name}</b> path
              </div>
            </div>
          </div>
        </Hover>
        {this.render_entries()}
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  // Get journal
  const journal = state.db[ownProps.journal_id];

  // Get entries for this journal today
  let entries = state.entries
    .map(entry_id => {
      return state.db[entry_id];
    })
    .filter(entry => {
      return entry.journal_id === ownProps.journal_id;
    })
    .filter(entry => {
      return is_today(entry.timestamp);
    });

  // Check for only 1 journal for dailys
  if (journal.type === "daily" && entries.length > 1) {
    throw new Error("daily journal has more than 1 journal for today");
  }

  // Sort entries if responsive
  if (journal.type === "responsive") {
    entries = entries.sort((a, b) => {
      return a.timestamp < b.timestamp;
    });
  }

  return {
    journal,
    entries,
    path: state.db[journal.from_path_id],
  };
}
export default connect(state_to_props, { set_entry_new, change_screens })(Journal);
