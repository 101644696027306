import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { ss } from "../common";

const styles = {
  item: {
    marginRight: 4,
  },
  deleted: {
    fontStyle: "italic",
    opacity: 0.7,
  },
};

class RefItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const style = ss(this.props.deleted, styles.item, styles.deleted);
    return <div style={style}>{`${this.props.name}, `}</div>;
  }
}

function state_to_props(state, ownProps) {
  const item = state.db[ownProps.id];

  return {
    name: item ? state.db[ownProps.id].name : "removed",
    deleted: item ? false : true,
  };
}
export default connect(state_to_props, {})(RefItem);
