// Convenience functions for getting things from state

import { is_same_date, get_answer_item_key } from "./common";
import { find_first_available_question } from "./redux/reducer";

const today = new Date();

// Gets an answer for a question
function get_answer(state, question_id, entry_id, map = false) {
  if (!entry_id) {
    if (map) {
      return [];
    }

    return null;
  }

  const entry = state.db[entry_id];

  const answer = question_id in entry.answers ? entry.answers[question_id] : null;

  // If answer is an array of ids, map will map them to objects from the db
  if (map) {
    if (!answer) {
      return [];
    }

    return answer.map(id => {
      return state.db[id];
    });
  } else {
    return answer;
  }
}

// Gets an entry + answer:
//  - that's on the same day as the my_entry_id (if none, then today)
//  - that's for a referenced question (so we don't know the ref_entry_id)
//  - must be referencing a daily journal, since it must be constrained to 1 entry a day
function get_ref_by_entryday(state, ref_question_id, my_entry_id, map = false) {
  // Figure out which day we should be looking for a ref_entry on
  // If we don't have an entry, then we're a new journal for today
  // otherwise, we're looking an entry, and should search for a ref_entry on the same day as the entry
  const search_day = my_entry_id
    ? state.db[my_entry_id].timestamp
    : new Date().getTime();

  // Get the journal_id for the referenced question
  const { journal_id: ref_journal_id, type: ref_journal_type } = state.db[
    state.db[state.db[ref_question_id].from_prompt_id].from_journal_id
  ];

  if (ref_journal_type !== "daily") {
    throw new Error("get_ref_by_entryday can only reference a daily journal");
  }

  // Get entry for ref_question
  const ref_entry = state.entries
    .map(entry_id => {
      return state.db[entry_id];
    })
    .filter(entry => {
      return entry.journal_id === ref_journal_id;
    })
    .filter(entry => {
      return is_same_date(search_day, entry.timestamp);
    })[0];

  // Now get the answer from that entry for ref_question_id
  return {
    ref_answer: get_answer(
      state,
      ref_question_id,
      ref_entry ? ref_entry.entry_id : null,
      map
    ),
    ref_entry: ref_entry ? ref_entry : null,
    ref_journal_id,
  };
}

// Filters a list of IDs on whether or not they still resolve
// Used for answers which don't control the existence of their IDs and are
// referencing IDs created by other questions
function filter_resolves(state, list_of_ids) {
  if (!list_of_ids) {
    return [];
  }

  return list_of_ids
    .map(id => {
      return state.db[id];
    })
    .filter(item => {
      return item ? true : false;
    })
    .map(item => {
      const key_name = get_answer_item_key(item);
      return item[key_name];
    });
}

// Gets the entry for a daily journal on a specified date (null = today)
function get_dailys_entry(state, journal_id, _date = null) {
  const date = _date ? _date : today;

  const entry = state.entries
    .map(entry_id => {
      return state.db[entry_id];
    })
    .filter(entry => {
      return entry.journal_id === journal_id;
    })
    .filter(entry => {
      return is_same_date(entry.timestamp, date);
    })[0];

  return entry ? entry : null;
}

// gets all the journals that the user can go and use right now for a path
// So, inactive and unavailable journals are filtered out
function get_usable_journals(state, path_id) {
  const path = state.db[path_id];

  const inactive_ids = state.inactive_ids;
  if (inactive_ids[path_id]) {
    return [];
  }

  const all_journal_ids = path.journals.map(journal => {
    return journal.journal_id;
  });

  // filter inactive (user deactivated) journals + paths
  let usable_journals = all_journal_ids.filter(journal_id => {
    return !inactive_ids[journal_id];
  });

  if (path.journey) {
    const current_stage_id = state.journeys[path.path_id].stage;
    const current_stage = path.journey.stages[current_stage_id];

    if (current_stage.available_journals) {
      usable_journals = usable_journals.filter(journal_id => {
        return current_stage.available_journals.includes(journal_id);
      });
    } else if (current_stage.unavailable_journals) {
      usable_journals = usable_journals.filter(journal_id => {
        return !current_stage.unavailable_journals.includes(journal_id);
      });
    }
  }

  return usable_journals;
}

// gets the available (set by a journey) journal_ids for a path
function get_available_journals(state, path_id) {
  const path = state.db[path_id];

  // get all journal ids
  let available_journals = path.journals.map(journal => {
    return journal.journal_id;
  });

  if (path.journey) {
    const current_stage_id = state.journeys[path.path_id].stage;
    const current_stage = path.journey.stages[current_stage_id];

    if (current_stage.available_journals) {
      available_journals = available_journals.filter(journal_id => {
        return current_stage.available_journals.includes(journal_id);
      });
    } else if (current_stage.unavailable_journals) {
      available_journals = available_journals.filter(journal_id => {
        return !current_stage.unavailable_journals.includes(journal_id);
      });
    }
  }

  return available_journals;
}

// answers whether or not the first available question in a journal has context
function first_question_has_context(state, journal_id) {
  const first_availabe_question_id = find_first_available_question(state, journal_id);

  if (!first_availabe_question_id) {
    return false;
  }

  const first_available_question = state.db[first_availabe_question_id];

  return !!first_available_question.context;
}

// Get all of the entries for a path
function get_path_entries(state, path_id) {
  return (
    state.entries
      // map id to object
      .map(entry_id => {
        return state.db[entry_id];
      })
      // remove entries for journals that don't exist (TODO - should we add a from_path_id to entries?)
      .filter(entry => {
        return entry.journal_id in state.db;
      })
      // filter to only entries from journals in this path
      .filter(entry => {
        return state.db[entry.journal_id].from_path_id === path_id;
      })
  );
}

export {
  get_answer,
  get_ref_by_entryday,
  filter_resolves,
  get_dailys_entry,
  get_usable_journals,
  get_available_journals,
  first_question_has_context,
  get_path_entries,
};
