import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_search_mode, set_creator_id, change_screens } from "../redux/actions";

import { cstyles, is_uuid } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 10,
    opacity: 1,
    transition: "opacity 350ms",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  code_search_box: {
    padding: 10,
    fontSize: 12,
    border: cstyles.border("input"),
    background: cstyles.raised(2),
    borderRadius: 8,
    resize: "none",
    color: cstyles.text("rest"),
    height: "min-content",
    width: 300,
  },
  button: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    fontSize: 18,
    fontWeight: 700,
    marginLeft: 30,
  },
  go_back: {
    padding: 6,
    borderRadius: 4,
    fontSize: 14,
    marginTop: 10,
    width: "min-content",
    whiteSpace: "nowrap",
    color: cstyles.text("rest"),
  },
  invalid_code: {
    color: cstyles.remove,
  },
};

class Code extends Component {
  static propTypes = {
    set_search_mode: PropTypes.func.isRequired,
    set_creator_id: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      code_input: "",
      code_invalid: false,
    };
  }

  on_enter_text(text) {
    this.setState({ code_input: text, code_invalid: false }, () => {
      this.on_search();
    });
  }

  on_search() {
    const { code_input } = this.state;
    if (is_uuid(code_input)) {
      this.setState({ code_invalid: false });
      this.props.set_creator_id(code_input);
      this.props.change_screens("creator");
    } else {
      this.setState({ code_invalid: true });
    }
  }

  render() {
    return (
      <div style={styles.page}>
        <div>Enter the creator code</div>
        <div style={styles.row}>
          <input
            style={styles.code_search_box}
            placeholder={"creator code"}
            value={this.state.code_input}
            onChange={e => this.on_enter_text(e.target.value)}
          />
          <Hover action={() => this.on_search()} style={styles.button}>
            go
          </Hover>
        </div>
        {this.state.code_invalid ? (
          <div style={styles.invalid_code}>invalid code</div>
        ) : null}
        <Hover
          action={() => this.props.set_search_mode("start")}
          style={styles.go_back}
        >
          go back
        </Hover>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {
  set_search_mode,
  set_creator_id,
  change_screens,
})(Code);
