import React, { Component } from "react";
import PropTypes from "prop-types";

import {} from "../common";
import {} from "../getters";

import { sign_in } from "./auth_actions";

export default class Confirm extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.checker = setInterval(() => {
      sign_in(this.props.email, this.props.password);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.checker);
  }

  render() {
    return (
      <div>
        <div>Check your email to confirm your account</div>
        <div style={{ marginTop: 30 }}>
          Return here and you'll be automatically logged in
        </div>
      </div>
    );
  }
}
