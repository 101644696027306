// A simple chip for displaying in the answer. Can be an affect or a multiple select

import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { cstyles, ss } from "../common";

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 13,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
  },
  deleted: {
    fontStyle: "italic",
    opacity: 0.7,
  },
};

class Chip extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const style = ss(this.props.deleted, styles.chip, styles.deleted);
    return <div style={style}>{this.props.name}</div>;
  }
}

function state_to_props(state, ownProps) {
  const item = state.db[ownProps.id];
  return {
    name: item ? state.db[ownProps.id].name : "removed",
    deleted: item ? false : true,
  };
}
export default connect(state_to_props, {})(Chip);
