import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_setting } from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const icon_checked = require("../icons/radio_checked.png");
const icon_unchecked = require("../icons/radio_unchecked.png");

// example of a definition object
const example_definition = {
  header: "",
  setting_name: "",
  options: [
    {
      setting_value: "",
      setting_label: "",
    },
  ],
};

const styles = {
  setting_container: {
    marginTop: 20,
    userSelect: "none",
  },
  radio_section: {
    marginLeft: 5,
  },
  radio: {
    maxWidth: "85%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 2,
    marginBottom: 4,
    backgroundColor: cstyles.raised(1),
    padding: 2,
  },
  header: {
    fontWeight: 600,
    marginBottom: 5,
  },
  radio_label: {
    paddingLeft: 10,
    marginTop: 3,
    fontSize: 15,
    color: cstyles.text("rest"),
  },
  icon: {
    height: 14,
    width: 14,
  },
};

class RadioSection extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    change_setting: PropTypes.func.isRequired,
    definition: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.validate_definition();
  }

  // Validate that the definition given is formatted as expected
  validate_definition() {
    const { definition } = this.props;

    // Validate top level keys
    Object.keys(example_definition).forEach(key => {
      if (!(key in definition)) {
        throw new Error(
          `Invalid definition given to RadioSection. Expected ${key} key`
        );
      }
    });

    // Validate keys in options
    definition.options.forEach(option => {
      Object.keys(example_definition.options[0]).forEach(key => {
        if (!(key in option)) {
          throw new Error(`Invalid option given to RadioSection. Expected ${key} key`);
        }
      });
    });
  }

  render() {
    const { settings, definition } = this.props;
    const current_setting_value = settings[definition.setting_name];

    return (
      <div style={styles.setting_container}>
        <div style={styles.header}>{definition.header}</div>
        <div style={styles.radio_section}>
          {definition.options.map(radio_option => {
            const icon =
              current_setting_value === radio_option.setting_value
                ? icon_checked
                : icon_unchecked;
            return (
              <Hover
                key={`radiosection:option:${radio_option.setting_value}`}
                style={styles.radio}
                action={() => {
                  this.props.change_setting(
                    definition.setting_name,
                    radio_option.setting_value
                  );
                }}
              >
                <img alt={radio_option.setting_label} src={icon} style={styles.icon} />
                <div style={styles.radio_label}>{radio_option.setting_label}</div>
              </Hover>
            );
          })}
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    settings: state.settings,
  };
}
export default connect(state_to_props, { change_setting })(RadioSection);
