import produce from "immer";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import * as AT from "./action_types";

import {
  new_qops,
  new_entry,
  new_affect_category,
  new_feeling,
  new_path,
  new_journal,
  new_prompt,
  new_question,
} from "../create_new";
import { is_syncable_key, new_sync_item, is_today, is_uuid } from "../common";
import { get_path_entries } from "../getters";

const app_init = {
  // A place to store error messages that aren't persisted
  errors: {
    creator_page: null,
    publish_path: null,
  },
  // A place to store loading flags that isn't persisted
  loading: {
    sub_unsub_creator: false,
    sync_account: false,
    entry_sharing: false, // same flag for granting and revoking sharing
    search_creators: false,
    designer_publish_path: false,
  },
  app: {
    screen: "home",
    show_settings: false,
    show_quick_buttons: false,
  },
  // components broken out for easy parsing
  path_comps: {
    path_ids: [],
    journal_ids: [], // all journal ids from paths
    button_ids: [], // all button ids from paths
  },
  editor: {
    show: false,
    journal_id: "",
    question_id: "",
    mode: "create",
    next_question: null, // the next available question in a path. null if n/a
    previous_question: null, // the previous question in a path. null if n/a
    text_entry_focus: false, // whether a text entry is in focus
    expanded: false,
  },
  db: {},
  qops_map: {}, // maps a question_id -> qops_id. Basically a lookup table to quickly find a qops_id
  entries: [],
  affects: {
    categories: [], // list of category_ids
    feelings: [], // list of feeling_ids
  },
  settings: {
    default_editor_height: "compact",
    entry_auto_scrolling: true,
    arrow_navigation_typing: "up_down_only",
    accent_color: "#FDC7D7",
    auto_show_side_context: "few",
  },
  journals: {
    type: "dailys",
  },
  entry: {
    entry_id: "",
    journal_id: "",
    show_share_modal: false,
    show_side_context: false,
    side_context_expanded: false,
    show_stage_complete: false,
  },
  auth: {
    signed_in: false,
    error: null,
    error_message: "",
    email: null,
  },
  // items that need to be synced to the API (list of IDs)
  sync: {
    queue: [],
    last_sync: null,
    syncing: false,
  },
  profile: {
    subscribed_creators: [],
  },
  inactive_ids: {}, // map of inactive ids. can be a path/journal. no entry = assumed active. true = inactive
  // search screen
  search: {
    mode: "start",
    home_creators_list: [],
  },
  // creator screen
  creator: {
    id: "",
  },
  stats: {
    app_opens: 0,
    journals: {
      /*
      "journal_id": {
        opens: 0,
        entries: 0
      }
      */
    },
  },
  // keeps current status of journeys
  journeys: {
    /*
    "path_id": {
      stage: "stage_id",
      prev_stage: "stage_id"
    }
    */
  },
  designer: {
    selected_path: null,
    selected_journal: null,
    selected_question: null,
    show_context: false,
    path_manager_path_id: null,
  },
  // content owned by the user
  my_content: {
    draft_paths: {},
    draft_path_order: [],
  },
};

// eslint-disable-next-line no-unused-vars
function log_proxy(prox_obj) {
  if (prox_obj === undefined) {
    // eslint-disable-next-line no-console
    return console.log("proxy object is undefined");
  }
  if (prox_obj === null) {
    // eslint-disable-next-line no-console
    return console.log("proxy object is null");
  }

  // unravel proxy
  const obj = JSON.parse(JSON.stringify(prox_obj));

  // Pretty print
  // eslint-disable-next-line no-console
  console.log(JSON.stringify(obj, null, 2));
}

// recalculates state.path_comps
function calc_path_comps(draft) {
  if (!draft.profile) {
    return;
  }

  // get paths from currently subscribed creators
  const paths = [];
  draft.profile.subscribed_creators.forEach(sub => {
    const summary = draft.db[`${sub.creator_id}:summary`];

    if (!summary) {
      return;
    }

    summary.paths.forEach(path_summary => {
      if (draft.db[path_summary.path_id]) {
        paths.push(draft.db[path_summary.path_id]);
      }
    });
  });

  // break paths down into components
  let path_ids = [];
  let journal_ids = [];
  let button_ids = [];

  paths.forEach(path => {
    path_ids.push(path.path_id);

    path.journals.forEach(journal => {
      journal_ids.push(journal.journal_id);
    });

    path.buttons.forEach(button => {
      button_ids.push(button.button_id);
    });
  });

  draft.path_comps.path_ids = path_ids;
  draft.path_comps.journal_ids = journal_ids;
  draft.path_comps.button_ids = button_ids;

  init_journeys(draft);
}

// sets init info for all journey style paths
function init_journeys(draft) {
  draft.path_comps.path_ids.forEach(path_id => {
    const path = draft.db[path_id];

    if (path.journey) {
      if (!draft.journeys[path_id]) {
        draft.journeys[path_id] = {
          stage: path.journey.initial_stage,
          prev_stage: path.journey.initial_stage,
        };

        update_journey_status(draft, path_id);
      }
    }
  });
}

function journey_condition_is_met(draft, condition) {
  if (condition.type === "answer") {
    const journal_id =
      draft.db[draft.db[condition.question_id].from_prompt_id].from_journal_id;

    // Get latest entry for journal
    const latest_entry = draft.entries
      .map(entry_id => {
        return draft.db[entry_id];
      })
      .filter(entry => {
        if (!entry) {
          return false;
        }
        return entry.journal_id === journal_id;
      })
      .sort((a, b) => {
        return b.timestamp - a.timestamp;
      })[0];

    if (!latest_entry) {
      return false;
    }

    // evaluate criteria against answer
    return evaluate_criteria(
      draft,
      latest_entry.answers[condition.question_id],
      condition.criteria
    );
  } else if (condition.type === "min_journal_entry_count") {
    if (!draft.stats.journals[condition.journal_id]) {
      return false;
    }

    // evaluate criteria against condition count
    return draft.stats.journals[condition.journal_id].entries >= condition.count;
  } else if (condition.type === "no_entrys") {
    if (!draft.stats.journals[condition.journal_id]) {
      return true;
    }

    // evaluate criteria against condition count
    return draft.stats.journals[condition.journal_id].entries === 0;
  } else {
    throw new Error(`unrecognized condition type in journey_condition_is_met`);
  }
}

// sets default options
function get_journey_options(options) {
  const default_options = {
    restart_stages_on_onboarding_change: false,
    recursively_follow_stage_transitions: true,
  };

  Object.keys(default_options).forEach(op => {
    if (!(op in options)) {
      options[op] = default_options[op];
    }
  });

  return options;
}

function update_journey_status(draft, path_id, context = null) {
  const path = draft.db[path_id];
  const { journey } = path;
  if (!journey) {
    return;
  }

  const options = get_journey_options(journey.options);

  if (context && context.type === "entry_change") {
    // handle option restart_stages_on_onboarding_change
    if (options.restart_stages_on_onboarding_change) {
      const init_stage = journey.stages[journey.initial_stage];
      const init_journal = draft.db[init_stage.active_journal];
      if (
        context.journal_id === init_journal.journal_id &&
        init_journal.type === "onboarding"
      ) {
        // reset journey to initial stage
        draft.journeys[path_id].stage = journey.initial_stage;
      }
    }
  }

  // Get current info
  const current_stage_id = draft.journeys[path_id].stage;
  const current_stage = Object.entries(journey.stages).reduce((acc, cur) => {
    const [stage_id, stage] = cur;

    if (stage_id === current_stage_id) {
      return stage;
    }
    return acc;
  }, null);

  // Determine whether any transitions apply
  const transition = current_stage.transitions.find(transition => {
    // Check whether all criteria are true
    const conditions_met = transition.conditions.reduce((status, condition) => {
      if (!journey_condition_is_met(draft, condition)) {
        status = false;
      }

      return status;
    }, true);

    return conditions_met;
  });

  // If no transition applies, there's nothing to do!
  if (!transition) {
    return;
  }

  // Move journey to destination stage
  draft.journeys[path_id].prev_stage = current_stage_id;
  draft.journeys[path_id].stage = transition.dest_stage;

  // sync journey stages
  queue_sync(draft, "@@journeys", "update");

  // Show stage complete message if we're on entry screen
  if (draft.app.screen === "entry") {
    if (current_stage.on_complete) {
      if (current_stage.on_complete.show_stage_complete_popup) {
        draft.entry.show_stage_complete = true;
      }
    } else {
      draft.entry.show_stage_complete = true;
    }
  }

  // Recursively call ourselves until there are no more transitions
  if (options.recursively_follow_stage_transitions) {
    update_journey_status(draft, path_id);
  }
}

function load_paths(draft, paths) {
  // Allow for a single path or an array of paths to be added
  if (!Array.isArray(paths)) {
    paths = [paths];
  }

  // break paths down and add individual objects to the db
  paths.forEach(path => {
    // Add or update path in obj db
    draft.db[path.path_id] = path;

    // Iterate over journals
    path.journals.forEach(journal => {
      // Add parent id
      journal.from_path_id = path.path_id;

      // Add journal to db
      draft.db[journal.journal_id] = journal;

      // Iterate over prompts
      journal.prompts.forEach(prompt => {
        // Add parent id
        prompt.from_journal_id = journal.journal_id;

        // Add or update prompt in obj db
        draft.db[prompt.prompt_id] = prompt;

        // Iterate over questions
        prompt.questions.forEach(question => {
          // Add parent id
          question.from_prompt_id = prompt.prompt_id;

          // Add or update question in obj db
          draft.db[question.question_id] = question;
        });
      });
    });

    // Add buttons
    path.buttons.forEach(button => {
      // Add path id to button def
      button.from_path_id = path.path_id;

      // Add to db
      draft.db[button.button_id] = button;
    });
  });

  calc_path_comps(draft);
}

// add an item to the sync.queue
function queue_sync(draft, id, action) {
  if (!is_syncable_key(id)) {
    throw new Error(`attempting to sync a non-syncable key: '${id}'`);
  }

  // see if we've already queued a sync for this item
  const matching_sync = draft.sync.queue.filter(sync => {
    return sync.id === id;
  });

  // if there's already 1 in the queue, don't queue if it's the same action
  if (matching_sync.length === 1 && matching_sync[0].action === action) {
    return;
  }

  // queue
  draft.sync.queue.push(new_sync_item(id, action));
}

function update_qops(draft, redux_action) {
  const { question_id, action } = redux_action;
  let { option } = redux_action;

  // Get qops for this question_id. If there isn't one, lazily create it
  let qops;
  if (question_id in draft.qops_map) {
    qops = draft.db[draft.qops_map[question_id]];
  } else {
    qops = new_qops(question_id);
    draft.qops_map[question_id] = qops.qops_id;
  }

  if (action === "create") {
    // Update qops
    qops.options[option.option_id] = option;
  } else if (action === "remove") {
    delete qops.options[option.option_id];

    option = JSON.parse(JSON.stringify(option));
    option.status = "removed";
  }

  // Update object db
  draft.db[qops.qops_id] = qops;
  draft.db[option.option_id] = option;
  queue_sync(draft, qops.qops_id, "update");
}

function update_entry(draft, action) {
  const { entry_id, question_id, question_type, data } = action;

  // Get journal_id
  const journal_id = draft.db[draft.db[question_id].from_prompt_id].from_journal_id;

  // Get entry, or lazy create it if we don't have one yet
  let entry;
  if (!entry_id) {
    // Create entry
    entry = new_entry(journal_id);

    // Add it to list of known entries
    draft.entries.push(entry.entry_id);

    // Set entry_id in entry screen info
    draft.entry.entry_id = entry.entry_id;

    update_stats(draft, "new_entry", journal_id);
  } else {
    entry = draft.db[entry_id];
  }

  // Update data
  if (
    ["text_entry", "yes_no", "shuffle", "checkbox", "single_select"].includes(
      question_type
    )
  ) {
    entry.answers[question_id] = data;
  } else if (
    ["multiple_select", "affects", "scale", "subset"].includes(question_type)
  ) {
    // get answer (or lazy create)
    let answer = entry.answers[question_id];
    if (!answer) {
      answer = [];
    }

    // select / remove option from list
    if (data.selected === true) {
      answer.push(data.id);
    } else {
      answer = answer.filter(ans => {
        return ans !== data.id;
      });
    }

    // update answer
    entry.answers[question_id] = answer;
  } else if (["list", "table_schedule_list"].includes(question_type)) {
    /*
    data: {
      action: "new || remove || update",
      item: {} (only for new or update)
      item_id: (only for remove)
    }
    */

    // get answer (or lazy create)
    let answer = entry.answers[question_id];
    if (!answer) {
      answer = [];
    }

    if (data.action === "new") {
      // Add to answer
      answer.push(data.item.item_id);

      // Add to db
      draft.db[data.item.item_id] = data.item;
    } else if (data.action === "update") {
      // Get item from db
      const orig_item = draft.db[data.item.item_id];

      // Spread new item into
      const updated_item = {
        ...orig_item,
        ...data.item,
      };

      // Update db
      draft.db[data.item.item_id] = updated_item;
    } else {
      // Remove from answer
      answer = answer.filter(item_id => {
        return item_id !== data.item_id;
      });

      // Remove from db
      delete draft.db[data.item_id];
    }

    // update answer
    entry.answers[question_id] = answer;
  } else {
    throw new Error(
      `question_type not handled in update_entry: ${question_type} - ${JSON.stringify(
        data
      )}`
    );
  }

  // Update reacts
  draft.db[journal_id].prompts.forEach(prompt => {
    const { react } = prompt;
    if (react) {
      // Get answer for source question
      const source_answer = entry.answers[react.source_question_id];

      if (evaluate_criteria(draft, source_answer, react.criteria)) {
        entry.reacts[prompt.prompt_id] = react.action;
      } else {
        // Set action to default: null
        entry.reacts[prompt.prompt_id] = null;
      }
    }
  });

  // Update db
  draft.db[entry.entry_id] = entry;

  // Update journey
  const path_id = draft.db[journal_id].from_path_id;
  update_journey_status(draft, path_id, {
    type: "entry_change",
    journal_id: entry.journal_id,
  });

  queue_sync(draft, entry.entry_id, "update");

  if (draft.app.screen === "entry") {
    determine_next_prev_questions(draft);
  }
}

// evaluates a criteria check for prompt reacts or journey answer conditions
function evaluate_criteria(draft, answer, criteria) {
  // Convert answer into something that can be checked by the react criteria

  if (!answer) {
    return false;
  }

  if (Array.isArray(answer)) {
    answer = answer.map(val => {
      // scale answers are lists of numbers
      if (typeof val === "number") {
        return val.toString();
      } else if (is_uuid(val)) {
        const obj = draft.db[val];

        if (obj.name) {
          return obj.name.toLowerCase();
        } else {
          throw new Error(
            `unhandled answer type in entry react check for array. val: ${val}`
          );
        }
      } else {
        throw new Error(`unhandled case in update reacts in update_entry for isArray`);
      }
    });
  } else {
    if (is_uuid(answer)) {
      const obj = draft.db[answer];

      if (obj.name) {
        answer = obj.name.toLowerCase();
      } else {
        throw new Error(
          `unhandled answer type in entry react check for single. val: ${answer}`
        );
      }
    } else {
      answer = answer.toString().toLowerCase();
    }
  }

  // Check if criteria are met
  let { check, value } = criteria;
  if (Array.isArray(value)) {
    value = value.map(v => v.toString().toLowerCase());
  } else {
    value = value.toString().toLowerCase();
  }

  if (check === "is_equal") {
    if (answer === value) {
      return true;
    }
  } else if (check === "is_not_equal") {
    if (answer !== value) {
      return true;
    }
  } else if (check === "is_one_of") {
    if (value.includes(answer)) {
      return true;
    }
  } else if (check === "is_not_one_of") {
    if (!value.includes(answer)) {
      return true;
    }
  } else if (check === "includes") {
    if (answer.includes(value)) {
      return true;
    }
  } else if (check === "not_includes") {
    if (!answer.includes(value)) {
      return true;
    }
  } else if (check === "includes_one_of") {
    if (answer.some(v => value.includes(v))) {
      return true;
    }
  } else if (check === "not_includes_one_of") {
    if (!answer.some(v => value.includes(v))) {
      return true;
    }
  }

  return false;
}

function on_entry_sharing_updated(draft, action) {
  const { entry_id, is_shared, token } = action;

  let entry = draft.db[entry_id];
  if (!entry) {
    throw new Error(`no entry in on_entry_sharing_updated`);
  }

  if (is_shared) {
    entry.shared = token;
  } else {
    delete entry.shared;
  }

  // Update db
  draft.db[entry.entry_id] = entry;
  queue_sync(draft, entry.entry_id, "update");
}

function remove_entry(draft, entry_id, sync = true) {
  draft.entries = draft.entries.filter(id => {
    return entry_id !== id;
  });

  if (sync) {
    const journal_id = draft.db[entry_id].journal_id;
    const path_id = draft.db[journal_id].from_path_id;

    update_stats(draft, "remove_entry", journal_id);

    update_journey_status(draft, path_id, {
      type: "entry_change",
      journal_id,
    });

    queue_sync(draft, entry_id, "remove");
  }

  delete draft.db[entry_id];
}

function add_new_affect_category(draft) {
  const new_category = new_affect_category();

  // Push id to categories list
  draft.affects.categories.push(new_category.category_id);

  // Update object db
  draft.db[new_category.category_id] = new_category;
  queue_sync(draft, new_category.category_id, "update");
}

function update_affect_category(draft, action) {
  const { category_id, field, value } = action;

  // Get category from db
  let category = draft.db[category_id];

  // Update field on category
  category[field] = value;

  // Update in object db
  draft.db[category_id] = category;
  queue_sync(draft, category_id, "update");
}

function remove_affect_category(draft, action, sync = true) {
  const { category_id } = action;

  // Remove from affects.categories
  draft.affects.categories = draft.affects.categories.filter(
    cat_id => cat_id !== category_id
  );

  if (sync) {
    queue_sync(draft, category_id, "remove");
  }
}

function add_new_feeling(draft) {
  const feeling = new_feeling();

  // Push id to feelings list
  draft.affects.feelings.push(feeling.feeling_id);

  // Update object db
  draft.db[feeling.feeling_id] = feeling;
  queue_sync(draft, feeling.feeling_id, "update");
}

function update_feeling(draft, action) {
  const { feeling_id, field, value } = action;

  // Get feeling from db
  let feeling = draft.db[feeling_id];

  // Update field on feeling
  feeling[field] = value;

  // Update in db
  draft.db[feeling_id] = feeling;
  queue_sync(draft, feeling_id, "update");
}

function remove_feeling(draft, action, sync = true) {
  const { feeling_id } = action;

  // Remove from affects.feelings
  draft.affects.feelings = draft.affects.feelings.filter(
    feel_id => feel_id !== feeling_id
  );

  if (sync) {
    queue_sync(draft, feeling_id, "remove");
  }
}

function dev_action(draft, redux_action) {
  const { action, value } = redux_action;
  if (action === "clear_entries") {
    draft.entries.forEach(entry_id => {
      delete draft.db[entry_id];
    });
    draft.entries = [];
  } else if (action === "update_db") {
    draft.db[value.id] = value.value;
  } else if (action === "add_entry_id") {
    draft.entries.push(value);
  } else if (action === "set_all_entries_back_one_day") {
    draft.entries
      .map(entry_id => {
        return draft.db[entry_id];
      })
      .forEach(entry => {
        const entry_day = new Date(entry.timestamp);
        const new_entry_day = (d => new Date(d.setDate(d.getDate() - 1)))(entry_day);
        entry.timestamp = new_entry_day.getTime();
        draft.db[entry.entry_id] = entry;
      });
  }
}

function change_setting(draft, action) {
  const { what, value } = action;

  switch (what) {
    case "default_editor_height":
      draft.settings.default_editor_height = value;
      break;
    case "entry_auto_scrolling":
      draft.settings.entry_auto_scrolling = value;
      break;
    case "arrow_navigation_typing":
      draft.settings.arrow_navigation_typing = value;
      break;
    case "accent_color":
      draft.settings.accent_color = value;
      break;
    case "auto_show_side_context":
      draft.settings.auto_show_side_context = value;
      break;

    default:
      throw new Error("invalid setting name");
  }
}

// Determines what the next and previous questions are, relative to the current selected question, in a journal
function determine_next_prev_questions(draft) {
  // Get prompts from current pathdef
  const { prompts } = draft.db[draft.editor.journal_id];

  const current_question_id = draft.editor.question_id;

  // "flatten" questions - pull out of prompts and into one list
  let questions = [];
  prompts.forEach(prompt => {
    prompt.questions.forEach(q => {
      questions.push(q);
    });
  });

  // Determine the current questions index in questions list
  let current_index;
  questions.forEach((q, idx) => {
    if (q.question_id === current_question_id) {
      current_index = idx;
    }
  });

  // Assign next/prev ids based on position!

  // If this path only has one question, we can stop early
  if (questions.length === 1) {
    draft.editor.next_question = null;
    draft.editor.previous_question = null;
  }

  // Look forward to find the next question
  draft.editor.next_question = null;
  for (let i = current_index + 1; i < questions.length; i++) {
    if (question_is_available(draft, questions[i])) {
      draft.editor.next_question = questions[i].question_id;
      break;
    }
  }

  // Look backwards to find the previous question
  draft.editor.previous_question = null;
  for (let i = current_index - 1; i >= 0; i--) {
    if (question_is_available(draft, questions[i])) {
      draft.editor.previous_question = questions[i].question_id;
      break;
    }
  }
}

// Shifts the editor to the next/previous question
function shift_question(draft, action) {
  const { direction } = action;

  if (direction === "next" && draft.editor.next_question) {
    draft.editor.question_id = draft.editor.next_question;
  } else if (direction === "previous" && draft.editor.previous_question) {
    draft.editor.question_id = draft.editor.previous_question;
  }

  determine_next_prev_questions(draft);
}

// determines whether a question is available to be answered in the editor
// returns true/false
function question_is_available(draft, question) {
  // Check for portal
  if (question.type === "portal") {
    return false;
  }

  // Evaluate prompt react check - status of reacts is stored in the entry
  // ASSUMPTION - this function is only being called while on the entry screen, thus entry.entry_id is set correctly
  if (draft.app.screen !== "entry") {
    throw new Error("question_is_available can only be called from the entry screen");
  }
  const entry = draft.db[draft.entry.entry_id];
  if (entry && entry.reacts[question.from_prompt_id] === "disable") {
    return false;
  }

  return true;
}

// finds first available question in a journal
function find_first_available_question(draft, journal_id) {
  // Get prompts from current pathdef
  const { prompts } = draft.db[journal_id];

  // "flatten" questions - pull out of prompts and into one list
  let questions = [];
  prompts.forEach(prompt => {
    prompt.questions.forEach(q => {
      questions.push(q);
    });
  });

  // find first available question
  for (let i = 0; i < questions.length; i++) {
    if (question_is_available(draft, questions[i])) {
      return questions[i].question_id;
    }
  }
}

// Smoothes out wrinkles between version upgrades
function upgrade_planner(draft) {
  // If on a non-existant screen, set to home
  if (
    ![
      "home",
      "affects",
      "dev",
      "journals",
      "entry",
      "history",
      "paths",
      "search",
      "creator",
      "designer",
    ].includes(draft.app.screen)
  ) {
    draft.app.screen = "home";

    // eslint-disable-next-line no-console
    console.warn(`WARN: started from an unrecognized screen`);
  }

  // update entries
  draft.entries
    .map(entry_id => {
      return draft.db[entry_id];
    })
    .forEach(entry => {
      if (!("reacts" in entry)) {
        entry = {
          ...entry,
          reacts: {},
        };
      }

      if (!("timestamp" in entry)) {
        entry.timestamp = entry.date;
        delete entry.date;
      }

      draft.db[entry.entry_id] = entry;
    });

  // Update non existant setting options
  Object.keys(app_init.settings).forEach(key => {
    if (!(key in draft.settings)) {
      draft.settings[key] = app_init.settings[key];
    }
  });

  // add accent color if its not there
  if (!draft.settings.accent_color) {
    draft.settings.accent_color = "#FDC7D7";
  }
}

// merges in a @@stats key from the API
// basically - take whichever is greater for each stat, remote or local
function merge_synced_stats(draft, new_stats) {
  // app opens
  if (new_stats.app_opens > draft.stats.app_opens) {
    draft.stats.app_opens = new_stats.app_opens;
  }

  draft.stats.journals = new_stats.journals;

  // Protection incase we add stats in the future and forget to
  // handle them in this function
  const handled_keys = ["app_opens", "journals"];
  Object.keys(new_stats).forEach(key => {
    if (!handled_keys.includes(key)) {
      throw new Error(
        `@@stats from API included key '${key}' which is not handled in merge_synced_stats`
      );
    }
  });
}

// adds synced items from an API call
function add_synced_items(draft, items) {
  items.forEach(item => {
    const { obj_status, obj_type, id } = item;

    // API remove object
    if (obj_status === "removed") {
      if (obj_type === "entry") {
        remove_entry(draft, item.id, false);
      } else if (obj_type === "affect_cat") {
        remove_affect_category(draft, { category_id: id }, false);
      } else if (obj_type === "feeling") {
        remove_feeling(draft, { feeling_id: id }, false);
      } else if (["list_item", "schedule_row"].includes(obj_type)) {
        delete draft.db[id];
      } else if (obj_type === "named_key" && id.startsWith("@@draft:")) {
        const path_id = id.slice(8);
        delete draft.my_content.draft_paths[path_id];
        draft.my_content.draft_path_order = draft.my_content.draft_path_order.filter(
          pid => pid !== path_id
        );
      } else if (obj_type === null) {
        // TODO - this is a tricky case. The API is allowed to say that the obj_type is null for removed objs,
        // but really we shouldn't be in this situation. We need better reporting to explore + stop these cases.

        // For now we'll just get rid of the local obj
        delete draft.db[id];
      } else {
        throw new Error(
          `unrecognized obj_type to delete in sync from API: '${obj_type}'`
        );
      }
    }
    // API add object
    else {
      if (obj_type === "entry") {
        if (!draft.entries.includes(item.entry_id)) {
          draft.entries.push(item.entry_id);
        }

        draft.db[item.entry_id] = item;
      } else if (obj_type === "affect_cat") {
        if (!draft.affects.categories.includes(item.category_id)) {
          draft.affects.categories.push(item.category_id);
        }

        draft.db[item.category_id] = item;
      } else if (obj_type === "feeling") {
        if (!draft.affects.feelings.includes(item.feeling_id)) {
          draft.affects.feelings.push(item.feeling_id);
        }

        draft.db[item.feeling_id] = item;
      } else if (obj_type === "qops") {
        draft.qops_map[item.question_id] = item.qops_id;

        // add all options as indv objects
        // TODO - update qops to be a list of ids
        Object.keys(item.options).forEach(option_id => {
          draft.db[option_id] = item.options[option_id];
        });

        draft.db[item.qops_id] = item;
      } else if (["list_item", "schedule_row"].includes(obj_type)) {
        draft.db[item.item_id] = item;
      } else if (obj_type === "multi_option") {
        draft.db[item.option_id] = item;
      } else if (obj_type === "named_key") {
        // parse different named keys
        if (item.key_name === "@@inactive_ids") {
          draft.inactive_ids = item.data;
        } else if (item.key_name === "@@stats") {
          merge_synced_stats(draft, item.data);
        } else if (item.key_name === "@@journeys") {
          draft.journeys = item.data;
        } else if (item.key_name === "@@draft_path_order") {
          draft.my_content.draft_path_order = item.data;
        }
        // saves a draft path from the path designer
        else if (item.key_name.startsWith("@@draft:")) {
          const path_id = item.key_name.slice(8);
          draft.my_content.draft_paths[path_id] = item.data;

          // add to draft_path_order if not there
          if (!draft.my_content.draft_path_order.includes(path_id)) {
            draft.my_content.draft_path_order.push(path_id);
          }
        } else {
          throw new Error(
            `unrecognized named key from API during get_latest: ${item.key_name}`
          );
        }
      } else {
        throw new Error(`unrecognized obj_type in sync from API: '${obj_type}'`);
      }
    }
  });
}

// updates info after an account_sync_success
function on_sync_account(draft, data) {
  // update profile
  if (data.profile) {
    draft.profile = data.profile;
  }

  // update creator summaries
  if (data.creator_summaries && data.creator_summaries.length > 0) {
    data.creator_summaries.forEach(summary => {
      draft.db[`${summary.creator_id}:summary`] = summary;
    });
  }

  calc_path_comps(draft);
}

// handles setting active status for individual path/journal for a creator
// draft.inactive_ids is a map of inactive journals/paths
// no entry = active
// if value = true -> journal/path is inactive
function update_journal_active(draft, active, id) {
  if (active) {
    delete draft.inactive_ids[id];
  } else {
    draft.inactive_ids[id] = true;
  }

  queue_sync(draft, "@@inactive_ids", "update");
}

// when a creator's data is  loaded
function on_load_creator(draft, data) {
  draft.creator.loading = false;

  if (!data.summary || !data.page) {
    draft.errors.creator_page = "error_creator_not_found";
    return;
  }

  draft.db[`creator_data:${data.page.creator_id}`] = data;
  draft.errors.creator_page = null;
}

// A shortcut for "you click on a journal intending to make a new entry"
// Behavior varies whether that's a responsive, daily, or onboarding journal
//    Responsive -> Make a new entry
//    Daily -> Go to today's entry, or make a new one if it doesn't exist
//    Onboarding -> Go to last entry for onboarding, or make a new one if it doesn't exist
// TODO -- dynamically load old entries?
function set_entry_new(draft, journal_id) {
  // get journal def from db
  const journal = draft.db[journal_id];
  if (!journal) {
    // TODO - dynamically load it?
    throw new Error(`couldn't get journal def on set_entry_new`);
  }

  // responsives get a new entry
  if (journal.type === "responsive") {
    draft.entry.entry_id = null;
    draft.entry.journal_id = journal.journal_id;
  }
  // dailys will edit today's entry, if it exists. Otherwise new entry
  else if (journal.type === "daily") {
    // set the journal id
    draft.entry.journal_id = journal.journal_id;

    // check to see if there's an entry for today
    const entry = draft.entries
      .map(entry_id => {
        return draft.db[entry_id];
      })
      .filter(entry => {
        return entry.journal_id === journal.journal_id;
      })
      .find(entry => {
        return is_today(entry.timestamp);
      });

    draft.entry.entry_id = entry ? entry.entry_id : null;
  }
  // Onboarding will go back to the last entry if it exists. Otherwise new entry
  else if (journal.type === "onboarding") {
    // set the journal id
    draft.entry.journal_id = journal.journal_id;

    // check to see if there's an entry for this onboarding journal
    const entry = draft.entries
      .map(entry_id => {
        return draft.db[entry_id];
      })
      .find(entry => {
        return entry.journal_id === journal.journal_id;
      });

    draft.entry.entry_id = entry ? entry.entry_id : null;
  } else {
    throw new Error(`journal type not recognized in set_entry_new`);
  }
}

// runs after a creator subscribe, which happens as an ACCOUNT_CHANGE_SUCCESS
// with a action.change === subscribe_to_creator
function on_creator_subscribe(draft, creator_id) {
  // We delete any existing pathdefs out of memory (doesn't matter on first sub)
  // This is so that the asset_manager will purposefully reload them
  // This allows you to reload the path by unsubbing and resubbing

  // Get all paths for creator
  draft.db[`creator_data:${creator_id}`].summary.paths
    .map(path_summary => {
      return path_summary.path_id;
    })
    // Delete locally saved copy, if it's there
    .forEach(path_id => {
      delete draft.db[path_id];
    });
}

// makes new data structures for state.stats (below function)
function stats_new(type) {
  switch (type) {
    case "journal":
      return {
        opens: 0,
        entries: 0,
      };
    default:
      throw new Error(`unrecognized type in stats_new: '${type}'`);
  }
}

// updates app stats
function update_stats(draft, type, data = null) {
  // Make local copy of stats
  let stats = JSON.parse(JSON.stringify(draft.stats));

  switch (type) {
    case "app_open":
      stats.app_opens += 1;
      break;
    case "journal_open":
      // data === journal_id
      if (!stats.journals[data]) {
        stats.journals[data] = stats_new("journal");
      }

      stats.journals[data].opens += 1;
      break;
    case "new_entry":
      // data === journal_id
      if (!stats.journals[data]) {
        stats.journals[data] = stats_new("journal");
      }

      stats.journals[data].entries += 1;
      break;
    case "remove_entry":
      // data === journal_id
      if (!stats.journals[data]) {
        // this case _shouldn't_ happen, but it's not worth throwing an error
        // todo -- error report

        stats.journals[data] = stats_new("journal");
        stats.journals[data].entries = 0;
      } else {
        stats.journals[data].entries -= 1;
      }

      break;
    default:
      throw new Error(`unrecognized type in update_stats: '${type}'`);
  }

  // Save
  draft.stats = stats;

  // note -- although its put in the sync queue, it won't be uploaded immediately.
  // stats lazy syncs, and waits for something else to be synced first (since this is a low priority sync)
  queue_sync(draft, "@@stats", "update");
}

function on_creator_search(draft, action) {
  const { search_type, data } = action;

  if (search_type === "list") {
    let creators_list = [];
    data.creators.forEach(summary => {
      // save id to sync to state.search.home_creators_list
      creators_list.push(summary.creator_id);

      // save summary to db
      draft.db[`${summary.creator_id}:summary`] = summary;
    });

    draft.search.home_creators_list = creators_list;
  } else {
    throw new Error(`unrecognized search_type in on_creator_search`);
  }
}

// --- path designer functions ---

// creates a new path from inside the path designer
function on_new_path(draft) {
  const path = new_path();

  draft.my_content.draft_paths[path.path_id] = path;
  draft.my_content.draft_path_order.push(path.path_id);

  queue_sync(draft, `@@draft:${path.path_id}`, "update");
  queue_sync(draft, "@@draft_path_order", "update");
}

function on_remove_draft_path(draft, action) {
  const { path_id } = action;

  delete draft.my_content.draft_paths[path_id];

  draft.my_content.draft_path_order = draft.my_content.draft_path_order.filter(id => {
    return id !== path_id;
  });

  queue_sync(draft, `@@draft:${path_id}`, "remove");
  queue_sync(draft, "@@draft_path_order", "update");
}

function on_reorder_draft_paths(draft, action) {
  const { moved_path_id, from_index, to_index } = action;

  draft.my_content.draft_path_order.splice(from_index, 1);
  draft.my_content.draft_path_order.splice(to_index, 0, moved_path_id);

  queue_sync(draft, "@@draft_path_order", "update");
}

function on_new_journal(draft, action) {
  const { path_id } = action;

  const journal = new_journal();

  draft.my_content.draft_paths[path_id].journals.push(journal);

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_remove_draft_journal(draft, action) {
  const { path_id, journal_id } = action;

  const path = draft.my_content.draft_paths[path_id];

  path.journals = path.journals.filter(journal => {
    return journal.journal_id !== journal_id;
  });

  // if deleting a selected journal, reset selected_journal to null
  if (draft.designer.selected_journal === journal_id) {
    draft.designer.selected_journal = null;
  }

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_reorder_draft_journals(draft, action) {
  const { path_id, from_index, to_index } = action;

  const path = draft.my_content.draft_paths[path_id];

  const journal = path.journals.splice(from_index, 1)[0];
  path.journals.splice(to_index, 0, journal);

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_new_prompt(draft, action) {
  const { path_id, journal_id } = action;

  const prompt = new_prompt();

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);

  journal.prompts.push(prompt);

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_remove_draft_prompt(draft, action) {
  const { path_id, journal_id, prompt_id } = action;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);

  // if a question in this prompt is selected, reset view
  try {
    const prompt = journal.prompts.find(prompt => prompt.prompt_id === prompt_id);
    if (
      draft.designer.selected_question &&
      prompt.questions.find(
        question => draft.designer.selected_question === question.question_id
      )
    ) {
      on_designer_breadcrumb(draft, { level: "journal" });
    }
  } catch (err) {}

  journal.prompts = journal.prompts.filter(prompt => {
    return prompt.prompt_id !== prompt_id;
  });

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

// handles drag and drop for reordering prompts and questions
// one function handles both since they're inside the same DragDropContext
// This is because you can move questions from one prompt to another
function on_reorder_draft_internal_journal(draft, action) {
  const { path_id, journal_id, result } = action;
  const { type, source, destination } = result;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);

  if (type === "prompt") {
    const prompt = journal.prompts.splice(source.index, 1)[0];
    journal.prompts.splice(destination.index, 0, prompt);
  } else if (type === "question") {
    const from_prompt = journal.prompts.find(
      prompt => prompt.prompt_id === source.droppableId
    );
    const to_prompt = journal.prompts.find(
      prompt => prompt.prompt_id === destination.droppableId
    );

    const question = from_prompt.questions.splice(source.index, 1)[0];
    to_prompt.questions.splice(destination.index, 0, question);
  } else {
    throw new Error(
      `unhandled drag+drop action in JournalCol. Type: ${type}. Result: ${JSON.stringify(
        result
      )}`
    );
  }

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_new_question(draft, action) {
  const { path_id, journal_id, prompt_id } = action;

  const question = new_question();

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);
  const prompt = journal.prompts.find(prompt => prompt.prompt_id === prompt_id);

  prompt.questions.push(question);

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_remove_draft_question(draft, action) {
  const { path_id, journal_id, prompt_id, question_id } = action;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);
  const prompt = journal.prompts.find(prompt => prompt.prompt_id === prompt_id);

  prompt.questions = prompt.questions.filter(question => {
    return question.question_id !== question_id;
  });

  if (draft.designer.selected_question === question_id) {
    on_designer_breadcrumb(draft, { level: "journal" });
  }

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_update_draft_path_info(draft, action) {
  const { path_id, info } = action;

  const path = draft.my_content.draft_paths[path_id];

  Object.keys(info).forEach(key => {
    path[key] = info[key];
  });

  draft.my_content.draft_paths[path_id] = path;

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_update_draft_journal_info(draft, action) {
  const { path_id, journal_id, info } = action;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => {
    return journal.journal_id === journal_id;
  });

  Object.keys(info).forEach(key => {
    journal[key] = info[key];
  });

  path.journals = path.journals.map(j => {
    if (j.journal_id === journal_id) {
      return journal;
    }
    return j;
  });

  draft.my_content.draft_paths[path_id] = path;

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_update_draft_prompt_info(draft, action) {
  const { path_id, journal_id, prompt_id, info } = action;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);
  const prompt = journal.prompts.find(prompt => prompt.prompt_id === prompt_id);

  Object.keys(info).forEach(key => {
    prompt[key] = info[key];
  });

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_update_draft_question_info(draft, action) {
  const { path_id, journal_id, prompt_id, question_id, info } = action;

  const path = draft.my_content.draft_paths[path_id];
  const journal = path.journals.find(journal => journal.journal_id === journal_id);
  const prompt = journal.prompts.find(prompt => prompt.prompt_id === prompt_id);
  const question = prompt.questions.find(
    question => question.question_id === question_id
  );

  // if the type is changed, reset all the special keys
  if (info.type) {
    delete question.style;
    delete question.options_source;
    delete question.static_options;
    delete question.available_times;
    delete question.ref_question_id;
    delete question.scale;
  }

  Object.keys(info).forEach(key => {
    question[key] = info[key];
  });

  queue_sync(draft, `@@draft:${path_id}`, "update");
}

function on_designer_breadcrumb(draft, action) {
  const { level } = action;

  switch (level) {
    case "question":
      draft.designer.show_context = false;
      break;

    case "journal":
      draft.designer.show_context = false;
      draft.designer.selected_question = null;
      break;

    case "path":
      draft.designer.show_context = false;
      draft.designer.selected_question = null;
      draft.designer.selected_journal = null;
      break;

    case "home":
      draft.designer.show_context = false;
      draft.designer.selected_question = null;
      draft.designer.selected_journal = null;
      draft.designer.selected_path = null;
      break;

    default:
      break;
  }
}

// on clicking the left arrow / "go back" icon on the divider between columns
// it "collapses" or "goes back" one
function on_designer_collapse_columns(draft) {
  const { designer } = draft;

  if (designer.show_context) {
    designer.show_context = false;
  } else if (designer.selected_question) {
    designer.selected_question = null;
  } else if (designer.selected_journal) {
    designer.selected_journal = null;
  } else if (designer.selected_path) {
    designer.selected_path = null;
  }
}

// restarts a journey
function restart_journey(draft, action) {
  const { path_id, remove_entries } = action;

  const path = draft.db[path_id];
  const { journey } = path;

  if (remove_entries) {
    const entries = get_path_entries(draft, path_id);
    entries.forEach(entry => {
      remove_entry(draft, entry.entry_id, true);
    });
  }

  // reset journey to initial stage
  draft.journeys[path_id].stage = journey.initial_stage;
  draft.journeys[path_id].prev_stage = journey.initial_stage;

  update_journey_status(draft, path_id);
}

function reducer(state = app_init, action) {
  if (action.type === "persist/PURGE") {
    return app_init;
  }

  return produce(state, draft => {
    switch (action.type) {
      case AT.APP_OPEN:
        draft.app.show_settings = false;
        draft.entry.show_share_modal = false;
        draft.editor.show = false;
        draft.entry.show_side_context = false;
        draft.entry.show_stage_complete = false;
        draft.search.mode = "start";
        upgrade_planner(draft);
        calc_path_comps(draft);
        update_stats(draft, "app_open");
        break;

      case AT.CHANGE_SCREEN:
        draft.app.screen = action.screen;

        // Close editor if it was shown
        draft.editor.show = false;

        // Close non entry-screen things
        if (draft.app.screen !== "entry") {
          draft.entry.show_side_context = false;
          draft.entry.show_stage_complete = false;
        }

        // reset designer when entering
        if (draft.app.screen === "designer") {
          draft.designer = app_init.designer;
        }

        break;

      case AT.LOAD_PATHDEF_SUCCESS:
        load_paths(draft, action.paths);
        break;

      case AT.SHOW_DATA_EDITOR:
        draft.editor.show = true;
        draft.editor.expanded = draft.settings.default_editor_height === "expanded";
        break;

      case AT.CLOSE_DATA_EDITOR:
        draft.editor.show = false;
        break;

      case AT.TOGGLE_EXPAND_EDITOR:
        draft.editor.expanded = !draft.editor.expanded;
        break;

      case AT.SET_DATA_EDITOR:
        // Allow for parameters to be optional by defaulting to current value
        draft.editor.journal_id = action.journal_id
          ? action.journal_id
          : draft.editor.journal_id;
        draft.editor.question_id = action.question_id
          ? action.question_id
          : draft.editor.question_id;

        draft.editor.mode = "create";
        determine_next_prev_questions(draft);
        draft.editor.text_entry_focus = false;
        break;

      // sets data editor to first available question
      case AT.SET_DATA_EDITOR_FIRST:
        draft.editor.journal_id = action.journal_id;
        draft.editor.question_id = find_first_available_question(
          draft,
          action.journal_id
        );

        draft.editor.mode = "create";
        determine_next_prev_questions(draft);
        draft.editor.text_entry_focus = false;
        break;

      // Shifts the editor to the next/previous question
      case AT.SHIFT_EDITOR_QUESTION:
        shift_question(draft, action);
        draft.editor.text_entry_focus = false;
        break;

      case AT.UPDATE_QUESTION_OPS:
        update_qops(draft, action);
        break;

      case AT.DB_UPDATE:
        action.objects.forEach(obj => {
          draft.db[obj.id] = obj.obj;
        });
        break;

      case AT.ENTRY_UPDATE:
        update_entry(draft, action);
        break;

      case AT.ENTRY_REMOVE:
        remove_entry(draft, action.entry_id);
        break;

      case AT.SET_EDITOR_MODE:
        draft.editor.mode = action.mode;
        break;

      case AT.TEXT_ENTRY_FOCUS:
        draft.editor.text_entry_focus = action.focus;
        break;

      case AT.NEW_AFFECT_CATEGORY:
        add_new_affect_category(draft);
        break;

      case AT.UPDATE_AFFECT_CATEGORY:
        update_affect_category(draft, action);
        break;

      case AT.REMOVE_AFFECT_CATEGORY:
        remove_affect_category(draft, action);
        break;

      case AT.NEW_FEELING:
        add_new_feeling(draft);
        break;

      case AT.UPDATE_FEELING:
        update_feeling(draft, action);
        break;

      case AT.REMOVE_FEELING:
        remove_feeling(draft, action);
        break;

      case AT.DEV_ACTION:
        dev_action(draft, action);
        break;

      case AT.SETTINGS_SHOW:
        draft.app.show_settings = true;
        break;

      case AT.SETTINGS_CLOSE:
        draft.app.show_settings = false;
        break;

      case AT.SETTINGS_CHANGE:
        change_setting(draft, action);
        break;

      case AT.SET_JOURNALS_SCREEN_TYPE:
        draft.journals.type = action.value;
        break;

      case AT.SET_ENTRY_SPECIFIC:
        draft.entry.entry_id = action.entry_id;
        draft.entry.journal_id = action.journal_id;
        draft.entry.show_share_modal = false;
        update_stats(draft, "journal_open", action.journal_id);
        break;

      case AT.SET_ENTRY_NEW:
        set_entry_new(draft, action.journal_id);
        draft.entry.show_share_modal = false;
        update_stats(draft, "journal_open", action.journal_id);
        break;

      case AT.TOGGLE_ENTRY_SHARE_MODAL:
        draft.entry.show_share_modal = !draft.entry.show_share_modal;
        break;

      case AT.SOS:
        draft.app = app_init.app;
        draft.editor = app_init.editor;
        draft.entry = app_init.entry;
        draft.journals = app_init.journals;
        draft.sync.queue = app_init.sync.queue;
        draft.sync.last_sync = app_init.sync.last_sync;
        draft.search = app_init.search;
        draft.creator = app_init.creator;
        draft.errors = app_init.errors;
        draft.designer = app_init.designer;
        calc_path_comps();
        break;

      case AT.TOGGLE_QUICK_BUTTONS:
        draft.app.show_quick_buttons = !draft.app.show_quick_buttons;
        break;

      case AT.AUTH_UPDATE:
        Object.keys(action.update).forEach(key => {
          draft.auth[key] = action.update[key];
        });
        break;

      case AT.SYNC_LATEST:
        draft.sync.syncing = true;
        break;

      case AT.SYNC_SUCCESS:
        draft.sync.queue = draft.sync.queue.filter(sync => {
          return !action.ids.includes(sync.id);
        });
        break;

      case AT.SYNC_LATEST_SUCCESS:
        add_synced_items(draft, action.items);
        draft.sync.last_sync = new Date().getTime();
        draft.sync.syncing = false;
        break;

      case AT.SYNC_ACCOUNT:
        draft.loading.sync_account = true;
        break;

      case AT.SYNC_ACCOUNT_SUCCESS:
        on_sync_account(draft, action.data);
        draft.loading.sync_account = false;
        break;

      case AT.SET_JOURNAL_ACTIVE:
        update_journal_active(draft, action.active, action.id);
        break;

      case AT.SEARCH_SET_MODE:
        draft.search.mode = action.mode;
        break;

      case AT.SET_CREATOR_ID:
        draft.creator.id = action.id;
        break;

      case AT.LOAD_CREATOR_SUCCESS:
        on_load_creator(draft, action.data);
        draft.loading.sub_unsub_creator = false;
        break;

      case AT.SET_ERROR:
        draft.errors[action.key] = action.message;
        break;

      case AT.ACCOUNT_CHANGE:
        if (
          ["subscribe_to_creator", "unsubscribe_from_creator"].includes(action.change)
        ) {
          draft.loading.sub_unsub_creator = true;
        }
        break;

      case AT.ACCOUNT_CHANGE_SUCCESS:
        if (
          ["subscribe_to_creator", "unsubscribe_from_creator"].includes(action.change)
        ) {
          draft.loading.sub_unsub_creator = false;

          // assumes a sync_account event will be sent right after this one
          // keeps the loading indicator while we're syncing the account
          draft.loading.sync_account = true;
        }
        if (action.change === "subscribe_to_creator") {
          on_creator_subscribe(draft, action.data.creator_id);
        }
        break;

      case AT.SET_LOADING_FLAG:
        draft.loading[action.key] = action.value;
        break;

      case AT.SHARE_ENTRY:
      case AT.REVOKE_ENTRY_SHARING:
        draft.loading.entry_sharing = true;
        break;

      case AT.ENTRY_SHARING_UPDATED:
        on_entry_sharing_updated(draft, action);
        draft.loading.entry_sharing = false;
        break;

      case AT.TOGGLE_SIDE_CONTEXT:
        draft.entry.show_side_context = !draft.entry.show_side_context;
        break;

      case AT.TOGGLE_EXPAND_SIDE_CONTEXT:
        if (action.show !== null) {
          draft.entry.side_context_expanded = action.show;
        } else {
          draft.entry.side_context_expanded = !draft.entry.side_context_expanded;
        }
        break;

      case AT.SEARCH_CREATORS:
        draft.loading.search_creators = true;
        break;

      case AT.SEARCH_CREATORS_SUCCESS:
        draft.loading.search_creators = false;
        on_creator_search(draft, action);
        break;

      case AT.SHOW_STAGE_COMPLETE:
        draft.entry.show_stage_complete = action.show;
        break;

      case AT.DESIGNER_NEW_PATH:
        on_new_path(draft);
        break;

      case AT.REMOVE_DRAFT_PATH:
        on_remove_draft_path(draft, action);
        if (draft.designer.path_manager_path_id === action.path_id) {
          draft.designer.path_manager_path_id = null;
        }
        break;

      case AT.REORDER_DRAFT_PATHS:
        on_reorder_draft_paths(draft, action);
        break;

      case AT.SET_SELECTED_PATH:
        draft.designer.selected_path = action.path_id;
        draft.designer.show_context = false;
        draft.designer.path_manager_path_id = null;
        break;

      case AT.DESIGNER_NEW_JOURNAL:
        on_new_journal(draft, action);
        break;

      case AT.REMOVE_DRAFT_JOURNAL:
        on_remove_draft_journal(draft, action);
        break;

      case AT.REORDER_DRAFT_JOURNALS:
        on_reorder_draft_journals(draft, action);
        break;

      case AT.SET_SELECTED_JOURNAL:
        if (draft.designer.selected_journal === action.journal_id) {
          draft.designer.selected_journal = null;
        } else {
          draft.designer.selected_journal = action.journal_id;
        }
        draft.designer.show_context = false;
        break;

      case AT.DESIGNER_NEW_PROMPT:
        on_new_prompt(draft, action);
        break;

      case AT.REMOVE_DRAFT_PROMPT:
        on_remove_draft_prompt(draft, action);
        break;

      case AT.REORDER_DRAFT_INTERNAL_JOURNAL:
        on_reorder_draft_internal_journal(draft, action);
        break;

      case AT.DESIGNER_NEW_QUESTION:
        on_new_question(draft, action);
        break;

      case AT.REMOVE_DRAFT_QUESTION:
        on_remove_draft_question(draft, action);
        break;

      case AT.SET_SELECTED_QUESTION:
        if (draft.designer.selected_question === action.question_id) {
          draft.designer.selected_question = null;
        } else {
          draft.designer.selected_question = action.question_id;
        }
        draft.designer.show_context = false;

        break;

      case AT.UPDATE_DRAFT_PATH_INFO:
        on_update_draft_path_info(draft, action);
        break;

      case AT.UPDATE_DRAFT_JOURNAL_INFO:
        on_update_draft_journal_info(draft, action);
        break;

      case AT.UPDATE_DRAFT_PROMPT_INFO:
        on_update_draft_prompt_info(draft, action);
        break;

      case AT.UPDATE_DRAFT_QUESTION_INFO:
        on_update_draft_question_info(draft, action);
        break;

      case AT.DESIGNER_COLLAPSE_COLUMNS:
        on_designer_collapse_columns(draft);
        break;

      case AT.DESIGNER_BREADCRUMB:
        on_designer_breadcrumb(draft, action);
        break;

      case AT.DESIGNER_TOGGLE_CONTEXT:
        draft.designer.show_context = !draft.designer.show_context;
        break;

      case AT.DESIGNER_SET_PATH_MANAGER_ID:
        if (draft.designer.path_manager_path_id === action.path_id) {
          draft.designer.path_manager_path_id = null;
        } else {
          draft.designer.path_manager_path_id = action.path_id;
        }
        draft.errors.publish_path = false;
        draft.loading.designer_publish_path = false;
        break;

      case AT.DESIGNER_PUBLISH_PATH:
        draft.loading.designer_publish_path = true;
        draft.errors.publish_path = false;
        break;

      case AT.DESIGNER_PUBLISH_PATH_SUCCESS:
        draft.loading.designer_publish_path = false;
        break;

      case AT.DESIGNER_PUBLISH_PATH_ERROR:
        draft.errors.publish_path = true;
        break;

      case AT.RESTART_JOURNEY:
        restart_journey(draft, action);
        break;

      default:
        break;
    }
  });
}

// create persisted store and export

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: [
      "app",
      "qops_map",
      "db",
      "entries",
      "affects",
      "settings",
      "journals",
      "entry",
      "editor",
      "sync",
      "profile",
      "inactive_ids",
      "creator",
      "stats",
      "search",
      "journeys",
      "my_content",
      "designer",
    ],
  },
  reducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

// start sagas
const { sagas } = require("./sagas");
sagaMiddleware.run(sagas);

export { store, persistor, app_init, evaluate_criteria, find_first_available_question };
