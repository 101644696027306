import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { cstyles, ss } from "../common";
import {} from "../getters";
import { mark_todo } from "../actions";

import Hover from "../wrappers/Hover";

const checked_icon = require("../icons/checkbox_checked.png");
const not_checked_icon = require("../icons/checkbox_not_checked.png");

const styles = {
  container: {
    maxWidth: 180,
  },
  long_name: {
    minWidth: 175,
  },
  checkbox: {
    margin: 2,
    marginLeft: -2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 13,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 2,
    paddingRight: 6,
  },
  checked: {
    textDecoration: "line-through",
    opacity: 0.6,
  },
  checkbox_icon: {
    width: 18,
    height: 18,
    marginRight: 8,
  },
  quick: {
    backgroundColor: cstyles.raised(1),
  },
};

class Button extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    update_entry: PropTypes.func.isRequired,
    entry_id: PropTypes.string,
    question_id: PropTypes.string.isRequired,
    quick: PropTypes.bool, // quick buttons styling
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  todo_click() {
    const { item } = this.props;

    this.props.update_entry(
      this.props.entry_id,
      this.props.question_id,
      "list",
      mark_todo(!item.completed, item)
    );
  }

  render_todo() {
    const { item } = this.props;
    const icon = item.completed ? checked_icon : not_checked_icon;
    let style = ss(item.completed, styles.checkbox, styles.checked);
    style = ss(this.props.quick, style, styles.quick);
    return (
      <Hover style={style} action={() => this.todo_click()}>
        <img alt={"checkbox"} src={icon} style={styles.checkbox_icon} />
        <div>{item.name}</div>
      </Hover>
    );
  }

  render_item() {
    const { style } = this.props.item;

    if (style === "todo") {
      return this.render_todo();
    } else {
      throw new Error(`button: item style ${style} unrecognized`);
    }
  }

  render() {
    const style = ss(
      this.props.item.name.length >= 25,
      styles.container,
      styles.long_name
    );

    return <div style={style}>{this.render_item()}</div>;
  }
}

function state_to_props(state, ownProps) {
  const item = state.db[ownProps.id];

  if (item.style === "todo" && !ownProps.entry_id) {
    throw new Error(
      `todo button for id ${JSON.stringify(item)} was not given an entry_id`
    );
  }

  return {
    item,
  };
}
export default connect(state_to_props, { update_entry })(Button);
