import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry, text_entry_focus } from "../redux/actions";

import { cstyles } from "../common";

import DelayInput from "../compkit/DelayInput";
import HoverIcon from "../wrappers/HoverIcon";

import Select from "react-select";

const remove_icon = require("../icons/delete.png");

const match_24hr = new RegExp(/^(\d{4}|\d{2}:\d{2}) ?- ?(\d{4}|\d{2}:\d{2})$/);
const match_12hr = new RegExp(
  /^(\d{1,2}:\d{2}(pm|am)|\d{1,2}(pm|am)) ?- ?(\d{1,2}:\d{2}(pm|am)|\d{1,2}(pm|am))$/
);

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  row_item: {
    display: "flex",
    alignItems: "center",
  },
};

class TSLRow extends Component {
  static propTypes = {
    item_id: PropTypes.string.isRequired,
    update_entry: PropTypes.func.isRequired,
    text_entry_focus: PropTypes.func.isRequired,
    entry: PropTypes.object,
    question_id: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  on_update(key, value) {
    const { item } = this.props;
    let updated_item = {
      ...item,
    };
    updated_item[key] = value;

    // Calculate length
    if (key === "time") {
      updated_item.length = this.get_time_length(value);
    }

    this.props.update_entry(
      this.props.entry ? this.props.entry.entry_id : null,
      this.props.question_id,
      "table_schedule_list",
      {
        action: "update",
        item: updated_item,
      }
    );
  }

  time_validator(time) {
    // validates that  time is formatted acceptably
    if (match_24hr.test(time)) {
      return true;
    }
    if (match_12hr.test(time)) {
      return true;
    }

    return false;
  }

  // interprets a time (eg 1000, 7pm, 14:15, 2:11pm)
  interpret_time(hr24, time) {
    let hour, minute;

    if (hr24) {
      if (time.includes(":")) {
        [hour, minute] = time.split(":");
      } else {
        hour = time.substring(0, 2);
        minute = time.substring(2, 4);
      }
    } else {
      let afternoon = false;
      if (time.includes("pm")) {
        afternoon = true;
      }

      // get rid of am/pm
      time = time.substring(0, time.length - 2);
      if (time.includes(":")) {
        [hour, minute] = time.split(":");
      } else {
        hour = time;
        minute = 0;
      }

      if (afternoon) {
        hour = parseInt(hour) + 12;
      }
    }

    return new Date(2000, 0, 1, hour, minute);
  }

  get_time_length(time) {
    try {
      const hr24 = match_24hr.test(time);

      // Parse times
      let [start, end] = time.split("-");
      const start_date = this.interpret_time(hr24, start.trim());
      const end_date = this.interpret_time(hr24, end.trim());
      return ((end_date - start_date) / (1000 * 60 * 60)).toFixed(1);
    } catch (err) {
      return 0;
    }
  }

  on_text_entry_focus(focus) {
    this.props.text_entry_focus(focus);
  }

  // removes row
  on_remove() {
    this.props.update_entry(
      this.props.entry ? this.props.entry.entry_id : null,
      this.props.question_id,
      "table_schedule_list",
      {
        action: "remove",
        item_id: this.props.item_id,
      }
    );
  }

  render() {
    const { item } = this.props;

    const default_value = this.props.options.filter(op => {
      return item.items.includes(op.value);
    });

    const display_options = this.props.options.filter(op => {
      return !op.assigned;
    });

    const bold = ["free time", ""].includes(item.name.trim().toLowerCase());

    const name_placeholder = item.time.length > 0 ? "free time" : "name";

    return (
      <div style={styles.row}>
        <div style={{ ...styles.row_item, minWidth: 200 }}>
          <DelayInput
            placeholder={name_placeholder}
            action={text => this.on_update("name", text)}
            start={item.name}
            on_focus={focus => this.on_text_entry_focus(focus)}
            bold={bold}
          />
        </div>
        <div style={{ ...styles.row_item, minWidth: 160 }}>
          <DelayInput
            placeholder={"time"}
            action={text => this.on_update("time", text)}
            start={item.time}
            validator={this.time_validator}
            on_focus={focus => this.on_text_entry_focus(focus)}
            bold={bold}
            width={130}
          />
        </div>
        <div
          style={{
            ...styles.row_item,
            minWidth: 100,
            fontWeight: bold ? "bold" : "normal",
          }}
        >
          {item.length ? item.length + " hrs" : ""}
        </div>
        <div style={{ ...styles.row_item, minWidth: 300 }}>
          <Select
            styles={cstyles.react_select_style}
            options={display_options}
            placeholder={"Select items"}
            isMulti
            menuPlacement={"bottom"}
            defaultValue={default_value}
            onChange={value =>
              this.on_update("items", value ? value.map(item => item.value) : [])
            }
          />
        </div>
        <div style={styles.row_item}>
          <HoverIcon
            alt={"remove"}
            icon={remove_icon}
            action={() => this.on_remove()}
            icon_size={14}
          />
        </div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  let entry = null;
  if (state.entry.entry_id) {
    entry = state.db[state.entry.entry_id];
  }

  return {
    question_id: state.editor.question_id,
    entry,
    item: state.db[ownProps.item_id],
  };
}
export default connect(state_to_props, { update_entry, text_entry_focus })(TSLRow);
