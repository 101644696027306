import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_setting } from "../redux/actions";

import {} from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

import check from "../icons/done.png";

const styles = {
  splatch: {
    borderRadius: 10,
    height: 45,
    width: 45,
    margin: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  color_row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  check: {
    width: 24,
    height: 24,
  },
};

class ColorPicker extends Component {
  static propTypes = {
    change_setting: PropTypes.func.isRequired,
    selected_accent: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_color_splatch(color) {
    return (
      <Hover
        key={`col:${color}`}
        style={{ ...styles.splatch, backgroundColor: color }}
        action={() => {
          this.props.change_setting("accent_color", color);
        }}
      >
        {this.props.selected_accent === color ? (
          <img src={check} alt={"check"} style={styles.check} />
        ) : null}
      </Hover>
    );
  }

  render() {
    return (
      <div>
        <div style={styles.color_row}>
          {this.render_color_splatch("#FDC7D7")}
          {this.render_color_splatch("#FF9DE6")}
          {this.render_color_splatch("#FE53BB")}
          {this.render_color_splatch("#FEC763")}
          {this.render_color_splatch("#FF8B8B")}
        </div>
        <div style={styles.color_row}>
          {this.render_color_splatch("#E8E500")}
          {this.render_color_splatch("#F5D300")}
          {this.render_color_splatch("#FF9472")}
          {this.render_color_splatch("#F85125")}
          {this.render_color_splatch("#E9275B")}
        </div>
        <div style={styles.color_row}>
          {this.render_color_splatch("#A5D8F3")}
          {this.render_color_splatch("#08F7FE")}
          {this.render_color_splatch("#13CA91")}
          {this.render_color_splatch("#7B61F8")}
          {this.render_color_splatch("#FEA0FE")}
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    selected_accent: state.settings.accent_color,
  };
}
export default connect(state_to_props, { change_setting })(ColorPicker);
