import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { dev_action } from "../redux/actions";

const matrixImg = require("./matrix.jpg");

const styles = {
  page: {
    width: "80vw",
  },
  bgImage: {
    filter: "saturate(25%)",
    width: "100%",
    height: "auto",
    position: "absolute",
    top: 0,
    opacity: 0.35,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: "rgba(225, 225, 225, .9)",
    cursor: "pointer",
  },
  content_container: {
    color: "black",
    margin: 10,
    position: "absolute",
    top: 0,
    width: "50%",
    height: "min-content",
    zIndex: 1,
  },
};

class DevScreen extends Component {
  static propTypes = {
    dev_action: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  delete_local_storage() {
    localStorage.removeItem("persist:root");
    window.location.reload(false);
  }

  render() {
    return (
      <div style={styles.page}>
        <div style={styles.content_container}>
          <div style={{ height: 60 }} />
          <div
            style={styles.row}
            onClick={() => {
              this.props.dev_action("clear_entries");
            }}
          >
            Clear all entries
          </div>
          <div
            style={styles.row}
            onClick={() => {
              this.props.dev_action("add_random_entries");
            }}
          >
            Add random entries
          </div>
          <div
            style={styles.row}
            onClick={() => {
              this.props.dev_action("set_all_entries_back_one_day");
            }}
          >
            Set the date on all entries back by 1 day
          </div>
          <div
            style={styles.row}
            onClick={() => {
              this.delete_local_storage();
            }}
          >
            Clear local storage
          </div>
        </div>
        <img src={matrixImg} style={styles.bgImage} alt={"matrix"} />
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, { dev_action })(DevScreen);
