const APP_OPEN = "APP_OPEN";
const CHANGE_SCREEN = "CHANGE_SCREEN";
const LOAD_PATHDEF_SUCCESS = "LOAD_PATHDEF_SUCCESS";
const SHOW_DATA_EDITOR = "SHOW_DATA_EDITOR";
const CLOSE_DATA_EDITOR = "CLOSE_DATA_EDITOR";
const TOGGLE_EXPAND_EDITOR = "TOGGLE_EXPAND_EDITOR";
const SET_DATA_EDITOR = "SET_DATA_EDITOR";
const SET_DATA_EDITOR_FIRST = "SET_DATA_EDITOR_FIRST";
const SHIFT_EDITOR_QUESTION = "SHIFT_EDITOR_QUESTION";
const SET_EDITOR_MODE = "SET_EDITOR_MODE";
const TEXT_ENTRY_FOCUS = "TEXT_ENTRY_FOCUS";
const UPDATE_QUESTION_OPS = "UPDATE_QUESTION_OPS";
const DB_UPDATE = "DB_UPDATE";
const ENTRY_UPDATE = "ENTRY_UPDATE";
const ENTRY_REMOVE = "ENTRY_REMOVE";
const NEW_AFFECT_CATEGORY = "NEW_AFFECT_CATEGORY";
const UPDATE_AFFECT_CATEGORY = "UPDATE_AFFECT_CATEGORY";
const REMOVE_AFFECT_CATEGORY = "REMOVE_AFFECT_CATEGORY";
const NEW_FEELING = "NEW_FEELING";
const UPDATE_FEELING = "UPDATE_FEELING";
const REMOVE_FEELING = "REMOVE_FEELING";
const DEV_ACTION = "DEV_ACTION";
const SETTINGS_SHOW = "SETTINGS_SHOW";
const SETTINGS_CLOSE = "SETTINGS_CLOSE";
const SETTINGS_CHANGE = "SETTINGS_CHANGE";
const SET_JOURNALS_SCREEN_TYPE = "SET_JOURNALS_SCREEN_TYPE";
const SET_ENTRY_SPECIFIC = "SET_ENTRY_SPECIFIC";
const SET_ENTRY_NEW = "SET_ENTRY_NEW";
const SOS = "SOS";
const TOGGLE_QUICK_BUTTONS = "TOGGLE_QUICK_BUTTONS";
const AUTH_UPDATE = "AUTH_UPDATE";
const SYNC_SUCCESS = "SYNC_SUCCESS";
const SYNC_LATEST = "SYNC_LATEST";
const SYNC_LATEST_SUCCESS = "SYNC_LATEST_SUCCESS";
const SYNC_ACCOUNT = "SYNC_ACCOUNT";
const SYNC_ACCOUNT_SUCCESS = "SYNC_ACCOUNT_SUCCESS";
const SET_JOURNAL_ACTIVE = "SET_JOURNAL_ACTIVE";
const SEARCH_SET_MODE = "SEARCH_SET_MODE";
const SET_CREATOR_ID = "SET_CREATOR_ID";
const LOAD_CREATOR_BY_ID = "LOAD_CREATOR_BY_ID";
const LOAD_CREATOR_SUCCESS = "LOAD_CREATOR_SUCCESS";
const SET_ERROR = "SET_ERROR";
const ACCOUNT_CHANGE = "ACCOUNT_CHANGE";
const ACCOUNT_CHANGE_SUCCESS = "ACCOUNT_CHANGE_SUCCESS";
const TOGGLE_ENTRY_SHARE_MODAL = "TOGGLE_ENTRY_SHARE_MODAL";
const SHARE_ENTRY = "SHARE_ENTRY";
const REVOKE_ENTRY_SHARING = "REVOKE_ENTRY_SHARING";
const ENTRY_SHARING_UPDATED = "ENTRY_SHARING_UPDATED";
const SET_LOADING_FLAG = "SET_LOADING_FLAG";
const TOGGLE_SIDE_CONTEXT = "TOGGLE_SIDE_CONTEXT";
const TOGGLE_EXPAND_SIDE_CONTEXT = "TOGGLE_EXPAND_SIDE_CONTEXT";
const SEARCH_CREATORS = "SEARCH_CREATORS";
const SEARCH_CREATORS_SUCCESS = "SEARCH_CREATORS_SUCCESS";
const SHOW_STAGE_COMPLETE = "SHOW_STAGE_COMPLETE";
const RESTART_JOURNEY = "RESTART_JOURNEY";

// designer actions
const DESIGNER_NEW_PATH = "DESIGNER_NEW_PATH";
const REMOVE_DRAFT_PATH = "REMOVE_DRAFT_PATH";
const REORDER_DRAFT_PATHS = "REORDER_DRAFT_PATHS";
const SET_SELECTED_PATH = "SET_SELECTED_PATH";

const DESIGNER_NEW_JOURNAL = "DESIGNER_NEW_JOURNAL";
const REMOVE_DRAFT_JOURNAL = "REMOVE_DRAFT_JOURNAL";
const REORDER_DRAFT_JOURNALS = "REORDER_DRAFT_JOURNAL";
const SET_SELECTED_JOURNAL = "SET_SELECTED_JOURNAL";

const DESIGNER_NEW_PROMPT = "DESIGNER_NEW_PROMPT";
const REMOVE_DRAFT_PROMPT = "REMOVE_DRAFT_PROMPT";
const REORDER_DRAFT_INTERNAL_JOURNAL = "REORDER_DRAFT_INTERNAL_JOURNAL";

const DESIGNER_NEW_QUESTION = "DESIGNER_NEW_QUESTION";
const REMOVE_DRAFT_QUESTION = "REMOVE_DRAFT_QUESTION";
const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";

const UPDATE_DRAFT_PATH_INFO = "UPDATE_DRAFT_PATH_INFO";
const UPDATE_DRAFT_JOURNAL_INFO = "UPDATE_DRAFT_JOURNAL_INFO";
const UPDATE_DRAFT_PROMPT_INFO = "UPDATE_DRAFT_PROMPT_INFO";
const UPDATE_DRAFT_QUESTION_INFO = "UPDATE_DRAFT_QUESTION_INFO";

const DESIGNER_COLLAPSE_COLUMNS = "DESIGNER_COLLAPSE_COLUMNS";
const DESIGNER_BREADCRUMB = "DESIGNER_BREADCRUMB";
const DESIGNER_TOGGLE_CONTEXT = "DESIGNER_TOGGLE_CONTEXT";
const DESIGNER_SET_PATH_MANAGER_ID = "DESIGNER_SET_PATH_MANAGER_ID";

const DESIGNER_PUBLISH_PATH = "DESIGNER_PUBLISH_PATH";
const DESIGNER_PUBLISH_PATH_SUCCESS = "DESIGNER_PUBLISH_PATH_SUCCESS";
const DESIGNER_PUBLISH_PATH_ERROR = "DESIGNER_PUBLISH_PATH_ERROR";

export {
  APP_OPEN,
  CHANGE_SCREEN,
  LOAD_PATHDEF_SUCCESS,
  SHOW_DATA_EDITOR,
  CLOSE_DATA_EDITOR,
  TOGGLE_EXPAND_EDITOR,
  SET_DATA_EDITOR,
  SET_DATA_EDITOR_FIRST,
  SHIFT_EDITOR_QUESTION,
  SET_EDITOR_MODE,
  TEXT_ENTRY_FOCUS,
  UPDATE_QUESTION_OPS,
  DB_UPDATE,
  ENTRY_UPDATE,
  ENTRY_REMOVE,
  NEW_AFFECT_CATEGORY,
  UPDATE_AFFECT_CATEGORY,
  NEW_FEELING,
  UPDATE_FEELING,
  REMOVE_AFFECT_CATEGORY,
  REMOVE_FEELING,
  DEV_ACTION,
  SETTINGS_SHOW,
  SETTINGS_CLOSE,
  SETTINGS_CHANGE,
  SET_JOURNALS_SCREEN_TYPE,
  SET_ENTRY_SPECIFIC,
  SOS,
  TOGGLE_QUICK_BUTTONS,
  AUTH_UPDATE,
  SYNC_SUCCESS,
  SYNC_LATEST,
  SYNC_LATEST_SUCCESS,
  SYNC_ACCOUNT,
  SYNC_ACCOUNT_SUCCESS,
  SET_JOURNAL_ACTIVE,
  SEARCH_SET_MODE,
  SET_CREATOR_ID,
  LOAD_CREATOR_BY_ID,
  LOAD_CREATOR_SUCCESS,
  SET_ERROR,
  ACCOUNT_CHANGE,
  ACCOUNT_CHANGE_SUCCESS,
  SET_ENTRY_NEW,
  TOGGLE_ENTRY_SHARE_MODAL,
  SHARE_ENTRY,
  REVOKE_ENTRY_SHARING,
  ENTRY_SHARING_UPDATED,
  SET_LOADING_FLAG,
  TOGGLE_SIDE_CONTEXT,
  TOGGLE_EXPAND_SIDE_CONTEXT,
  SEARCH_CREATORS,
  SEARCH_CREATORS_SUCCESS,
  SHOW_STAGE_COMPLETE,
  RESTART_JOURNEY,
  DESIGNER_NEW_PATH,
  REMOVE_DRAFT_PATH,
  REORDER_DRAFT_PATHS,
  SET_SELECTED_PATH,
  DESIGNER_NEW_JOURNAL,
  REMOVE_DRAFT_JOURNAL,
  REORDER_DRAFT_JOURNALS,
  SET_SELECTED_JOURNAL,
  DESIGNER_NEW_PROMPT,
  REMOVE_DRAFT_PROMPT,
  REORDER_DRAFT_INTERNAL_JOURNAL,
  DESIGNER_NEW_QUESTION,
  REMOVE_DRAFT_QUESTION,
  SET_SELECTED_QUESTION,
  UPDATE_DRAFT_PATH_INFO,
  UPDATE_DRAFT_JOURNAL_INFO,
  UPDATE_DRAFT_PROMPT_INFO,
  UPDATE_DRAFT_QUESTION_INFO,
  DESIGNER_COLLAPSE_COLUMNS,
  DESIGNER_BREADCRUMB,
  DESIGNER_TOGGLE_CONTEXT,
  DESIGNER_SET_PATH_MANAGER_ID,
  DESIGNER_PUBLISH_PATH,
  DESIGNER_PUBLISH_PATH_SUCCESS,
  DESIGNER_PUBLISH_PATH_ERROR,
};
