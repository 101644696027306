import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { toggle_quick_buttons } from "../redux/actions";

import { cstyles } from "../common";
import { get_ref_by_entryday } from "../getters";

import ButtonSection from "./ButtonSection";

import HoverIcon from "../wrappers/HoverIcon";

const close_icon = require("../icons/close.png");

const styles = {
  container: {
    position: "fixed",
    top: 60,
    right: 0,
    zIndex: 90,
  },
  content: {
    backgroundColor: cstyles.raised(1),
    border: cstyles.border("input"),
    borderRadius: 8,
    marginRight: 10,
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    maxWidth: 800,
    overflowX: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  buttons: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
};

class QuickButtons extends Component {
  static propTypes = {
    button_ids: PropTypes.array.isRequired,
    toggle_quick_buttons: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render_buttons() {
    if (this.props.button_ids.length === 0) {
      return <div>No active buttons</div>;
    }

    return this.props.button_ids.map(button_id => {
      return <ButtonSection key={button_id} button_id={button_id} quick={true} />;
    });
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.content} className={"animated bounceInRight fast"}>
          <div style={styles.buttons}>{this.render_buttons()}</div>
          <div style={styles.header}>
            <HoverIcon
              alt={"close buttons"}
              icon={close_icon}
              action={() => {
                this.props.toggle_quick_buttons();
              }}
              icon_size={16}
            />
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const button_ids = state.path_comps.button_ids
    .map(button_id => {
      return state.db[button_id];
    })
    // filter out buttons that are inactive
    .filter(button => {
      if (
        state.inactive_ids[button.from_path_id] ||
        state.inactive_ids[button.button_id]
      ) {
        return false;
      }
      return true;
    })
    .filter(button => {
      const { ref_answer } = get_ref_by_entryday(state, button.ref_question_id, null);
      return ref_answer && ref_answer.length > 0 ? true : false;
    })
    .map(button => {
      return button.button_id;
    });

  return {
    button_ids,
  };
}
export default connect(state_to_props, { toggle_quick_buttons })(QuickButtons);
