import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { cstyles } from "../common";

import HoverIcon from "../wrappers/HoverIcon";

const remove_icon = require("../icons/delete.png");

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    marginRight: 20,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("input"),
    fontSize: 13,
    userSelect: "none",
    color: cstyles.text("rest"),
    padding: 4,
    paddingLeft: 6,
    paddingRight: 3,
  },
};

class ListItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    item_id: PropTypes.string.isRequired,
    on_remove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.chip}>
        {this.props.name}
        <HoverIcon
          alt={"remove"}
          icon={remove_icon}
          action={() => this.props.on_remove(this.props.item_id)}
          style={{ padding: 3 }}
          icon_size={14}
        />
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  return {
    name: state.db[ownProps.item_id].name,
  };
}
export default connect(state_to_props, {})(ListItem);
