import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { toggle_side_context, toggle_expand_side_context } from "../redux/actions";

import { cstyles, boxShadows, ss, url_regex } from "../common";
import {} from "../getters";

import HoverIcon from "../wrappers/HoverIcon";
import Hover from "../wrappers/Hover";
import SpotlightEntry from "../creator/SpotlightEntry";

const close_icon = require("../icons/close.png");

const styles = {
  container: {
    position: "fixed",
    opacity: 0,
    right: -20,
    top: 70,
    bottom: 210,
    backgroundColor: cstyles.raised(1),
    marginRight: 8,
    boxShadow: boxShadows[2],
    transition: "height 400ms, opacity 250ms, right 250ms, bottom 250ms",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 400,
    maxWidth: 400,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 10,
  },
  body: {
    overflowY: "auto",
    marginBottom: 10,
    paddingRight: 5,
    flexgrow: 1,
    flex: 10,
  },
  header_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    width: "100%",
  },
  title: {
    fontSize: 28,
    fontWeight: 300,
    marginRight: 20,
  },
  expand_button: {
    position: "absolute",
    bottom: 6,
    right: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 8,
    opacity: 0.8,
    marginRight: -8,
  },
  arrow_icon: {
    height: 42,
    width: 42,
  },
  no_context_text: {
    fontStyle: "italic",
    color: cstyles.text("inactive"),
    fontSize: 16,
    marginTop: 20,
  },
  body_paragraph: {
    color: cstyles.text("rest"),
  },
  image: {
    maxWidth: 380,
    maxHeight: 400,
    marginTop: 20,
    marginBottom: 20,
  },
  video: {
    width: 380,
    height: 214,
    marginTop: 20,
    marginBottom: 20,
  },
  video_title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: -15,
    maxWidth: 680,
  },
  divider: {
    width: 400,
    maxWidth: 400,
    marginTop: 70,
    marginBottom: 20,
  },
  header: {
    fontSize: 28,
    fontWeight: 600,
    marginBottom: 20,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  example_entries_title: {
    color: cstyles.text("faded"),
    fontSize: 16,
    marginLeft: 6,
  },
  example_entries: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    paddingBottom: 15,
    scrollbarWidth: "thin",
  },
};

class SideContext extends Component {
  static propTypes = {
    toggle_side_context: PropTypes.func.isRequired,
    toggle_expand_side_context: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    editor_expanded: PropTypes.bool.isRequired,
    context: PropTypes.object,
    example_entries: PropTypes.array.isRequired,
    editor_show: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      opening: false,
      expanded: this.props.expanded, // expanded exists in both state and props. props is only used to set initial state
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opening: true,
      });
    }, 0);
  }

  render_title() {
    return <div style={styles.title}>Question Context</div>;
  }

  render_body_paragraph(section) {
    // Do url/link replacing
    const text = section.text
      .replace(url_regex, url => {
        return `<$$$SPLITHERE$$$><$$$URL$$$>${url}<$$$SPLITHERE$$$>`;
      })
      .split("<$$$SPLITHERE$$$>")
      .map(sec => {
        if (sec.includes("<$$$URL$$$>")) {
          sec = sec.replace("<$$$URL$$$>", "");
          return React.createElement("a", { href: sec }, sec);
        }
        return sec;
      });

    return (
      <p style={styles.body_paragraph} key={section.text}>
        {text}
      </p>
    );
  }

  render_body_header(section) {
    return (
      <p style={styles.header} key={section.header}>
        {section.header}
      </p>
    );
  }

  render_body_image(section) {
    let style = styles.image;
    if (section.width && section.width < 380) {
      style = { ...style, width: section.width };
    }

    return (
      <div style={styles.center}>
        <img src={section.source} alt={"context"} style={style} />
      </div>
    );
  }

  render_video_title(title) {
    if (!title) {
      return null;
    }

    return <div style={styles.video_title}>{title}</div>;
  }

  render_body_video(section) {
    if (section.type === "video-direct") {
      return (
        <div>
          {this.render_video_title(section.title)}
          <video style={styles.video} controls>
            <source src={section.source} />
          </video>
        </div>
      );
    } else if (section.type === "video-frame") {
      // note -- these need to be specific embed links. the journal designer should handle this
      return (
        <div>
          {this.render_video_title(section.title)}
          <iframe
            style={styles.video}
            src={section.source}
            frameBorder="0"
            allowFullScreen
            title={"iframe context video"}
          />
        </div>
      );
    }
  }

  render_body_divider(section) {
    return (
      <div>
        <hr style={styles.divider} />
        {section.title ? <div style={styles.header}>{section.title}</div> : null}
      </div>
    );
  }

  render_body() {
    const { context } = this.props;

    if (!context || !context.body || context.body.length === 0) {
      return (
        <div style={styles.no_context_text}>No context available for this question</div>
      );
    }

    return context.body.map((section, idx) => {
      if (section.type === "paragraph") {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_paragraph(section)}
          </div>
        );
      } else if (section.type.includes("image")) {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_image(section)}
          </div>
        );
      } else if (section.type.includes("video")) {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_video(section)}
          </div>
        );
      } else if (section.type === "divider") {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_divider(section)}
          </div>
        );
      } else if (section.type === "header") {
        return (
          <div key={`context-section:${idx}`} style={{ width: "100%" }}>
            {this.render_body_header(section)}
          </div>
        );
      } else {
        throw new Error(
          `SideContext encountered an unknown section type: ${section.type}`
        );
      }
    });
  }

  render_example_entries() {
    const { example_entries } = this.props;

    if (!example_entries || example_entries.length === 0) {
      return null;
    }

    return (
      <div style={{ width: "100%" }}>
        <hr style={{ border: cstyles.border("subtle"), width: "100%" }} />
        <div style={styles.example_entries_title}>Example entries for this journal</div>
        <div style={styles.example_entries}>
          {example_entries.map((entry, idx) => {
            return (
              <div style={{ minWidth: 180 }} key={`example-entry:${idx}`}>
                <SpotlightEntry entry={entry} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render_expand() {
    if (this.state.expanded) {
      return (
        <div style={{ height: 40 }}>
          <Hover
            style={styles.expand_button}
            action={() => {
              this.setState({
                expanded: false,
              });
              setTimeout(() => {
                this.props.toggle_expand_side_context();
              }, 200);
            }}
          >
            <div style={{ color: cstyles.text("faded") }}>shrink</div>
            <img
              src={require("../icons/up_arrow.png")}
              alt={"expand"}
              style={styles.arrow_icon}
            />
          </Hover>
        </div>
      );
    } else {
      return (
        <div style={{ height: 40 }}>
          <Hover
            style={styles.expand_button}
            action={() => {
              this.props.toggle_expand_side_context();
              setTimeout(() => {
                this.setState({
                  expanded: true,
                });
              }, 200);
            }}
          >
            <div style={{ color: cstyles.text("faded") }}>expand</div>
            <img
              src={require("../icons/down_arrow.png")}
              alt={"expand"}
              style={styles.arrow_icon}
            />
          </Hover>
        </div>
      );
    }
  }

  render() {
    // opening animation
    let container_style = ss(this.state.opening, styles.container, {
      opacity: 1,
      right: 0,
    });

    // figure out bottom position
    // fully expanded
    if (this.state.expanded || !this.props.editor_show) {
      container_style = {
        ...container_style,
        bottom: 5,
      };
    }
    // normal expanded
    else if (!this.state.expanded && !this.props.editor_expanded) {
      container_style = {
        ...container_style,
        bottom: 210,
      };
    }
    // shrunk due to expanded editor
    else if (!this.state.expanded && this.props.editor_expanded) {
      container_style = {
        ...container_style,
        bottom: 410,
      };
    }

    return (
      <div style={container_style}>
        <div style={styles.header_row}>
          {this.render_title()}
          <HoverIcon
            icon={close_icon}
            icon_size={24}
            alt={"close context"}
            action={() => {
              this.setState({
                opening: false,
              });
              setTimeout(() => {
                this.props.toggle_side_context();
              }, 200);
            }}
          />
        </div>

        <div style={styles.body}>{this.render_body()}</div>
        {this.render_example_entries()}
        {this.render_expand()}
      </div>
    );
  }
}

function state_to_props(state) {
  const question = state.db[state.editor.question_id];
  const { context } = question;

  const journal = state.db[state.entry.journal_id];
  const example_entries = journal.context
    ? journal.context.example_entries
      ? journal.context.example_entries
      : []
    : [];

  return {
    expanded: state.entry.side_context_expanded,
    editor_expanded: state.editor.expanded,
    editor_show: state.editor.show,
    context,
    example_entries,
  };
}
export default connect(state_to_props, {
  toggle_side_context,
  toggle_expand_side_context,
})(SideContext);
