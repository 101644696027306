import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_screens } from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Input from "./Input";

import Hover from "../wrappers/Hover";

import { sign_up, clear_error } from "./auth_actions";
import Error from "./Error";
import Confirm from "./Confirm";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
  },
  header: {
    fontSize: 18,
    marginBottom: 30,
    color: cstyles.text("rest"),
  },
  privacy: {
    fontSize: 11,
  },
  button: {
    padding: 6,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: cstyles.text("rest"),
    border: cstyles.border("subtle"),
    height: 28,
  },
  spinner: {
    border: "4px solid " + cstyles.raised(0),
    borderTop: "4px solid #bae7ff",
    borderRadius: "50%",
    width: 10,
    height: 10,
    animation: "spin 1s linear infinite",
  },
  req: {
    color: cstyles.remove,
    fontSize: 13,
    fontWeight: 600,
    textAlign: "right",
    margin: 4,
    height: 16,
  },
  info_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  go_back: {
    fontSize: 13,
    fontWeight: 600,
    padding: 4,
    color: cstyles.text("inactive"),
  },
};

class SignUp extends Component {
  static propTypes = {
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      mode: "signup", // signup || confirm
    };
    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  on_key_press(e) {
    if (e.key === "Enter") {
      switch (this.state.mode) {
        case "signup":
          this.on_sign_up();
          break;
        case "confirm":
          // nothing to do - just ignore
          break;
        default:
          break;
      }
    }
  }

  on_input(name, value) {
    let new_state = {};
    new_state[name] = value;
    this.setState(new_state);
  }

  on_sign_up() {
    if (this.state.password.length < 8 || this.state.email.length < 6) {
      return;
    }

    this.setState({
      loading: true,
    });

    sign_up(this.state.email, this.state.password).then(success => {
      this.setState({
        loading: false,
      });

      if (success) {
        this.setState({
          mode: "confirm",
        });
      }
    });
  }

  show_req() {
    const { password } = this.state;
    if (password.length > 0 && password.length < 8) {
      return <div style={styles.req}>8+ characters</div>;
    } else {
      return <div style={styles.req}></div>;
    }
  }

  render() {
    if (this.state.mode === "confirm") {
      return <Confirm email={this.state.email} password={this.state.password} />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>To continue, create an account</div>
        <Input name={"email"} on_change={value => this.on_input("email", value)} />
        <Input
          name={"password"}
          on_change={value => this.on_input("password", value)}
        />
        {this.show_req()}
        <Error />

        <Hover
          style={styles.button}
          action={() => {
            this.on_sign_up();
          }}
          disabled={this.state.password.length < 8}
        >
          {this.state.loading ? <div style={styles.spinner} /> : "sign up"}
        </Hover>
        <div style={styles.info_row}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://wellsayer.com/privacy"}
            style={styles.privacy}
          >
            Wellsayer Privacy Policy
          </a>
          <Hover
            action={() => {
              this.props.change_screens("home");
              clear_error();
            }}
            style={styles.go_back}
          >
            Go back
          </Hover>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, { change_screens })(SignUp);
