import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { ss, cstyles } from "../common";

const styles = {
  chip: {
    borderRadius: 8,
    margin: 2,
    display: "flex",
    width: "max-content",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
    border: cstyles.border("rest"),
    fontSize: 13,
    transition: "background-color 250ms, border 250ms, color 250ms",
    userSelect: "none",
    color: cstyles.text("rest"),
    cursor: "pointer",
  },
  inner: {
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 8,
  },
};

class AffectChip extends Component {
  static propTypes = {
    update_entry: PropTypes.func.isRequired,
    feeling: PropTypes.object.isRequired,
    entry_id: PropTypes.any,
    question_id: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  on_select() {
    this.props.update_entry(this.props.entry_id, this.props.question_id, "affects", {
      selected: !this.props.selected,
      id: this.props.feeling.feeling_id,
    });
  }

  render() {
    // Given an empty name, don't render the affect
    if (this.props.feeling.name === "") {
      return null;
    }

    // Change styling for hover
    const { hover } = this.state;
    let chip_style = ss(hover, styles.chip, cstyles.chip_hover);

    // Change style for selected
    chip_style = ss(this.props.selected, chip_style, cstyles.chip_selected);

    return (
      <div
        style={chip_style}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={() => this.on_select()}
      >
        <div style={styles.inner}>{this.props.feeling.name}</div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const feeling = state.db[ownProps.feeling_id];
  const { question_id } = state.editor;

  // Check to see if we're selected (by checking if our ID is in entry.answers.question_id array)
  let entry_id = null;
  let selected = false;
  try {
    // Get entry (if exists)
    let entry = state.db[state.entry.entry_id];
    entry_id = entry.entry_id;

    // Get answer for our question_id
    let answer = entry.answers[question_id];

    // If this affect is already answered, selected=true
    if (answer.includes(feeling.feeling_id)) {
      selected = true;
    }
  } catch (err) {}

  return {
    feeling,
    entry_id,
    question_id,
    selected,
  };
}
export default connect(state_to_props, { update_entry })(AffectChip);
