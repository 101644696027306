import React, { Component } from "react";

import { connect } from "react-redux";
import {} from "../redux/actions";

import Categories from "./Categories";
import Feelings from "./Feelings";

const styles = {
  page: {
    marginTop: 10,
    marginRight: 10,
    height: "100%",
    overflowY: "scroll",
  },
};

class AffectsScreen extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.page}>
        <Categories />
        <div style={{ height: 20 }} />
        <Feelings />
        <div style={{ height: 100 }} />
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(AffectsScreen);
