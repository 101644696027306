import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { set_entry_new, change_screens, show_stage_complete } from "../redux/actions";

import { cstyles, boxShadows, ss } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";
import HoverIcon from "../wrappers/HoverIcon";

const close_icon = require("../icons/close.png");

const styles = {
  container: {
    position: "fixed",
    opacity: 0,
    right: -20,
    bottom: 210,
    backgroundColor: cstyles.raised(1),
    marginRight: 8,
    boxShadow: boxShadows[2],
    transition: "height 400ms, opacity 250ms, right 250ms, bottom 250ms",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    minWidth: 280,
    maxWidth: 400,
    minHeight: 100,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  close_div: {
    position: "absolute",
    right: 6,
    top: 6,
  },
  title: {
    fontWeight: 400,
    fontSize: 16,
    height: 35,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  continue: {
    fontSize: 14,
    color: cstyles.text("inactive"),
  },
  journal_name: {
    fontWeight: 700,
    fontSize: 21,
    textDecoration: "underline",
    marginBottom: 5,
  },
};

class StageComplete extends Component {
  static propTypes = {
    editor_expanded: PropTypes.bool.isRequired,
    editor_show: PropTypes.bool.isRequired,
    show_side_context: PropTypes.bool.isRequired,
    next_journal_name: PropTypes.string.isRequired,
    next_journal_id: PropTypes.string.isRequired,
    on_complete: PropTypes.object,
    set_entry_new: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    show_stage_complete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      opening: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opening: true,
      });
    }, 0);
  }

  render_title() {
    const { on_complete } = this.props;

    const tone = on_complete
      ? on_complete.stage_complete_popup_tone
        ? on_complete.stage_complete_popup_tone
        : "celebratory"
      : "celebratory";

    if (tone === "celebratory") {
      return (
        <div style={styles.title}>
          <span role="img" aria-label="party popper">
            🎉
          </span>
          &nbsp; stage complete! &nbsp;
          <span role="img" aria-label="confetti ball">
            🎊
          </span>
        </div>
      );
    } else {
      return <div style={styles.title}>stage complete</div>;
    }
  }

  render() {
    const { editor_expanded, show_side_context, next_journal_name } = this.props;

    // opening animation
    let container_style = ss(this.state.opening, styles.container, {
      opacity: 1,
      right: 0,
    });

    // figure out bottom position
    if (!this.props.editor_show) {
      container_style = {
        ...container_style,
        bottom: 5,
      };
    } else if (editor_expanded) {
      container_style = {
        ...container_style,
        bottom: 410,
      };
    } else {
      container_style = {
        ...container_style,
        bottom: 210,
      };
    }

    // figure out right position
    if (show_side_context) {
      container_style = {
        ...container_style,
        right: 430,
      };
    } else {
      container_style = {
        ...container_style,
        right: 5,
      };
    }

    return (
      <Hover
        style={container_style}
        action={() => {
          this.props.set_entry_new(this.props.next_journal_id);
          this.props.change_screens("entry");
          this.props.show_stage_complete(false);
        }}
      >
        <div style={styles.close_div}>
          <HoverIcon
            stop_prop
            icon={close_icon}
            icon_size={24}
            alt={"close show stage"}
            action={() => {
              this.props.show_stage_complete(false);
            }}
          />
        </div>
        {this.render_title()}
        <div style={styles.continue}>click to continue to</div>
        <div style={styles.journal_name}>{next_journal_name}</div>
      </Hover>
    );
  }
}

function state_to_props(state) {
  const path_id = state.db[state.entry.journal_id].from_path_id;
  const path = state.db[path_id];
  const prev_stage = path.journey.stages[state.journeys[path_id].prev_stage];

  // get next journal
  const cur_stage = path.journey.stages[state.journeys[path_id].stage];
  const next_journal = state.db[cur_stage.active_journal];
  const next_journal_name = next_journal.name;
  const next_journal_id = next_journal.journal_id;

  return {
    editor_expanded: state.editor.expanded,
    editor_show: state.editor.show,
    show_side_context: state.entry.show_side_context,
    on_complete: prev_stage.on_complete ? prev_stage.on_complete : null,
    next_journal_name,
    next_journal_id,
  };
}
export default connect(state_to_props, {
  set_entry_new,
  change_screens,
  show_stage_complete,
})(StageComplete);
