import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_screens } from "../redux/actions";

import { cstyles, ss } from "../common";
import {} from "../getters";

import SignIn from "../auth/SignIn";

import Hover from "../wrappers/Hover";

const logo = require("../icons/logo-light.png");

const styles = {
  page: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    width: "100vw",
    opacity: 0,
    transition: "opacity 500ms",
  },
  left: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0,
    borderStyle: "none none none solid",
    borderWidth: "1px",
    borderColor: cstyles.raised(1),
  },
  right_content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  new: {
    fontSize: 50,
    marginBottom: 30,
  },
  logo: {
    height: 128,
    width: 128,
    animation: "breath 2s ease-in-out infinite alternate",
  },
};

class Welcome extends Component {
  static propTypes = {
    change_screens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    setTimeout(() => {
      this.setState({
        show: true,
      });
    }, 100);
  }

  on_im_new() {
    this.setState({
      show: false,
    });
    setTimeout(() => {
      this.props.change_screens("new");
    }, 600);
  }

  render() {
    const page_style = ss(this.state.show, styles.page, { opacity: 1 });
    return (
      <div style={page_style}>
        <div style={styles.left}>
          <SignIn />
        </div>
        <Hover
          style={styles.right}
          action={() => {
            this.on_im_new();
          }}
        >
          <div style={styles.right_content}>
            <div style={styles.new}>I'm new</div>
            <img alt={"wellsayer logo"} src={logo} style={styles.logo} />
            <div style={{ marginTop: 30, fontSize: 14 }}>click to continue</div>
          </div>
        </Hover>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, { change_screens })(Welcome);
