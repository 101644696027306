import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { change_setting, close_settings } from "../redux/actions";
import { persistor } from "../redux/reducer";
import { ss, cstyles } from "../common";

import { sign_out } from "../auth/auth_actions";

import RadioSection from "./RadioSection";
import ColorPicker from "./ColorPicker";

import Hover from "../wrappers/Hover";
import HoverIcon from "../wrappers/HoverIcon";

const close_icon = require("../icons/close.png");

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, 0.75)",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 250ms",
    opacity: 0.3,
    zIndex: 100,
  },
  container: {
    marginTop: -40,
    width: 650,
    height: "70vh",
    backgroundColor: cstyles.raised(1),
    borderRadius: 8,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  header: {
    height: 80,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
    color: "white",
    fontSize: 24,
    padding: "0 35px 0 35px",
    fontWeight: 600,
  },
  sidebar_container: {
    padding: "10px 0 0 5px",
    width: 200,
  },
  sidebar_section: {
    padding: "3px 0px 3px 10px",
    marginLeft: 5,
    justifyContent: "flex-start",
    backgroundColor: cstyles.raised(1),
    fontWeight: 600,
    width: "80%",
    transition: "filter 250ms",
  },
  sidebar_section_selected: {
    backgroundColor: "#40a9ff",
  },
  section_container: {
    padding: "10px 0 0 5px",
    width: 450,
    height: "80%",
    overflowY: "auto",
  },
  screen_header: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 15,
  },
  close_icon_container: {
    padding: 8,
    backgroundColor: cstyles.raised(1),
  },
  info_header: {
    fontSize: 18,
  },
  info_text: {
    fontSize: 14,
    color: cstyles.text("rest"),
  },
  button: {
    fontSize: 18,
    color: cstyles.text("rest"),
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: cstyles.raised(1),
    whiteSpace: "nowrap",
    width: "min-content",
  },
  section_header: {
    fontWeight: 600,
    fontSize: 18,
  },
  subtext: {
    color: cstyles.text("faded"),
  },
  color_preview_box: {
    backgroundColor: cstyles.raised(0),
    borderRadius: 8,
    margin: 10,
    padding: 10,
    paddingLeft: 20,
  },
  preview_question: {
    fontSize: 22,
    fontWeight: 300,
    transition: "color 250ms, filter 250ms, opacity 250ms",
  },
  preview_answer: {
    fontSize: 14,
  },
};

class SettingsOverlay extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    change_setting: PropTypes.func.isRequired,
    close_settings: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      section: "customize",
    };

    this.fadeInTimer = setTimeout(() => {
      this.setState({
        opacity: 1,
      });
    }, 0);
  }

  componentWillUnmount() {
    /* Minor cleanup task
    This is only relevant if the settings menu is up, and the page is refreshed (or similar)
    Without it, the setTimeout to fade in gives the warning: Warning: Can't perform a React state update on an unmounted component.
    */
    clearTimeout(this.fadeInTimer);
  }

  on_change_section(section) {
    this.setState({
      section,
    });
  }

  render_sidebar() {
    const sections = [
      {
        name: "customize",
        display: "Customize",
      },
      {
        name: "editor",
        display: "Editor",
      },
      {
        name: "entry_screen",
        display: "Entry Screen",
      },
      {
        name: "account",
        display: "Account",
      },
    ];

    return sections.map(section => {
      const style = ss(
        this.state.section === section.name,
        styles.sidebar_section,
        styles.sidebar_section_selected
      );
      return (
        <Hover
          style={style}
          action={() => {
            this.on_change_section(section.name);
          }}
          key={`sidebar:${section.name}`}
        >
          {section.display}
        </Hover>
      );
    });
  }

  render_section() {
    switch (this.state.section) {
      case "editor":
        return this.render_editor_section();
      case "entry_screen":
        return this.render_entry_screen_section();
      case "account":
        return this.render_account_section();
      case "customize":
        return this.render_customize_section();
      default:
        throw new Error("invalid settings section namee");
    }
  }

  render_entry_screen_section() {
    return (
      <div style={styles.section_container}>
        <div style={styles.screen_header}>Entry Screen</div>
        <RadioSection
          definition={{
            header: "Auto scrolling",
            setting_name: "entry_auto_scrolling",
            options: [
              {
                setting_value: true,
                setting_label: "Automatically scroll to question",
              },
              {
                setting_value: false,
                setting_label: "Don't scroll",
              },
            ],
          }}
        />
        <RadioSection
          definition={{
            header: "Automatically show question context",
            setting_name: "auto_show_side_context",
            options: [
              {
                setting_value: "always",
                setting_label: "Always show when opening a journal.",
              },
              {
                setting_value: "few",
                setting_label: "Show for the first few entrys in a journal.",
              },
              {
                setting_value: "first",
                setting_label: "Only show for the first entry in a journal.",
              },
            ],
          }}
        />
      </div>
    );
  }

  render_editor_section() {
    return (
      <div style={styles.section_container}>
        <div style={styles.screen_header}>Editor</div>
        <RadioSection
          definition={{
            header: "Default editor height",
            setting_name: "default_editor_height",
            options: [
              {
                setting_value: "compact",
                setting_label: "Compact",
              },
              {
                setting_value: "expanded",
                setting_label: "Expanded",
              },
            ],
          }}
        />
        <RadioSection
          definition={{
            header: "Arrow key navigation while typing",
            setting_name: "arrow_navigation_typing",
            options: [
              {
                setting_value: "disabled",
                setting_label: "Arrow keys do not change the question",
              },
              {
                setting_value: "up_down_only",
                setting_label: "Only Up/Down keys will change the question",
              },
              {
                setting_value: "enabled",
                setting_label: "Arrow keys always change the questions",
              },
            ],
          }}
        />
      </div>
    );
  }

  render_account_section() {
    const { auth } = this.props;
    return (
      <div style={styles.section_container}>
        <div style={styles.screen_header}>Account</div>
        <div>
          <div style={styles.info_header}>Email</div>
          <div style={styles.info_text}>{auth.email}</div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Hover
            style={styles.button}
            action={async () => {
              await sign_out();
              await persistor.purge();
            }}
          >
            Sign out
          </Hover>
        </div>
      </div>
    );
  }

  render_customize_section() {
    const { settings } = this.props;
    return (
      <div style={styles.section_container}>
        <div style={styles.screen_header}>Customize your app</div>
        <div style={styles.section_header}>Accent color</div>
        <div style={styles.subtext}>
          Change the color of entry questions in app and in shared entries
        </div>
        <div style={{ marginTop: 10 }}>
          <ColorPicker />
        </div>
        <div style={styles.color_preview_box}>
          <div style={{ ...styles.preview_question, color: settings.accent_color }}>
            A question in your journal
          </div>
          <div style={styles.preview_answer}>
            and your lovely response to that question
          </div>
        </div>
      </div>
    );
  }

  on_close() {
    this.setState({ opacity: 0 });
    setTimeout(() => {
      this.props.close_settings();
    }, 300);
  }

  render() {
    const overlay_style = ss(true, styles.overlay, {
      opacity: this.state.opacity,
    });

    return (
      <div
        style={overlay_style}
        onClick={() => {
          this.on_close();
        }}
      >
        <div
          style={styles.container}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div style={styles.header}>
            <div>Settings</div>
            <HoverIcon
              icon={close_icon}
              icon_size={24}
              alt={"close settings"}
              action={() => {
                this.on_close();
              }}
            />
          </div>
          <div style={styles.content}>
            <div style={styles.sidebar_container}>{this.render_sidebar()}</div>
            {this.render_section()}
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {
    settings: state.settings,
    auth: state.auth,
  };
}
export default connect(state_to_props, { change_setting, close_settings })(
  SettingsOverlay
);
