import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry } from "../redux/actions";

import { get_answer } from "../getters";

import HoverIcon from "../wrappers/HoverIcon";

const checked_icon = require("../icons/checkbox_checked.png");
const not_checked_icon = require("../icons/checkbox_not_checked.png");

const styles = {
  container: {
    height: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  },
};

class Checkbox extends Component {
  static propTypes = {
    answer: PropTypes.bool,
    entry_id: PropTypes.string,
    question_id: PropTypes.string.isRequired,
    update_entry: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  on_key_press(e) {
    if (e.key === " ") {
      this.on_check();
    }
  }

  on_check() {
    this.props.update_entry(
      this.props.entry_id,
      this.props.question_id,
      "checkbox",
      this.props.answer ? false : true
    );
  }

  render() {
    const { answer } = this.props;

    const icon = answer ? checked_icon : not_checked_icon;

    return (
      <HoverIcon
        alt={"checkbox"}
        style={styles.container}
        icon={icon}
        action={() => this.on_check()}
        icon_size={64}
      />
    );
  }
}

function state_to_props(state) {
  const { entry_id } = state.entry;
  const { question_id } = state.editor;

  const answer = get_answer(state, question_id, entry_id);

  return {
    entry_id,
    question_id,
    answer,
  };
}
export default connect(state_to_props, { update_entry })(Checkbox);
