/* 

auth actions

References
    Auth API docs:  https://aws-amplify.github.io/amplify-js/api/classes/authclass.html
    Response types  https://github.com/aws-amplify/amplify-js/blob/4644b4322ee260165dd756ca9faeb235445000e3/packages/amazon-cognito-identity-js/index.d.ts
    Quick start:    https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js
*/

import { Auth } from "aws-amplify";
import {
  auth_update,
  change_screens,
  sync_latest,
  sync_account,
} from "../redux/actions";
import { store } from "../redux/reducer";

function is_error(e) {
  return (
    e &&
    e.stack &&
    e.message &&
    typeof e.stack === "string" &&
    typeof e.message === "string"
  );
}

// handles dispatching error cases
function dispatch_error(error) {
  try {
    if (is_error(error)) {
      store.dispatch(
        auth_update({
          error: true,
          error_message: error.toString(),
        })
      );
    } else {
      store.dispatch(
        auth_update({
          error: error.code,
          error_message: error.message,
        })
      );
    }
  } catch (err) {
    store.dispatch(
      auth_update({
        error: true,
      })
    );
  }
}

function clear_error() {
  store.dispatch(
    auth_update({
      error: null,
      error_message: "",
    })
  );
}

function post_login_syncs() {
  store.dispatch(sync_latest());
  store.dispatch(sync_account());
}

async function sign_up(email, password) {
  try {
    await Auth.signUp({
      username: email,
      password,
    });
    clear_error();
    return true;
  } catch (error) {
    dispatch_error(error);
  }

  return false;
}

async function sign_in(email, password) {
  try {
    const user = await Auth.signIn(email, password);
    store.dispatch(change_screens("home"));
    store.dispatch(
      auth_update({
        signed_in: true,
        email: user.attributes.email,
        error: null,
        error_message: "",
      })
    );

    post_login_syncs();

    return true;
  } catch (error) {
    dispatch_error(error);
  }

  return false;
}

async function sign_out() {
  try {
    await Auth.signOut();
    store.dispatch(
      auth_update({
        signed_in: false,
        error: null,
        error_message: "",
      })
    );
    return true;
  } catch (error) {
    dispatch_error(error);
  }

  return false;
}

async function check_signed_in() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    store.dispatch(
      auth_update({
        signed_in: true,
        email: user.attributes.email,
        error: null,
        error_message: "",
      })
    );

    post_login_syncs();

    return true;
  } catch (error) {
    store.dispatch(
      auth_update({
        signed_in: false,
        error: null,
        error_message: "",
      })
    );
  }

  return false;
}

async function forgot_password(email) {
  try {
    await Auth.forgotPassword(email);
    clear_error();
    return true;
  } catch (error) {
    dispatch_error(error);
  }

  return false;
}

async function forgot_password_submit(email, code, password) {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);
    clear_error();
    return true;
  } catch (error) {
    dispatch_error(error);
  }

  return false;
}

export {
  sign_up,
  sign_in,
  sign_out,
  check_signed_in,
  forgot_password,
  forgot_password_submit,
  clear_error,
};
