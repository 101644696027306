// ui version -- the latest short git hash
// why only the first 3 characters? I figure it's better to minimize the "internal" info that we publish,
// and that 3 characters is usually enough for the use case of this identifier
const UIVER = process.env.REACT_APP_UIVER.slice(0, 3);

// app version -- the overall defined version of the app
const APPVER = process.env.REACT_APP_APPVER;

// id -- a unique build id for this particular code
const ID = `${APPVER}/${UIVER}`;

export { UIVER, APPVER, ID };
