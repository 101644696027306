import React, { PureComponent } from "react";
import { cstyles } from "../common";

const styles = {
  divider: {
    backgroundColor: cstyles.raised(2),
    height: 1,
    width: "100%",
  },
};

export default class Divider extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div style={styles.divider} />;
  }
}
