import React, { Component } from "react";
import PropTypes from "prop-types";

import {} from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

const styles = {
  input: {
    padding: 6,
    marginBottom: 20,
    backgroundColor: cstyles.raised(0),
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: cstyles.app_border,
    color: cstyles.text("active"),
    fontSize: 16,
    width: 240,
  },
};

export default class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    on_change: PropTypes.func.isRequired,
    start: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };

    if (this.props.start && this.props.start.length > 0) {
      this.state = {
        text: this.props.start,
      };
    }
  }

  on_change(text) {
    this.props.on_change(text);
    this.setState({
      text,
    });
  }

  render() {
    const { name } = this.props;
    return (
      <input
        style={styles.input}
        placeholder={name}
        value={this.state.text}
        onChange={e => this.on_change(e.target.value)}
        maxLength={60}
        type={name.includes("password") ? "password" : null}
      />
    );
  }
}
