import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_feeling, remove_feeling } from "../redux/actions";

import swal from "sweetalert";

import Divider from "./Divider";
import DeleteButton from "./DeleteButton";
import { ss, cstyles } from "../common";

const styles = {
  row: {
    marginTop: 3,
    userSelect: "none",
    opacity: 0,
    transition: "opacity 350ms",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
    marginRight: 8,
  },
  name: {
    fontSize: 14,
    border: "none",
    width: "180px",
    backgroundColor: cstyles.raised(1),
    color: "white",
  },
  description: {
    fontSize: 14,
    width: "100%",
    borderRadius: 4,
    border: cstyles.border("active"),
    padding: 4,
    backgroundColor: cstyles.raised(2),
    color: "white",
  },
  select: {
    fontSize: 14,
    padding: 4,
    backgroundColor: cstyles.raised(2),
    color: "white",
    border: "none",
    cursor: "pointer",
  },
  select_container: {
    width: "min-content",
    marginRight: 5,
    padding: 8,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
  },
};

class FeelingRow extends Component {
  static propTypes = {
    feeling: PropTypes.object.isRequired,
    update_feeling: PropTypes.func.isRequired,
    remove_feeling: PropTypes.func.isRequired,
    all_categories: PropTypes.array.isRequired,
    order: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { name, description } = this.props.feeling;
    this.state = {
      name: name,
      category_id: this.props.feeling.category_id,
      description: description,
      opacity: 0,
    };

    this.name_change_timer = null;
    this.description_change_timer = null;

    setTimeout(() => {
      this.setState({
        opacity: 1,
      });
    }, this.props.order * 75);
  }

  on_update_name(e) {
    const text = e.target.value;
    this.setState({
      name: text,
    });

    clearTimeout(this.name_change_timer);
    this.name_change_timer = setTimeout(() => {
      this.props.update_feeling(this.props.feeling.feeling_id, "name", this.state.name);
    }, 500);
  }

  on_update_description(e) {
    const text = e.target.value;
    this.setState({
      description: text,
    });

    clearTimeout(this.description_change_timer);
    this.description_change_timer = setTimeout(() => {
      this.props.update_feeling(
        this.props.feeling.feeling_id,
        "description",
        this.state.description
      );
    }, 500);
  }

  on_update_category(e) {
    const category_id = e.target.value;

    this.props.update_feeling(
      this.props.feeling.feeling_id,
      "category_id",
      category_id
    );

    this.setState({
      category_id: category_id,
    });
  }

  on_remove() {
    // Get name, set empty if not present
    let { name } = this.props.feeling;
    if (!name) {
      name = "<no name>";
    }

    swal({
      title: "Remove " + name,
      text: `Are you sure want to remove ${name} from your feelings list?\n\nThis won't remove the feeling from any journal entries where it was already selected.`,
      icon: "warning",
      buttons: ["Keep " + name, "Remove " + name],
      dangerMode: true,
    }).then(choice => {
      if (choice) {
        this.props.remove_feeling(this.props.feeling.feeling_id);
      }
    });
  }

  render_category_select() {
    // builds a list of the IDs of all categories - used to determine if this row is referencing a non-existent category
    let all_category_ids = [];

    // build options list
    let options = [];
    options.push(
      <option key={"default" + this.props.feeling.feeling_id} value="" disabled hidden>
        Choose category
      </option>
    );
    options = options.concat(
      this.props.all_categories.map(cat => {
        all_category_ids.push(cat.category_id);
        return (
          <option
            key={cat.category_id + this.props.feeling.feeling_id}
            value={cat.category_id}
          >
            {cat.name}
          </option>
        );
      })
    );

    // If the category doesn't exist, set it to undefined in the select
    let selected_category_id = this.state.category_id;
    if (!all_category_ids.includes(this.state.category_id)) {
      selected_category_id = undefined;
    }

    const select_container_style = ss(!selected_category_id, styles.select_container, {
      border: cstyles.border("warn"),
    });

    return (
      <div style={select_container_style}>
        <select
          style={styles.select}
          onChange={e => this.on_update_category(e)}
          value={selected_category_id}
          defaultValue={""}
        >
          {options}
        </select>
      </div>
    );
  }

  render() {
    const row_style = { ...styles.row, ...{ opacity: this.state.opacity } };

    return (
      <div style={row_style}>
        <Divider />{" "}
        <div style={styles.content}>
          <div style={{ width: "200px" }}>
            <input
              style={styles.name}
              placeholder={"enter a name"}
              value={this.state.name}
              onChange={e => this.on_update_name(e)}
              maxLength={25}
            />
          </div>
          <div style={{ width: "200px" }}>{this.render_category_select()}</div>
          <div style={{ flex: 1 }}>
            <input
              style={styles.description}
              placeholder={"enter a description"}
              value={this.state.description}
              onChange={e => this.on_update_description(e)}
            />
          </div>
          <DeleteButton
            key={this.props.feeling.feeling_id}
            callback={() => this.on_remove()}
          />
        </div>
      </div>
    );
  }
}

function state_to_props(state, ownProps) {
  const feeling = state.db[ownProps.feeling_id];

  // Get all categories
  const all_categories = state.affects.categories.map(cat_id => {
    return state.db[cat_id];
  });

  return {
    feeling: feeling,
    all_categories: all_categories,
  };
}
export default connect(state_to_props, { update_feeling, remove_feeling })(FeelingRow);
