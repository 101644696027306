import React, { Component } from "react";
import PropTypes from "prop-types";
import { ss } from "../common";

const styles = {
  icon_div: {
    padding: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    transition: "filter 250ms",
    cursor: "pointer",
    margin: 2,
    position: "relative",
  },
  icon: {
    height: 28,
    width: 28,
    opacity: 0.7,
    transition: "opacity 250ms",
  },
  inactive: {
    opacity: 0.5,
    cursor: "default",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0)",
    opacity: 0,
    zIndex: 10,
    transition: "opacity 250ms, background-color 250ms",
  },
  overlay_one_step: {
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
};

export default class HoverIcon extends Component {
  static propTypes = {
    icon: PropTypes.any,
    alt: PropTypes.string,
    action: PropTypes.func.isRequired,
    style: PropTypes.object,
    active: PropTypes.bool,
    icon_size: PropTypes.number,
    stop_prop: PropTypes.bool, // whether to stop click event propagation
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      expand: false, // expand on mouse down
    };
  }

  on_click(e, active) {
    if (this.props.stop_prop) {
      e.stopPropagation();
    }

    // Only execute action if we're active
    if (active) {
      this.props.action();
    }
  }

  render() {
    // Set default value for active if not given
    const active = typeof this.props.active !== "boolean" ? true : this.props.active;

    // Merge in any optional styling passed in
    const base_style = ss(this.props.style, styles.icon_div, this.props.style);

    // Set div and icon style based on active and hover status
    let div_style = ss(!active, base_style, styles.inactive);
    let icon_style = ss(this.state.hover && active, styles.icon, { opacity: 1 });

    // Set icon size if specified
    icon_style = ss(this.props.icon_size, icon_style, {
      width: this.props.icon_size,
      height: this.props.icon_size,
    });

    // overlay style
    let overlay_style = ss(
      active && this.state.hover,
      styles.overlay,
      styles.overlay_one_step
    );

    // copy parent border radius for overlay
    if (div_style.borderRadius) {
      overlay_style = ss(true, overlay_style, { borderRadius: div_style.borderRadius });
    }

    // scale on mouse down
    icon_style = ss(active && this.state.expand, icon_style, {
      transform: "scale(1.1)",
    });

    return (
      <div
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false, expand: false });
        }}
        onClick={e => this.on_click(e, active)}
        onMouseDown={() => {
          this.setState({ expand: true });
        }}
        onMouseUp={() => {
          this.setState({ expand: false });
        }}
        style={div_style}
      >
        <img src={this.props.icon} style={icon_style} alt={this.props.alt} />
        <div style={overlay_style} />
      </div>
    );
  }
}
