import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  close_data_editor,
  set_editor_mode,
  shift_editor_question,
  toggle_expand_editor,
} from "../redux/actions";

import TextEntry from "./TextEntry";
import MultiSelect from "./MultiSelect";
import AffectSelect from "./AffectSelect";
import ScaleSelect from "./ScaleSelect";
import List from "./List";
import TableScheduleList from "./TableScheduleList";
import YesNo from "./YesNo";
import Subset from "./Subset";
import Shuffle from "./Shuffle";
import Checkbox from "./Checkbox";
import ScaleText from "../wrappers/ScaleText";
import SingleSelect from "./SingleSelect";
import Timer from "./Timer";

import { boxShadows, cstyles, ss } from "../common";

import HoverIcon from "../wrappers/HoverIcon";

// import icons
const expand_icon = require("../icons/expand.png");
const shrink_icon = require("../icons/shrink.png");
const edit_icon = require("../icons/edit.png");
const close_icon = require("../icons/close.png");
const next_icon = require("../icons/next.png");
const previous_icon = require("../icons/previous.png");

const styles = {
  view: {
    position: "fixed",
    bottom: 5,
    left: 0,
    right: 0,
    backgroundColor: cstyles.raised(1),
    height: "200px",
    marginLeft: 170,
    marginRight: "10px",
    boxShadow: boxShadows[2],
    transition: "height 250ms, margin-bottom 400ms, margin-left 250ms, right 250ms",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
  },
  header_row: {
    padding: 2,
    backgroundColor: cstyles.raised(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    userSelect: "none",
    borderRadius: "8px 8px 0px 0px",
  },
  question_view: {
    display: "flex",
    flexDirection: "column",
  },
  question_text: {
    marginLeft: 7,
    fontSize: 24,
    maxHeight: 48,
    overflow: "hidden",
    color: "#FDC7D7",
    fontWeight: 400,
  },
  header_right_section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 8,
  },
  navigation_div: {
    display: "flex",
    flexDirection: "row",
    padding: 4,
    marginRight: 20,
  },
  entry_container: {
    overflow: "hidden",
    height: "calc(100% - 54px)",
  },
};

class Editor extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    question_type: PropTypes.string.isRequired,
    editor_mode: PropTypes.string.isRequired,
    close_data_editor: PropTypes.func.isRequired,
    toggle_expand_editor: PropTypes.func.isRequired,
    set_editor_mode: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    shift_editor_question: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired,
    text_entry_focus: PropTypes.bool.isRequired,
    side_context_expanded: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      closing: false,
    };

    this.keypress_handler = e => {
      this.on_key_press(e);
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypress_handler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypress_handler, false);
  }

  expand() {
    // expands view to a larger view
    this.props.toggle_expand_editor();
  }

  close_editor() {
    // Starts closing the editor
    this.setState({
      closing: true,
    });
    setTimeout(() => {
      this.props.close_data_editor();
    }, 500);
  }

  change_mode() {
    const { editor_mode } = this.props;
    if (editor_mode === "create") {
      this.props.set_editor_mode("edit");
    } else if (editor_mode === "edit") {
      this.props.set_editor_mode("create");
    }
  }

  render_expand_icon() {
    // renders an expand or shrink icon

    // Change image based on whether we're expanded
    let img_icon = expand_icon;
    let alt = "expand";
    if (this.props.expanded) {
      img_icon = shrink_icon;
      alt = "shrink";
    }

    return (
      <HoverIcon
        alt={alt}
        icon={img_icon}
        action={() => this.expand()}
        style={{ marginRight: 8 }}
      />
    );
  }

  render_edit_button() {
    if (this.props.question_type === "multiple_select") {
      return (
        <HoverIcon
          alt={"edit"}
          icon={edit_icon}
          action={() => this.change_mode()}
          style={{ marginRight: 8 }}
        />
      );
    }
    // render a "spacer"
    else {
      return <div style={{ height: 25, width: 50 }} />;
    }
  }

  shift_question(direction) {
    if (direction === "next" && this.props.navigation.next) {
      this.props.shift_editor_question("next");
    } else if (direction === "previous" && this.props.navigation.previous) {
      this.props.shift_editor_question("previous");
    }
  }

  map_arrow_to_direction(arrow_key_name) {
    switch (arrow_key_name) {
      case "ArrowRight":
        return "next";
      case "ArrowUp":
        return "previous";
      case "ArrowLeft":
        return "previous";
      case "ArrowDown":
        return "next";
      default:
        return null;
    }
  }

  on_key_press(e) {
    const { arrow_navigation_typing } = this.props.settings;
    // if arrow navigation while typing is disabled, return immediately
    if (arrow_navigation_typing === "disabled") {
      return;
    }

    const { key } = e;

    // If the key wasn't an arrow key, return
    if (!["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(key)) {
      return;
    }

    // If we always navigate regardless of text focus, go ahead and shift the question
    if (arrow_navigation_typing === "enabled") {
      this.shift_question(this.map_arrow_to_direction(key));
    }

    if (arrow_navigation_typing === "up_down_only") {
      // up/down always navigate, regardless of text focus
      if (["ArrowUp", "ArrowDown"].includes(key)) {
        this.shift_question(this.map_arrow_to_direction(key));
      }
      // left/right only navigate if a text entry is not focused
      else if (!this.props.text_entry_focus) {
        this.shift_question(this.map_arrow_to_direction(key));
      }
    }
  }

  render_navigation() {
    const { previous, next } = this.props.navigation;

    return (
      <div style={styles.navigation_div}>
        <HoverIcon
          alt={"previous"}
          icon={previous_icon}
          action={() => this.shift_question("previous")}
          style={{ margin: 0 }}
          active={previous}
        />
        <HoverIcon
          alt={"next"}
          icon={next_icon}
          action={() => this.shift_question("next")}
          style={{ margin: 0 }}
          active={next}
        />
      </div>
    );
  }

  render_entry_body() {
    const { question } = this.props;
    const key = `editor:${question.type}:${question.question_id}`;
    if (question.type === "text_entry") {
      return <TextEntry key={key} />;
    } else if (question.type === "multiple_select") {
      return <MultiSelect key={key} />;
    } else if (question.type === "affects") {
      return <AffectSelect key={key} />;
    } else if (question.type === "scale") {
      return <ScaleSelect key={key} />;
    } else if (question.type === "list") {
      return <List key={key} />;
    } else if (question.type === "table_schedule_list") {
      return <TableScheduleList key={key} />;
    } else if (question.type === "yes_no") {
      return <YesNo key={key} />;
    } else if (question.type === "subset") {
      return <Subset key={key} />;
    } else if (question.type === "shuffle") {
      return <Shuffle key={key} />;
    } else if (question.type === "checkbox") {
      return <Checkbox key={key} />;
    } else if (question.type === "single_select") {
      return <SingleSelect key={key} />;
    } else if (question.type === "timer") {
      return <Timer key={key} />;
    } else {
      throw new Error(`question type "${question.type}" not recognized in editor`);
    }
  }

  render() {
    const { settings } = this.props;
    // Change view style based on whether we're expanded or not
    let view_style = styles.view;
    if (this.props.expanded) {
      view_style = { ...view_style, ...{ height: "400px" } };
    }
    if (this.state.closing) {
      view_style = { ...view_style, ...{ height: "0px" } };
    }
    view_style = ss(this.props.side_context_expanded, view_style, {
      right: 432,
      marginLeft: 160,
    });

    const question_style = ss(true, styles.question_text, {
      color: settings.accent_color,
    });

    return (
      <div style={view_style} className={"animated bounceInUp fast"}>
        <div style={styles.header_row}>
          <div style={styles.question_view}>
            <ScaleText style={question_style} min_size={14}>
              {this.props.question.question}
            </ScaleText>
          </div>

          <div style={styles.header_right_section}>
            {this.render_navigation()}
            {this.render_edit_button()}
            {this.render_expand_icon()}
            <HoverIcon
              alt={"close"}
              icon={close_icon}
              action={() => this.close_editor()}
              style={{ marginRight: 8 }}
            />
          </div>
        </div>
        <div style={styles.entry_container}>{this.render_entry_body()}</div>
      </div>
    );
  }
}

function state_to_props(state) {
  const {
    question_id,
    mode,
    next_question,
    previous_question,
    text_entry_focus,
  } = state.editor;

  // Set what navigation options are available (for next/prev question)
  const navigation = {
    next: next_question ? true : false,
    previous: previous_question ? true : false,
  };

  return {
    question: state.db[question_id],
    question_type: state.db[question_id].type,
    editor_mode: mode,
    settings: state.settings,
    navigation,
    text_entry_focus,
    side_context_expanded:
      state.entry.show_side_context && state.entry.side_context_expanded,
    expanded: state.editor.expanded,
  };
}
export default connect(state_to_props, {
  close_data_editor,
  set_editor_mode,
  shift_editor_question,
  toggle_expand_editor,
})(Editor);
