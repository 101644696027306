import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_question_ops } from "../redux/actions";

import { ss, cstyles } from "../common";

import { new_multi_option } from "../create_new";

import OptionChip from "./OptionChip";

const styles = {
  container: {
    flex: 1,
    padding: 10,
    display: "flex",
    height: "95%",
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  options: {
    flex: 5,
    minWidth: "120px",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    flexWrap: "wrap",
    overflowY: "auto",
  },
  new_option: {
    flex: 1,
    minWidth: "150px",
    padding: 5,
    display: "flex",
    flexDirection: "column",
  },
  create_option_input: {
    padding: 10,
    fontFamily: "Nunito",
    fontSize: 12,
    border: "1px solid white",
    background: cstyles.raised(2),
    borderRadius: 8,
    resize: "none",
    color: "white",
  },
  create_icon: {
    margin: 6,
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    height: 20,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "filter 250ms",
    userSelect: "none",
    backgroundColor: cstyles.action_confirm,
    cursor: "pointer",
  },
  add_option_message: {
    color: cstyles.text("active"),
    fontSize: 24,
    width: "100%",
    height: "100%",
    ...cstyles.col,
    justifyContent: "center",
    alignItems: "center",
  },
};

class MultiSelect extends Component {
  static propTypes = {
    update_question_ops: PropTypes.func.isRequired,
    question_id: PropTypes.string.isRequired,
    qops: PropTypes.object, // may not be defined until updated
  };

  constructor(props) {
    super(props);
    this.state = {
      create_icon_hover: false,
      create_text: "",
    };
  }

  render_create_icon() {
    const style = ss(
      this.state.create_icon_hover,
      styles.create_icon,
      cstyles.bright_hover
    );
    return (
      <div
        style={style}
        onMouseEnter={() => {
          this.setState({ create_icon_hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ create_icon_hover: false });
        }}
        onClick={() => this.create_option()}
      >
        create
      </div>
    );
  }

  check_enter(e) {
    if (e.key === "Enter") {
      this.create_option();
    }
  }

  render_options() {
    // Renders list of options
    const { qops } = this.props;
    if (qops !== undefined && Object.values(qops.options).length !== 0) {
      return Object.values(qops.options).map(op => {
        return <OptionChip key={op.option_id} option_id={op.option_id} />;
      });
    }
    // "show add an option message"
    else {
      return (
        <div style={styles.add_option_message}>
          <div>Add something to your list →</div>
          <div>Answer by choosing from a list that you build.</div>
          <div>You use the same list every time you answer the question.</div>
        </div>
      );
    }
  }

  create_option() {
    this.props.update_question_ops(
      this.props.question_id,
      new_multi_option(this.state.create_text),
      "create"
    );

    this.setState({
      create_text: "",
    });
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.row}>
          <div style={styles.options}>{this.render_options()}</div>
          <div style={styles.new_option}>
            <input
              style={styles.create_option_input}
              placeholder={"new option"}
              onKeyPress={e => this.check_enter(e)}
              value={this.state.create_text}
              onChange={e => this.setState({ create_text: e.target.value })}
              maxLength={30}
            />
            {this.render_create_icon()}
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  const question_id = state.editor.question_id;
  const qops_id = state.qops_map[question_id];

  let qops;
  if (qops_id) {
    qops = state.db[qops_id];
  }

  return {
    question_id,
    qops,
  };
}
export default connect(state_to_props, {
  update_question_ops,
})(MultiSelect);
