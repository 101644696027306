import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { SOS, change_screens } from "./redux/actions";

import { cstyles } from "./common";
import {} from "./getters";

import HoverIcon from "./wrappers/HoverIcon";

import swal from "sweetalert";

import { ID } from "./appinfo";

const nuclear_icon = require("./icons/nuclear.png");
const sos_icon = require("./icons/sos.png");
const home_icon = require("./icons/home.png");

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    width: 300,
  },
  title: {
    fontSize: 52,
    marginBottom: 20,
  },
  actions: {
    marginLeft: 100,
    display: "flex",
    flexDirection: "column",
  },
  actions_title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  error: {
    height: 40,
    width: 500,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    color: cstyles.text("inactive"),
    backgroundColor: "#270c11",
    border: "2px solid #4e1822",
    borderRadius: 8,
    padding: 5,
    margin: 5,
  },
  section: {
    marginTop: 20,
  },
  nuclear: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button_section: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button_section_right: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
  },
};

class Whoops extends Component {
  static propTypes = {
    error: PropTypes.object,
    SOS: PropTypes.func.isRequired,
    change_screens: PropTypes.func.isRequired,
    error_info: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  go_nuclear() {
    localStorage.clear();
    window.location.reload(false);
  }

  click_nuclear() {
    swal({
      title: "Clear local data, resync app",
      text: `This will clear all of your locally saved data. When the app reloads, it will be as if you were opening this on a new computer for the first time.\n\nWARNING: There is a chance for data loss if something hasn't been saved to the remote server yet.`,
      icon: "warning",
      buttons: ["Don't do it", "Press the button"],
      dangerMode: true,
    }).then(choice => {
      if (choice) {
        this.go_nuclear();
      }
    });
  }

  sos() {
    this.props.SOS();
    window.location.reload(false);
  }

  home() {
    this.props.change_screens("home");
    window.location.reload(false);
  }

  render() {
    let error_message = "";
    try {
      error_message += this.props.error.toString();
      error_message += `\n${ID}`;
      error_message += "\n";
      error_message += this.props.error_info.componentStack;
      error_message += "\n\n";
      error_message += this.props.error.stack;
    } catch (err) {}

    return (
      <div style={styles.container}>
        <div style={styles.info}>
          <div style={styles.title}>Whoops!</div>
          <div>The app has had an irrecoverable error.</div>
          <div>Ben probably messed something up.</div>
        </div>
        <div style={styles.actions}>
          <div style={styles.actions_title}>What should I do?</div>
          <div style={styles.section}>
            <div>1. Send this error message to Ben,</div>
            <div> along with a quick explanation of what you were doing.</div>
          </div>
          <textarea style={styles.error} value={error_message} />

          <div style={styles.button_section}>
            <div style={{ flex: 1 }}>
              2. Go to the home page, and try what you were doing again
            </div>
            <div style={styles.button_section_right}>
              <HoverIcon
                icon={home_icon}
                alt={"go home"}
                icon_size={60}
                style={{ width: "min-content" }}
                action={() => this.home()}
              />
            </div>
          </div>
          <div style={styles.button_section}>
            <div style={{ flex: 1 }}>3. Send an SOS. Try a light reset.</div>
            <div style={styles.button_section_right}>
              <HoverIcon
                icon={sos_icon}
                alt={"send an SOS - do a light app reset"}
                icon_size={60}
                style={{ width: "min-content" }}
                action={() => this.sos()}
              />
            </div>
          </div>
          <div style={styles.section}>4. Wait for Ben to fix the problem</div>
          <div style={styles.button_section}>
            <div style={{ flex: 1 }}>5. Delete local data, and resync your app</div>
            <div style={styles.button_section_right}>
              <HoverIcon
                icon={nuclear_icon}
                alt={"Delete local data, and resync your app"}
                icon_size={60}
                style={{ width: "min-content" }}
                action={() => this.click_nuclear()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, { SOS, change_screens })(Whoops);
