import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { update_entry, text_entry_focus } from "../redux/actions";
import { cstyles, ss } from "../common";

const styles = {
  text_entry_div: {
    flex: 1,
    padding: 10,
    display: "flex",
    height: "90%",
  },
  text_entry_input: {
    flex: 1,
    padding: 10,
    fontFamily: "Nunito",
    border: cstyles.border("input"),
    borderRadius: 8,
    resize: "none",
    color: "white",
    backgroundColor: cstyles.raised(2),
    maxWidth: 600,
  },
  stats_box: {
    minWidth: 65,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    color: "white",
    opacity: 0,
    transition: "opacity 750ms",
    marginLeft: 4,
    padding: 4,
    fontSize: 10,
  },
};

class TextEntry extends Component {
  static propTypes = {
    update_entry: PropTypes.func.isRequired,
    question_id: PropTypes.string.isRequired,
    entry: PropTypes.object,
    text_entry_focus: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { entry } = this.props;

    let starting_value = "";
    if (entry) {
      if (this.props.question_id in entry.answers)
        starting_value = this.props.entry.answers[this.props.question_id];
    }

    this.state = {
      input_value: starting_value,
    };
    this.timer = null;
  }

  on_text_enter(e) {
    this.setState({
      input_value: e.target.value,
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.on_update_entry();
    }, 500);
  }

  on_update_entry() {
    let entry_id = null;
    if (this.props.entry) {
      entry_id = this.props.entry.entry_id;
    }
    this.props.update_entry(
      entry_id,
      this.props.question_id,
      "text_entry",
      this.state.input_value
    );
  }

  render_stats() {
    let { input_value } = this.state;

    // Count words
    let word_count;
    try {
      word_count = input_value.trim().split(/\s+/).length;
    } catch (err) {}

    // Count characters
    let character_count = input_value.length;

    // Only display if > 1
    const style = ss(word_count > 1 && character_count > 1, styles.stats_box, {
      opacity: 1,
    });

    return (
      <div style={style}>
        <div>{word_count} words</div>
        <div>{character_count} characters</div>
      </div>
    );
  }

  // sets state.editor.text_entry_focus when the text entry gains/loses focus
  on_focus(focus) {
    this.props.text_entry_focus(focus);
  }

  render() {
    return (
      <div style={styles.text_entry_div}>
        <textarea
          style={styles.text_entry_input}
          value={this.state.input_value}
          onChange={text => {
            this.on_text_enter(text);
          }}
          onFocus={e => this.on_focus(true)}
          onBlur={e => this.on_focus(false)}
          placeholder={"Type an entry"}
        />
        {this.render_stats()}
      </div>
    );
  }
}

function state_to_props(state) {
  let entry = null;
  if (state.entry.entry_id) {
    entry = state.db[state.entry.entry_id];
  }

  return {
    question_id: state.editor.question_id,
    entry,
  };
}
export default connect(state_to_props, { update_entry, text_entry_focus })(TextEntry);
