import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {} from "../redux/actions";

import { cstyles } from "../common";
import {} from "../getters";

import Hover from "../wrappers/Hover";

const styles = {
  entry: {
    border: cstyles.border("subtle"),
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  entry_name: {
    fontSize: 15,
    fontWeight: 600,
    textAlign: "center",
  },
  entry_date: {
    width: "100%",
    marginRight: 25,
    fontSize: 12,
    fontWeight: 300,
    color: cstyles.text("faded"),
    textAlign: "right",
    marginTop: 6,
  },
};

class SpotlightEntries extends Component {
  static propTypes = {
    entry: PropTypes.object.isRequired,
  };

  render() {
    const { entry } = this.props;

    return (
      <Hover
        style={styles.entry}
        action={() => {
          window.open(entry.link, "_blank");
        }}
      >
        <div style={styles.entry_name}>{entry.name}</div>
        <div style={styles.entry_date}>- {entry.date}</div>
      </Hover>
    );
  }
}

function state_to_props(state) {
  return {};
}
export default connect(state_to_props, {})(SpotlightEntries);
